import { useState, useEffect, useCallback } from "react";

import { Each } from "utils/Each";

import { useAside, useTranslations } from "hooks";
import { loadTreeRoot, create } from "modules/addresses/services/addresses";
import { getTranslatedName, actionAndReload } from "./utils";
import { mapWithDistances } from "modules/addresses/models/address.model";

import RootNode from "./RootNode";
import NodeControl from "./NodeControl";
import AddressForm from "../../forms/address.form";

const RootNodeControl = ({ rootLevel, permissions }) => {
	// ================================================
	// Hooks
	// ================================================
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	// ================================================
	// State
	// ================================================
	const [tree, setTree] = useState(undefined);

	// ================================================
	// Effects
	// ================================================
	const loadRoot = useCallback(async () => {
		const res = await loadTreeRoot(rootLevel);
		setTree(res);
	}, [rootLevel]);

	const hardReloadRoot = useCallback(async () => {
		if (tree !== undefined) setTree(undefined);
		loadRoot();
	}, [tree, loadRoot]);

	useEffect(() => {
		loadRoot();
	}, [loadRoot]);

	// ================================================
	// Component Services
	// ================================================
	const createAndReload = useCallback(
		async (data) =>
			await actionAndReload(
				async () =>
					await create[rootLevel](null, mapWithDistances(data)),
				hardReloadRoot,
				translate,
				"ThisCreatedSuccessfully",
				rootLevel
			),
		[rootLevel, hardReloadRoot, translate]
	);

	const openNewRoot = () => {
		asideBuilder.setTitle(`New ${getTranslatedName(translate, rootLevel)}`);
		asideBuilder.setComponent(AddressForm);
		asideBuilder.setComponentKey(`new-address-${rootLevel}-${new Date()}`);
		asideBuilder.setComponentProps({
			type: getTranslatedName(translate, rootLevel),
			level: rootLevel,
			siblings: tree,
		});
		asideBuilder.setSaveCallback(createAndReload);
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	return (
		<RootNode
			rootLevel={rootLevel}
			onAddOpen={openNewRoot}
			loading={tree === undefined}
		>
			<Each
				of={tree}
				render={(item, index) => {
					return (
						<NodeControl
							{...item}
							index={index}
							rootLevel={rootLevel}
							permissions={permissions}
							parentReload={async () => hardReloadRoot()}
						/>
					);
				}}
			/>
		</RootNode>
	);
};

export default RootNodeControl;
