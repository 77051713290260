import DefaultSettings from "../../../services";
const TYPE_ID = 4;

class Service extends DefaultSettings {
	constructor() {
		super(TYPE_ID);
	}

	async mapData(data) {
		return super.mapData(data, true);
	}
}
export default Service;
