import { useContext, useRef } from "react";
import { AsideContext } from "contexts/AsideManager";

const initialState = {
	open: false,
	right: false,
	Component: null,
	componentProps: {},
	saveCallback: () => {},
	autoClose: true,
	SecondaryComponent: null,
	secondaryComponentProps: {},
	secondaryComponentKey: null,
	SiblingComponent: null,
	siblingComponentProps: {},
	siblingComponentKey: null,
	siblingTitle: "",
	SiblingTriggerButton: null,
	siblingTriggerButtonProps: {},
	siblingTriggerButtonKey: null,
	resetOnClose: true,
	onClose: () => {},
	title: "",
	width: 32,
	fullScreen: false,
	autoClear: true,
	siblingOpen: false,
	closeOnOutsideClick: true,
};

const helperState = {
	open: false,
	right: false,
	hideSaveButton: true,
	Component: null,
	componentProps: {},
};

const useAside = () => {
	const { asideState, updateConfig } = useContext(AsideContext);

	const config = useRef({ ...initialState });
	const helperConfig = useRef({ ...helperState });

	const build = () => {
		updateConfig(config.current, helperConfig.current);
		config.current = { ...initialState };
		helperConfig.current = { ...helperState };
	};

	const buildHelper = () => {
		updateConfig(false, helperConfig.current);
		helperConfig.current = { ...helperState };
	};

	const asideBuilder = {
		setRight: (val) => {
			config.current.right = val;
			updateConfig({ right: val });
		},
		setTitle: (val) => {
			config.current.title = val;
			updateConfig({ title: val });
		},
		setComponent: (val) => {
			config.current.Component = val;
		},
		setComponentKey: (val) => {
			config.current.componentKey = val;
		},
		setComponentProps: (val) => {
			config.current.componentProps = val;
		},
		setSecondaryComponent: (val) => {
			config.current.SecondaryComponent = val;
		},
		setSecondaryComponentKey: (val) => {
			config.current.secondaryComponentKey = val;
		},
		setSecondaryComponentProps: (val) => {
			config.current.secondaryComponentProps = val;
		},
		setResetOnClose: (val) => {
			config.current.resetOnClose = val;
		},
		setOnClose: (val) => {
			config.current.onClose = val;
		},
		setWidth: (val) => {
			config.current.width = val;
		},
		setFullScreen: (val) => {
			config.current.fullScreen = val;
		},
		setIsLoading: (val) => {
			config.current.isLoading = val;
			updateConfig({ isLoading: val });
		},
		updateComponentProps: (val) => {
			let updatedProps = { ...config.current.componentProps, ...val };
			updateConfig({ componentProps: updatedProps });
		},
		setOpen: (val) => (config.current.open = val),
		setAutoClose: (val) => (config.current.autoClose = val),
		setAutoClear: (val) => (config.current.autoClear = val),
		setSaveCallback: async (val) => (config.current.saveCallback = val),
		reset: () => {
			config.current = { ...initialState };
			helperConfig.current = { ...helperState };
			build();
		},
		setSiblingOpen: (val) => {
			config.current.siblingOpen = val;
			updateConfig({ siblingOpen: val });
		},
		hideSaveButton: (val) => {
			config.current.hideSaveButton = val;
			updateConfig({ hideSaveButton: val });
		},
		setCloseOnOutsideClick: (val) => {
			config.current.closeOnOutsideClick = val;
		},
		build,
	};

	const helperAsideBuilder = {
		setTitle: (val) => {
			helperConfig.current.title = val;
			updateConfig(false, { title: val });
		},
		setComponent: (val) => {
			helperConfig.current.Component = val;
		},
		setComponentKey: (val) => {
			helperConfig.current.componentKey = val;
		},
		setComponentProps: (val) => {
			helperConfig.current.componentProps = val;
			updateConfig(false, { componentProps: val });
		},
		hideSaveButton: (val) => {
			helperConfig.current.hideSaveButton = val;
		},
		setSaveCallback: async (val) =>
			(helperConfig.current.saveCallback = val),
		setOpen: (val) => {
			helperConfig.current.open = val;
			updateConfig(false, { open: val });
		},
		build: buildHelper,
	};

	config.current.onClose = () => asideBuilder.reset();

	return {
		asideState,
		asideBuilder,
		helperAsideBuilder,
	};
};

export default useAside;
