import { Button } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

const Aside = ({
	open = false,
	right = false,
	title = 'Menu',
	onClose = () => {},
	Component = null,
	componentKey = '',
	componentProps = {},
	saveCallback = async (data) => {},
	autoClose = true,
	autoClear = true,
	SecondaryComponent = null,
	secondaryComponentKey = '',
	secondaryComponentProps = {},
	resetOnClose = false,
	width = 32,
	hideSaveButton = false,
	fullScreen = false,
	isLoading = false,
	closeOnOutsideClick = true,
}) => {
	const { translate } = useTranslations();
	const [loading, setLoading] = useState(isLoading);
	const componentRef = useRef(null);
	const secondaryComponentRef = useRef(null);

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	const classes = {
		default: right ? 'right-0 border-gray-200 border-l' : 'left-0 border-gray-200 border-r',
		close: right ? 'translate-x-full' : '-translate-x-full -ml-20',
	};

	const onCloseAction = () => {
		if (resetOnClose) {
			componentRef.current?.clear();
		}
		onClose();
	};

	const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia('(min-width: 640px)').matches);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 640px)');
		const handleResize = () => setIsLargeScreen(mediaQuery.matches);

		mediaQuery.addListener(handleResize);
		return () => mediaQuery.removeListener(handleResize);
	}, []);

	const _doSubmitFromButton = async () => {
		if (componentRef.current?.runSubmit) {
			await componentRef.current?.runSubmit();
		} else {
			_doSubmit();
		}
	};

	const _doSubmit = async (formData = null) => {
		setLoading(true);
		const data = formData || componentRef.current?.getData();
		if (data === false) {
			setLoading(false);
			return;
		}

		try {
			await saveCallback(data);
			autoClose && onCloseAction();
			autoClear && componentRef.current?.clear();
		} catch (err) {
			console.error(err);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{/* Overlay */}
			<div
				className={`${open ? 'absolute' : 'display-none'} top-0 left-0 bottom-0 right-0 z-20 bg-slate-800/50`}
				onClick={() => {
					closeOnOutsideClick && onCloseAction();
				}}
			></div>

			{/* Main Side Menu */}
			<div
				className={`${open ? '' : classes.close} absolute top-0 ${
					classes.default
				} bottom-0 z-40 p-4 pr-2 h-full flex flex-col transition-transform ease-in-out duration-200 bg-white`}
				style={{
					width: isLargeScreen ? (fullScreen ? '100%' : width + 'rem') : '100%',
				}}
			>
				<div className='flex flex-row justify-between pr-2'>
					<div className='flex flex-row items-center'>
						<button
							type='button'
							onClick={onCloseAction}
							className='text-gray-400 bg-transparent -ml-1 mr-1 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center'
						>
							<i className='ri-close-line text-lg'></i>
							<span className='sr-only'>{translate('closeMenu')}</span>
						</button>
						<h5 id='drawer-navigation-label' className='text-base font-semibold text-gray-500 uppercase'>
							{title}
						</h5>
					</div>
				</div>

				<div className='py-4 h-full overflow-y-auto pr-2'>
					{Component && (
						<Component
							key={componentKey}
							ref={componentRef}
							{...componentProps}
							submitCallback={_doSubmit}
						/>
					)}
				</div>

				<div className='py-1 flex flex-row-reverse justify-between items-center'>
					{!hideSaveButton && (
						<Button disabled={loading} onClick={_doSubmitFromButton}>
							{translate('save')}
						</Button>
					)}

					{SecondaryComponent && (
						<SecondaryComponent
							key={secondaryComponentKey}
							ref={secondaryComponentRef}
							{...secondaryComponentProps}
						/>
					)}
				</div>
				<Tooltip id='aside-tooltip' />
			</div>
		</>
	);
};

export default Aside;
