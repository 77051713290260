import { forwardRef } from "react";
const Label = forwardRef((props, ref) => {
	return (
		<div className={`block mb-2 text-sm font-medium text-gray-900`}>
			<div className="flex flex-row items-center">
				{props?.value ?? props?.defaultValue}
			</div>
		</div>
	);
});
export default Label;
