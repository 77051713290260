import React, { useState, useEffect } from "react";
import { useTranslations } from "hooks";
import Tooltip from "components/ui/Input/internal/Tooltip";
const Toggle = ({
	className,
	onClick,
	small,
	disabled,
	checked,
	name,
	value = "",
	checkedValue = "true",
	uncheckedValue = "false",
	title = null,
	defaultChecked = null,
	defaultValue = null,
}) => {
	const { translate } = useTranslations();
	const [checkedState, setCheckedState] = useState(checked ?? false);

	useEffect(() => {
		setCheckedState(checked);
	}, [checked]);

	useEffect(() => {
		const value = defaultChecked ?? defaultValue;
		if (value !== null) setCheckedState(value);
	}, [defaultValue, defaultChecked]);

	const onClickEventHandler = (value) => {
		setCheckedState(value);
		onClick(value);
	};

	return (
		<Tooltip text={translate(title, true)}>
			<div className="flex">
				<label
					className={`relative inline-flex items-center cursor-pointer ${className}`}
				>
					<input
						type="hidden"
						disabled={disabled}
						value={uncheckedValue}
						name={name}
					/>
					<input
						type="checkbox"
						value={value || checkedValue}
						name={name}
						className="sr-only peer"
						checked={checkedState}
						onChange={() =>
							!disabled && onClickEventHandler(!checkedState)
						}
					/>
					<div
						className={`
                ${disabled ? "opacity-30 cursor-not-allowed" : "opacity-100"}
                ${
					small
						? "w-9 h-5  after:h-4 after:w-4 after:top-[2px] after:left-[2px]"
						: "w-11 h-6 after:h-5 after:w-5 after:top-[2px] after:left-[2px]"
				} 
                bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all peer-checked:bg-primary-500`}
					></div>
				</label>
			</div>
		</Tooltip>
	);
};

Toggle.defaultProps = {
	className: "",
	disabled: false,
	small: false,
	checked: false,
	onClick: () => {},
};

export default Toggle;
