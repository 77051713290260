import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, ReSelect, SearchSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm, useTranslations } from 'hooks';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

import RoomTypeService from 'modules/addresses/pages/settings/pages/RoomType/services';
import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';

const PriorityRuleForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();

	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();
	const roomTypeService = new RoomTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				fetchTaskTypes(res?.monitorDoubleTaskRule?.taskGroup?.id);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='type' value={props.service.getTypeId()} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='description'>
						<TextArea
							isView={isView}
							placeholder={'description'}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>

					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							name='monitorDoubleTaskRule.taskGroupId'
							defaultValue={data?.monitorDoubleTaskRule?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>

					<FormEntry label='taskTypes'>
						<SearchSelect
							multiple
							isView={isView}
							name='monitorDoubleTaskRule.taskTypeIdsArray'
							options={taskTypesOptions}
							defaultSelected={data?.monitorDoubleTaskRule?.taskTypeIds}
							excludeSelectedOptionsWhenNotInOptionsList
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='fromRoomTypes'>
						<SearchSelect
							multiple
							isView={isView}
							name='monitorDoubleTaskRule.fromRoomTypeIdsArray'
							service={roomTypeService}
							defaultSelected={data?.monitorDoubleTaskRule?.fromRoomTypeIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='toRoomTypes'>
						<SearchSelect
							multiple
							isView={isView}
							name='monitorDoubleTaskRule.toRoomTypeIdsArray'
							service={roomTypeService}
							defaultSelected={data?.monitorDoubleTaskRule?.toRoomTypeIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label={'filterTaskBarcode'}>
						<CheckBox
							isView={isView}
							name='monitorDoubleTaskRule.filterTaskBarcode'
							label='filterTaskBarcode'
							selected={data?.monitorDoubleTaskRule?.filterTaskBarcode}
						/>
					</FormEntry>
					<FormEntry label='timeMargin'>
						<Input
							isView={isView}
							type='number'
							min={0}
							placeholder={'timeMargin'}
							name='monitorDoubleTaskRule.timeMargin'
							defaultValue={data?.monitorDoubleTaskRule?.timeMargin}
						/>
					</FormEntry>
					<FormEntry label={'matchFrom'}>
						<CheckBox
							isView={isView}
							name='monitorDoubleTaskRule.matchFrom'
							label='matchFrom'
							selected={data?.monitorDoubleTaskRule?.matchFrom}
						/>
					</FormEntry>
					<FormEntry label={'matchTo'}>
						<CheckBox
							isView={isView}
							name='monitorDoubleTaskRule.matchTo'
							label='matchTo'
							selected={data?.monitorDoubleTaskRule?.matchTo}
						/>
					</FormEntry>
					<FormEntry label={'matchTaskType'}>
						<CheckBox
							isView={isView}
							name='monitorDoubleTaskRule.matchTaskType'
							label='matchTaskType'
							selected={data?.monitorDoubleTaskRule?.matchTaskType}
						/>
					</FormEntry>
					<FormEntry label={'matchTaskName'}>
						<CheckBox
							isView={isView}
							name='monitorDoubleTaskRule.matchTaskName'
							label='matchTaskName'
							selected={data?.monitorDoubleTaskRule?.matchTaskName}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default PriorityRuleForm;
