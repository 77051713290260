function getCountryCodeFromLanguageCode(languageCode) {
	languageCode = languageCode.toLowerCase();

	const exceptions = {
		af: "za", // Afrikaans - South Africa
		ar: "sa", // Arabic - Saudi Arabia
		be: "by", // Belarusian - Belarus
		bn: "bd", // Bengali - Bangladesh
		bs: "ba", // Bosnian - Bosnia and Herzegovina
		cs: "cz", // Czech - Czech Republic
		dv: "mv", // Divehi - Maldives
		dz: "bt", // Dzongkha - Bhutan
		el: "gr", // Greek - Greece
		et: "ee", // Estonian - Estonia
		fa: "ir", // Persian - Iran
		ga: "ie", // Irish - Ireland
		he: "il", // Hebrew - Israel
		hi: "in", // Hindi - India
		hr: "hr", // Croatian - Croatia
		hy: "am", // Armenian - Armenia
		is: "is", // Icelandic - Iceland
		ja: "jp", // Japanese - Japan
		ka: "ge", // Georgian - Georgia
		kk: "kz", // Kazakh - Kazakhstan
		km: "kh", // Khmer - Cambodia
		kn: "in", // Kannada - India
		ko: "kr", // Korean - South Korea
		ky: "kg", // Kyrgyz - Kyrgyzstan
		lo: "la", // Lao - Laos
		lv: "lv", // Latvian - Latvia
		mk: "mk", // Macedonian - North Macedonia
		ml: "in", // Malayalam - India
		mn: "mn", // Mongolian - Mongolia
		my: "mm", // Burmese - Myanmar
		ne: "np", // Nepali - Nepal
		si: "lk", // Sinhala - Sri Lanka
		sq: "al", // Albanian - Albania
		sr: "rs", // Serbian - Serbia
		ta: "in", // Tamil - India
		te: "in", // Telugu - India
		th: "th", // Thai - Thailand
		tk: "tm", // Turkmen - Turkmenistan
		uk: "ua", // Ukrainian - Ukraine
		ur: "pk", // Urdu - Pakistan
		uz: "uz", // Uzbek - Uzbekistan
		vi: "vn", // Vietnamese - Vietnam
		zh: "cn", // Chinese - China
		en: "us",
		pt: "br",
		eu: "es",
	};

	if (languageCode in exceptions) {
		return exceptions[languageCode];
	}

	return languageCode;
}

const getFlag = (lang, isBig = true) => {
	const path = isBig ? "4x3" : "1x1";
	const flagPath = `${getCountryCodeFromLanguageCode(lang)}.svg`;

	try {
		return require(`./${path}/${flagPath}`);
	} catch (e) {
		console.error(
			`Flag for language ${lang} does not exist. Using US flag instead.`
		);
		return require(`./${path}/us.svg`);
	}
};

export default getFlag;
