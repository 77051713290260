import { Toggle, Input } from "components/ui/Input";
import { useTranslations } from "hooks";
const DispatcherItem = ({
	item,
	index = 0,
	moveUpDisabled = false,
	moveDownDisabled = false,
	onMoveUp = () => {},
	onMoveDown = () => {},
	onToggleChange = () => {},
	disabled = false,
}) => {
	const { translate } = useTranslations();
	return (
		<div key={index} className="flex items-center space-x-3 h-8 ">
			<div className="flex flex-row   items-center">
				<button
					disabled={moveUpDisabled || disabled}
					className={`opacity-50 hover:opacity-100 ${
						moveUpDisabled && `cursor-not-allowed opacity-20`
					}`}
					type="button"
					onClick={() => onMoveUp(index)}
				>
					<i className="ri-arrow-up-fill"></i>
				</button>
				<button
					disabled={moveDownDisabled || disabled}
					className={`opacity-50 hover:opacity-100 ${
						moveDownDisabled && `cursor-not-allowed opacity-20`
					}`}
					type="button"
					onClick={() => onMoveDown(index)}
				>
					<i className="ri-arrow-down-fill"></i>
				</button>
			</div>
			<div className="flex items-center">
				<Toggle
					disabled={disabled}
					onClick={(value) => onToggleChange(value)}
					small={true}
					name={`dispatcherItems[${index}].enabled`}
					defaultChecked={item.enabled}
				/>
			</div>
			<div
				className={`text-sm w-3/4 ${
					item.enabled ? "opacity-100" : "opacity-60"
				}`}
			>
				{translate(item.name)}
			</div>
			{item.hasValue && item.enabled ? (
				<div>
					<Input
						disabled={disabled}
						sideLabel={"min"}
						className={"h-6"}
						type="number"
						min={1}
						required={true}
						name={`dispatcherItems[${index}].value`}
						defaultValue={item.value}
					/>
				</div>
			) : (
				<input
					disabled={disabled}
					type="hidden"
					name={`dispatcherItems[${index}].value`}
					value={item.value}
				/>
			)}

			<input
				disabled={disabled}
				type="hidden"
				name={`dispatcherItems[${index}].itemId`}
				value={item.itemId}
			/>
			<input
				disabled={disabled}
				type="hidden"
				name={`dispatcherItems[${index}].order`}
				value={item.order}
			/>
		</div>
	);
};
export default DispatcherItem;
