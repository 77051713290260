// import UploaderPortal from "./UploaderPortal";

export const portals = [
	// {
	// 	id: "media:uploader",
	// 	element: UploaderPortal,
	// 	initialState: {
	// 		files: [],
	// 	},
	// },
];

export const PORTALS = {
	// uploader: "media:uploader",
};

export default portals;
