import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, MultiAddressSelector, ReSelect, SearchSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm, useTranslations } from 'hooks';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { getCurrentTime, getToday } from 'utils';

const AdjustStaRuleForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();

	const today = getToday();
	const currentTime = getCurrentTime();

	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				const l_taskGroupId = res?.adjustStaRule?.taskGroup?.id;
				setData(res);
				setLoading(false);

				fetchTaskTypes(l_taskGroupId);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	const dayOfWeekOptions = [
		{ value: 0, label: translate('sunday') },
		{ value: 1, label: translate('monday') },
		{ value: 2, label: translate('tuesday') },
		{ value: 3, label: translate('wednesday') },
		{ value: 4, label: translate('thursday') },
		{ value: 5, label: translate('friday') },
		{ value: 6, label: translate('saturday') },
	];

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='type' value={props.service.getTypeId()} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='description'>
						<TextArea
							isView={isView}
							placeholder={'description'}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='startDate'>
						<Input
							isView={isView}
							type='date'
							placeholder={'startDate'}
							name='adjustStaRule.startDate'
							defaultValue={data?.adjustStaRule?.startDate || today}
						/>
					</FormEntry>
					<FormEntry label='endDate'>
						<Input
							isView={isView}
							type='date'
							placeholder={'endDate'}
							name='adjustStaRule.endDate'
							defaultValue={data?.adjustStaRule?.endDate || today}
						/>
					</FormEntry>
					<FormEntry label='startTime'>
						<Input
							isView={isView}
							type='time'
							placeholder={'startTime'}
							name='adjustStaRule.startTime'
							defaultValue={data?.adjustStaRule?.startTime || currentTime}
						/>
					</FormEntry>
					<FormEntry label='endTime'>
						<Input
							isView={isView}
							type='time'
							placeholder={'endTime'}
							name='adjustStaRule.endTime'
							defaultValue={data?.adjustStaRule?.endTime || currentTime}
						/>
					</FormEntry>
					<FormEntry label='validDaysOfWeek'>
						<SearchSelect
							multiple
							isView={isView}
							name='adjustStaRule.validDaysOfWeekArray'
							options={dayOfWeekOptions}
							defaultSelected={data?.adjustStaRule?.validDaysOfWeek}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							name='adjustStaRule.taskGroupId'
							defaultValue={data?.adjustStaRule?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTypes'>
						<SearchSelect
							multiple
							isView={isView}
							name='adjustStaRule.taskTypeIdsArray'
							options={taskTypesOptions}
							defaultSelected={data?.adjustStaRule?.taskTypeIds}
							excludeSelectedOptionsWhenNotInOptionsList
							isSingleValueOnSelectAll
						/>
					</FormEntry>

					<FormEntry label='fromLocation'>
						<MultiAddressSelector
							isView={isView}
							name='adjustStaRule.from'
							addresses={data?.adjustStaRule?.from}
						/>
					</FormEntry>
					<FormEntry label='toLocation'>
						<MultiAddressSelector
							isView={isView}
							name='adjustStaRule.to'
							addresses={data?.adjustStaRule?.to}
						/>
					</FormEntry>

					<FormEntry label='newPlanTime'>
						<Input
							isView={isView}
							type='time'
							placeholder={'newPlanTime'}
							name='adjustStaRule.newPlanTime'
							defaultValue={data?.adjustStaRule?.newPlanTime || currentTime}
						/>
					</FormEntry>
					<FormEntry label={'additionalMinutesToPlanTime'}>
						<Input
							isView={isView}
							placeholder={'additionalMinutesToPlanTime'}
							type='number'
							min={0}
							name='adjustStaRule.additionalMinutesToPlanTime'
							defaultValue={data?.adjustStaRule?.additionalMinutesToPlanTime}
							sideLabel={'mins'}
						/>
					</FormEntry>
					<FormEntry label={'additionalDaysToPlanTime'}>
						<Input
							isView={isView}
							placeholder={'additionalDaysToPlanTime'}
							type='number'
							min={0}
							name='adjustStaRule.additionalDaysToPlanTime'
							defaultValue={data?.adjustStaRule?.additionalDaysToPlanTime}
							sideLabel={'days'}
						/>
					</FormEntry>

					<FormEntry label='newTaskType'>
						<ReSelect
							isView={isView}
							name='adjustStaRule.newTaskTypeId'
							defaultValue={data?.adjustStaRule?.newTaskType}
							options={taskTypesOptions}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default AdjustStaRuleForm;
