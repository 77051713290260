import { TruncatedTextRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class SimpleObjectColumn extends StringColumn {
	constructor(name, keyToRender = 'name', options = {}) {
		super(options.name || name, options);
		this.noDataValue = 'N/A';
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.key = options.key || name;
		this.label = options.label || name;
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.truncateLength = options.truncateLength || 32;

		this.render =
			options.render ||
			((value) => {
				if (!value) return this.noDataValue;
				return <TruncatedTextRender value={value[keyToRender]} num={this.truncateLength} />;
			});
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default SimpleObjectColumn;
