import AbstractService from "services/Service";
const endpoint = "rules";

class RuleService extends AbstractService {
	constructor(typeId = null) {
		super();
		this.endpoint = endpoint;
		this.type = typeId;
		this.typeQuery = "RuleType=" + this.type;
	}

	async getAll(queryString) {
		const l_queryString =
			this.typeQuery + (queryString ? "&" + queryString : "");
		return super.getAll(l_queryString);
	}

	async create(data) {
		return await this.api.post(
			`${this.endpoint}/${this.type}`,
			await this.mapData(data)
		);
	}
	async update(id, data) {
		return await this.api.put(
			`${this.endpoint}/${this.type}/${id}`,
			await this.mapData(data)
		);
	}

	getTypeId() {
		return this.type;
	}
	setTypeId(type) {
		this.type = type;
	}
}
export default RuleService;
