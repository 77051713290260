import { withDashboardLayout } from "hoc";
import { Outlet } from "react-router-dom";

const ExternalIntegrationModule = () => {
	return <Outlet />;
};

const config = {
	externalMode: true,
};

export default withDashboardLayout(ExternalIntegrationModule, config);
