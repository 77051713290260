import AbstractService from 'services/Service';

const endpoint = 'users/me/data-grids';

class DataGridService extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async update(id, data) {
		return await this.api.put(`${this.endpoint}/${id}/schema`, { context: data });
	}

	async remove(id) {
		return await this.api.put(`${this.endpoint}/${id}/schema`, { context: null });
	}

	async getAll() {
		return await this.api.get(`${this.endpoint}/schema`);
	}

	async create(data) {
		throw new Error('Create method is not allowed for data grids');
	}

	async getOne(id) {
		throw new Error('Get method is not allowed for data grids');
	}

	async getMultiple(ids) {
		throw new Error('Get multiple method is not allowed for data grids');
	}

	async getExport(queryString = null) {
		throw new Error('Get export method is not allowed for data grids');
	}

	async getOptionsList(queryString = null, allItemIsHidden = false) {
		throw new Error('Get options list method is not allowed for data grids');
	}
}

export default DataGridService;
