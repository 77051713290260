const DateTimeRender = ({
	value,
	format = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	},
}) => {
	if (!value) return 'N/A';
	const localTime = new Date(value).toLocaleString('en-GB', format);

	return localTime;
};
export default DateTimeRender;
