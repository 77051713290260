import { useTranslations } from 'hooks';
import CheckBox from '../../../CheckBox';

const SelectAllOption = ({ searchTerm, isSelectAll = false, isHalfChecked = false, onClick = () => {} }) => {
	const { translate } = useTranslations();
	return (
		<div
			className={`option cursor-pointer text-sm px-4 py-0.5 border-double border-b-2 border-gray-200  hover:bg-gray-200 `}
		>
			<div className='flex flex-row items-center' onClick={onClick}>
				<div className='flex items-center'>
					<CheckBox
						readOnly={searchTerm?.length > 0}
						selected={isSelectAll}
						halfChecked={isHalfChecked || false}
					/>
				</div>
				<div className='font-semibold text-gray-600'>
					{searchTerm?.length > 0
						? translate('selectAllResults')
						: isSelectAll
						? translate('deselectAll')
						: translate('selectAll')}
				</div>
			</div>
		</div>
	);
};
export default SelectAllOption;
