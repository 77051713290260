import { changeColorContrast } from 'utils/color';
const ItemListRender = (items, className = '', color) => {
	if (!items || items.length === 0) return '';

	return (
		<div className='flex flex-col'>
			{items?.map((item) => {
				return (
					<div className='flex items-center'>
						<div
							style={
								item.color
									? {
											backgroundColor: item.color + '80',
											color: changeColorContrast(item.color, -40),
									  }
									: {}
							}
							className={`w-auto bg-gray-200 py-0.5 px-2 mt-1 justify-center text-gray-900 whitespace-nowrap rounded-xl flex-row flex items-center ${className}`}
						>
							{item.name}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ItemListRender;
