import configs from 'config/configs';
import { TranslationContext } from 'contexts/TranslationManager';
import { useContext } from 'react';

const useTranslations = () => {
	const { translations, changeLanguage, fallbackTranslations, activeLanguageCode, getLanguages } =
		useContext(TranslationContext);

	const translate = (key, noWarning = false, ...args) => {
		if (!translations && (!fallbackTranslations || !fallbackTranslations[key])) return key;

		let translation = translations?.find((t) => t.systemCode === key);

		if (translations && translation) {
			translation = translation.text;
		} else {
			if (!fallbackTranslations || !fallbackTranslations[key]) {
				if (noWarning) return key;

				if (configs.displayWarningForNotPresentTranslations) {
					console.warn(`Warning: Fallback translation not found for key: ${key}, using key as translation`);
				}

				// Return key with first letter capitalized on camelCases
				return key?.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
					return str.toUpperCase();
				});
			}

			if (noWarning) return fallbackTranslations[key];

			if (configs.displayWarningsForUnFoundTranslations) {
				console.warn(`Warning: Translation not found for key: ${key}, using fallback`);
			}

			translation = fallbackTranslations[key];
		}

		// Replace placeholders in the translation with the provided arguments
		for (let i = 0; i < args.length; i++) {
			translation = translation.replace('%s', args[i]);
		}

		return translation;
	};

	return { translate, activeLanguageCode, changeLanguage, getLanguages };
};
export default useTranslations;
