import {
	forwardRef,
	useEffect,
	useState,
	useRef,
	useImperativeHandle,
	Suspense,
} from "react";
import { Loading } from "components/ui/Interactive";
import { useTranslations, useCrud } from "hooks";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import InputField from "modules/tasks/components/InputField";
import { Each } from "utils/Each";
import { Button } from "components/ui/Input";

const MultipleCopyForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const [fieldData, setFieldData] = useState(null);

	const [fieldGroups, setFieldGroups] = useState([]);

	const initiateData = () => {
		if (props.data.id) {
			props?.service?.getMultipleCopyFields(props.data.id).then((res) => {
				if (res) {
					setFieldData(res);
					setFieldGroups([res.fields]);
				}
				setLoading(false);
			});
		}
	};

	const createNewFieldGroup = (fields) => {
		setFieldGroups((prev) => {
			return [...prev, [fields]];
		});
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	useEffect(() => {
		initiateData();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input
						type="hidden"
						name="id"
						value={props.data.id || false}
					/>

					<Each
						of={fieldGroups}
						render={(fieldGroup, fieldGroupIndex) => {
							return (
								<>
									<InternalFormDivider key={fieldGroupIndex}>
										{`${translate("copy")} ${
											fieldGroupIndex + 1
										}`}
									</InternalFormDivider>
									<Each
										of={fieldData.fields}
										render={(field, index) => {
											if (field?.isHidden) return null;
											return (
												<FormEntry
													label={field.name}
													required={field.isRequired}
												>
													<input
														type="hidden"
														name={`fields[${fieldGroupIndex}][${index}].fieldId`}
														value={field.id}
													/>
													<input
														type="hidden"
														name={`fields[${fieldGroupIndex}][${index}].inputType`}
														value={field.inputType}
													/>
													<InputField
														name={`fields[${fieldGroupIndex}][${index}].value`}
														inputType={
															field.inputType
														}
														defaultData={
															props.data.id
																? field.value
																: field.defaultValue
														}
														options={JSON.parse(
															field?.values
														)}
														isRequired={
															field.isRequired
														}
														isDisabled={
															field.isDisabled
														}
														parentId={
															props?.data
																.taskGroupId
														}
													/>
												</FormEntry>
											);
										}}
									/>
									<tr>
										<td colSpan={2}>
											<div className="border-t border-gray-200 my-4"></div>
										</td>
									</tr>
								</>
							);
						}}
					/>
				</Form>

				<Button
					className={"mt-3 ml-1"}
					small
					color="secondary"
					onClick={() => createNewFieldGroup(fieldData.fields)}
				>
					<i className="ri-file-copy-2-line mr-2"></i>
					{translate("addAnotherCopy")}
				</Button>
			</div>
		</Suspense>
	);
});

export default MultipleCopyForm;
