import { FormEntry } from 'components/form';
import { useTranslations } from 'hooks';
import InputField from 'modules/tasks/components/InputField';
import { useEffect, useState } from 'react';
import FooterInput from './FooterInput';
import HeaderInput from './HeaderInput';

const Field = ({
	id = null,
	inputType,
	name,
	options = [],
	formKey = '',
	taskGroupId = null,
	order = 0,
	isHidden = false,
	isDisabled = false,
	isRequired = false,
	isAlwaysRequired = false,
	isNeverRequired = false,
	customFieldId = null,
	taskGroupFieldId = null,
	defaultValue = null,
	isView = false,
	onFieldDataChange = (fieldData) => {},
	...args
}) => {
	const [fieldRequired, setFieldRequired] = useState((isAlwaysRequired && (isDisabled || isHidden)) || false);
	const [fieldName, setFieldName] = useState(name);
	const { translate } = useTranslations();

	useEffect(() => {
		setFieldName(name);
	}, [name]);

	useEffect(() => {
		onFieldDataChange({ name: fieldName });
	}, [fieldName]);

	const translateOptions = (options) => {
		if (!options) return [];
		return options.map((option) => {
			return {
				...option,
				name: translate(option.Name, true),
			};
		});
	};

	return (
		<>
			<input type='hidden' name={formKey + '.order'} value={order} />
			<input type='hidden' name={formKey + '.name'} value={fieldName} />
			<input type='hidden' name={formKey + '.id'} value={id} />
			<input type='hidden' name={formKey + '.customFieldId'} value={customFieldId} />
			<input type='hidden' name={formKey + '.taskGroupFieldId'} value={taskGroupFieldId} />
			<input type='hidden' name={formKey + '.inputType'} value={inputType} />

			<HeaderInput
				isView={isView}
				name={fieldName}
				onNameChanged={(name) => {
					setFieldName(name);
				}}
			/>
			<FormEntry label={'byDefault'} required={fieldRequired}>
				<InputField
					name={formKey + '.defaultValue'}
					inputType={inputType}
					defaultData={defaultValue}
					options={translateOptions(options)}
					isRequired={fieldRequired}
					parentId={taskGroupId}
					isView={isView}
					{...args}
				/>
			</FormEntry>

			<FooterInput
				isView={isView}
				isDisabled={isDisabled}
				isHidden={isHidden}
				isAlwaysRequired={isAlwaysRequired}
				isNeverRequired={isNeverRequired}
				isRequired={isAlwaysRequired || isRequired}
				formKey={formKey}
				setFieldRequired={setFieldRequired}
				onFieldDataChange={onFieldDataChange}
			/>
		</>
	);
};

export default Field;
