import Swal from "sweetalert2";
import toast from "react-hot-toast";

/**
 * Level number to level text map
 */
export const LEVELS = {
	1: "bed",
	2: "room",
	3: "zone",
	4: "site",
	5: "campus",
	6: "group",
};

/**
 * Side color for the node according to level
 * level: color
 */
export const COLORS = {
	6: "bg-slate-800",
	5: "bg-blue-500",
	4: "bg-green-500",
	3: "bg-red-500",
	2: "bg-sky-500",
	1: "bg-orange-300",
};

/**
 * Border color for NEW NODE components according to level
 * level: color
 */
export const BORDER_COLORS = {
	6: "border-slate-800",
	5: "border-blue-500",
	4: "border-green-500",
	3: "border-red-500",
	2: "border-sky-500",
	1: "border-orange-300",
};

/**
 * Level to Translated address level name mapping
 * @param {*} translate translate hook method
 * @param {*} level address level
 * @returns
 */
export const getTranslatedName = (translate, level) => {
	return translate(LEVELS[level] || "", true);
};

/**
 * Shows a generic error message
 * @param {*} translate translate hook method
 */
export const showErrorToast = (translate) => {
	toast(
		() => (
			<div className="flex flex-col">
				<div>{translate("opsSomethingWentWrong")}</div>
				<div className="text-xs text-slate-500">
					{translate("pleaseTryAgainLaterOrReportThisIssue")}
				</div>
			</div>
		),
		{
			duration: 2000,
			icon: "❗️",
		}
	);
};

/**
 * Shows a success toast with a custom translated text
 * @param {*} translate translate hook method
 * @param {*} key translate key
 * @param {*} level address level
 */
export const showSuccessToast = (translate, key, level) => {
	toast(translate(key, false, getTranslatedName(translate, level)), {
		duration: 4000,
		icon: "✅",
	});
};

/**
 * Show a SWAL confirmation popup before delete
 * @param {*} translate translate hook method
 * @param {*} Swal injected Swal object
 * @param {*} name node name
 * @param {*} level node level
 * @param {*} callback on confirmed callback
 */
export const requestDelete = (translate, name, level, callback) => {
	Swal.fire({
		title: translate("areYouSure"),
		text: translate(
			"youAreAboutToDeleteThisAndAllItsChildren",
			false,
			`${name} [${getTranslatedName(translate, level)}]`
		),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: translate("yesDeleteId"),
		cancelButtonText: translate("cancel"),
		confirmButtonColor: "#EF4444",
		cancelButtonColor: "#b5bec9",
	}).then((result) => {
		if (result.isConfirmed) callback();
	});
};

/**
 * Runs a given action with error handling, and shows appropriate toast messages
 * Finally reloads according to a reload function given
 * @param {*} action Function -> action to run, i.e. create
 * @param {*} reload Function -> final reload callback
 * @param {*} translate translate hook method
 * @param {*} key translation key
 * @param {*} level address level
 */
export const actionAndReload = async (
	action,
	reload,
	translate,
	key,
	level
) => {
	let res = null;
	try {
		res = await action();
	} catch (err) {
		res = { succeeded: false, message: err };
	}

	if (!res.succeeded) {
		showErrorToast(translate);
	} else {
		showSuccessToast(translate, key, level);
	}

	reload();
};
