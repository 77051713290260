import AbstractService from "services/Service";

const endpoint = "modules";

class ModuleService extends AbstractService {
	constructor() {
		super(endpoint);
		this.endpoint = endpoint;
	}
}
export default ModuleService;
