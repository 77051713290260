import Component from "./component";
import routes, { privateAccessibleRoutes } from "./routes";
import portals from "./portals";

const module = {
	Component,
	routes,
	portals,
	privateAccessibleRoutes,
};

export default module;
