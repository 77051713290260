import CheckBox from '../../../CheckBox';
const Option = ({ option, isSelected = false, onClick = {} }) => {
	return (
		<div
			onClick={() => onClick(option)}
			className={`option cursor-pointer text-sm px-4 py-0.5 border-b-2  hover:bg-gray-200 
                ${isSelected ? 'bg-gray-100 border-gray-100' : 'border-gray-50'}
        `}
		>
			<div className='flex flex-row items-center'>
				<div className='flex items-center'>
					<CheckBox selected={isSelected} />
				</div>
				<div className='flex flex-row w-full  items-center justify-between'>
					{option?.html ? (
						<div dangerouslySetInnerHTML={{ __html: option.html }}></div>
					) : (
						<>
							<div>{option.label}</div>
							{option.icon && (
								<div className='text-lg text-gray-500'>
									<i className={option.icon}></i>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default Option;
