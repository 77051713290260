import { FormEntry } from 'components/form';
import { Input, ReSelect } from 'components/ui/Input';

const FormElement = ({
	label,
	name,
	type,
	placeholder = '',
	value = null,
	defaultValue = null,
	options = [],
	otherOptions = null,
	onChange,
	error,
	disabled = false,
	isView = false,
}) => {
	return (
		<FormEntry label={label}>
			{type === 'date' ? (
				<Input
					isView={isView}
					type='date'
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue || null}
					disabled={disabled}
					{...otherOptions}
				/>
			) : type === 'select' ? (
				<ReSelect
					isView={isView}
					name={name}
					options={options}
					placeholder={placeholder}
					defaultValue={defaultValue || null}
					disabled={disabled}
					{...otherOptions}
				/>
			) : (
				<Input
					isView={isView}
					type={type}
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue || null}
					disabled={disabled}
					{...otherOptions}
				/>
			)}
		</FormEntry>
	);
};

export default FormElement;
