import React from "react";
import { Navigate } from "react-router-dom";

import NotImplemented from "components/system/NotImplemented";

const routes = [
	{ index: true, element: <NotImplemented /> },
	{ path: "tasks", element: <NotImplemented /> },
	{ path: "workflows", element: <NotImplemented /> },
	{ path: "manage", element: <NotImplemented /> },
	{ path: "settings", element: <NotImplemented /> },

	{ path: "*", element: <Navigate to={""} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
