// src/columns/BaseColumn.js

class BaseColumn {
	/**
	 * Constructs a new BaseColumn instance.
	 * @param {string} name - The unique identifier for the column.
	 * @param {string} type - The data type of the column (e.g., 'string', 'number', 'date').
	 */
	constructor(name, type) {
		if (!name || typeof name !== 'string') {
			throw new Error("Column 'name' must be a non-empty string.");
		}
		if (!type || typeof type !== 'string') {
			throw new Error("Column 'type' must be a non-empty string.");
		}

		this.name = name;
		this.title = null;
		this.key = name;
		this.type = type;
		this.sortKey = null;
		this.hidden = false;
		this.render = null;
		this.filter = null;
		this.meta = null;
		this.order = 0;
	}

	/**
	 * Gets the display label for the column.
	 * Override this method in subclasses if needed.
	 * @returns {string}
	 */
	getLabel() {
		return this.name;
	}

	getTitle() {
		return this.title;
	}

	/**
	 * Determines if the column is sortable.
	 * Override this method in subclasses if needed.
	 * @returns {boolean}
	 */
	isSortable() {
		return this.sortKey !== null;
	}

	/**
	 * Determines if the column is filterable.
	 * Override this method in subclasses if needed.
	 * @returns {boolean}
	 */
	isFilterable() {
		return this.filter !== null;
	}

	/**
	 * Determines if the column is hidden.
	 * Override this method in subclasses if needed.
	 * @returns {boolean}
	 */
	isHidden() {
		return this.hidden;
	}

	/**
	 * Renders the cell content.
	 * Override this method in subclasses to provide custom rendering.
	 * @param {*} value - The cell value.
	 * @param {object} row - The entire row data.
	 * @returns {React.ReactNode}
	 */
	renderCell(value, row) {
		return value;
	}
}

export default BaseColumn;
