import { AddressColumn, DescriptionColumn, IdColumn, NameColumn, SimpleObjectColumn } from 'core/columns';
import { DateColumn, StringColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
import UserRender from 'modules/tasks/pages/Tasks/pages/Tasks/components/ModelRenders/UserRender';
class AssetModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'asset';
		this.pluralModelName = 'assets';
	}
	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(
			new StringColumn('number', {
				label: 'number',
				filter: {
					type: 'text',
					key: 'Number',
				},
			}),
		);
		this.columns.push(new DescriptionColumn({ hidden: true }));
		this.columns.push(new SimpleObjectColumn('type', 'name'));
		this.columns.push(new SimpleObjectColumn('status', 'name'));
		this.columns.push(new SimpleObjectColumn('responsiblePerson', 'name'));
		this.columns.push(new SimpleObjectColumn('category', 'name'));
		this.columns.push(
			new StringColumn('tags', {
				hidden: true,
				label: 'tags',
				filter: {
					type: 'text',
					key: 'Tags',
				},
			}),
		);
		this.columns.push(
			new StringColumn('manufacturer', {
				label: 'manufacturer',
				filter: {
					type: 'text',
					key: 'Manufacturer',
				},
				hidden: true,
			}),
		);

		this.columns.push(new AddressColumn('homeLocation'));
		this.columns.push(new AddressColumn('currentLocation'));
		this.columns.push(
			new StringColumn('serialNumber', {
				label: 'serialNumber',
				filter: {
					type: 'text',
					key: 'SerialNumber',
				},
			}),
		);
		this.columns.push(
			new StringColumn('model', {
				label: 'model',
				hidden: true,
				filter: {
					type: 'text',
					key: 'Model',
				},
			}),
		);
		this.columns.push(
			new DateColumn('expirationDate', {
				label: 'expirationDate',
				hidden: true,
				filter: {
					type: 'date',
					key: 'ExpirationDate',
				},
			}),
		);
		this.columns.push(
			new DateColumn('createdOn', {
				label: 'createdOn',
				filter: {
					type: 'date',
					key: 'CreatedOn',
				},
				hidden: true,
			}),
		);
		this.columns.push(
			new SimpleObjectColumn('createdBy', 'name', {
				hidden: true,
				render: (value) => <UserRender value={value} itemClassName={'px-3'} />,
			}),
		);

		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}
export default AssetModel;
