import { forwardRef, useEffect, useRef, useState } from 'react';

import Tooltip from 'components/ui/Input/internal/Tooltip';
import { useTranslations } from 'hooks';

const FileInput = forwardRef(
	({ isView = false, defaultValue = null, onChange = () => {}, onDelete = () => {}, ...props }, ref) => {
		const { translate } = useTranslations();

		let inputRef = useRef(null);
		if (ref) {
			inputRef = ref;
		}

		const [isImage, setIsImage] = useState(false);
		const [previewValue, setPreviewValue] = useState(defaultValue);
		const [fileUrl, setFileUrl] = useState(null);

		useEffect(() => {
			if (defaultValue) {
				setPreviewValue(defaultValue);
				setFileUrl(defaultValue);
			}
		}, [defaultValue]);

		useEffect(() => {
			const checkIfImage = (url) => {
				return new Promise((resolve) => {
					const img = new Image();
					img.onload = () => resolve(true);
					img.onerror = () => resolve(false);
					img.src = url;
				});
			};

			if (previewValue) {
				checkIfImage(previewValue).then((result) => {
					setIsImage(result);
				});
			}
		}, [previewValue]);

		const handleFileClick = (file) => {
			window.open(file, '_blank');
		};

		const handleFileChange = (e) => {
			onChange(e);
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const fileUrl = reader.result;
					setPreviewValue(fileUrl);
				};
				const blobUrl = URL.createObjectURL(file);
				setFileUrl(blobUrl);
				reader.readAsDataURL(file);
			}
		};

		const handleFileDelete = () => {
			setPreviewValue(null);
			setFileUrl(null);
			onDelete();
			inputRef.current.value = '';
		};

		return (
			<Tooltip text={translate(props?.title ?? null, true)}>
				<div
					className={`relative w-full ${props.containerClassName} bg-gray-50 
							border rounded-lg 
							border-gray-300 `}
				>
					{!props.hideLabel && props.label && (
						<label className={`block mb-1 text-xs font-medium text-gray-700`}>
							{translate(props?.label || '', true)}
						</label>
					)}

					{previewValue ? (
						<div className='relative group'>
							<div className='flex justify-center items-center py-1'>
								{isImage ? (
									<div>
										<img
											src={previewValue}
											alt='file'
											className='w-28 h-auto object-cover rounded-lg transition-transform duration-200 hover:scale-110 cursor-pointer'
										/>
									</div>
								) : (
									<div className='w-full h-28 flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-lg cursor-pointer'>
										{previewValue.match(/\.pdf$/) ? (
											<i className='ri-file-pdf-2-line text-red-500 text-3xl'></i>
										) : (
											<i className='ri-file-text-line text-gray-500 text-3xl'></i>
										)}
									</div>
								)}
							</div>
							<div className='absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200'>
								<div className='flex space-x-2'>
									<button
										type='button'
										className='text-gray-800 p-2 rounded-full bg-gray-100 hover:bg-gray-200'
										onClick={() => handleFileClick(fileUrl)}
									>
										<i className='ri-download-line text-xl'></i>
									</button>
									<button
										type='button'
										className='text-gray-800 p-2 rounded-full bg-gray-100 hover:bg-gray-200'
										onClick={() => handleFileDelete()}
									>
										<i className='ri-delete-bin-6-line text-xl'></i>
									</button>
								</div>
							</div>
						</div>
					) : null}

					{!isView && (
						<div className='flex flex-row items-center'>
							<input
								type='file'
								ref={inputRef}
								{...props}
								placeholder={translate(props?.placeholder || '', true)}
								className={`
							cursor-pointer
							text-gray-900 
							text-sm 
							focus:ring-blue-500 
							focus:border-blue-500 
							block w-full 
							p-2.5 
							my-1 
							file:cursor-pointer
							file:mr-5 file:py-2 file:px-3 file:border-0
							file:rounded-lg
							file:bg-primary-500 file:text-white
							${props.className}
						`}
								onChange={handleFileChange}
								// class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none"
							/>
						</div>
					)}
				</div>
			</Tooltip>
		);
	},
);

FileInput.defaultProps = {
	className: '',
	hideLabel: false,
};

export default FileInput;
