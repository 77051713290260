import { Form, FormEntry } from 'components/form';
import { CheckBox, Input } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useTranslations } from 'hooks';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

const EntraIdSetupForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const { getOne } = useCrud(props.service);
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const initiateData = () => {
		getOne('')
			.then((response) => {
				setData(response);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	useEffect(() => {
		initiateData();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-auto'>
				<Form ref={myForm}>
					<FormEntry label={'tenantId'}>
						<Input
							isView={isView}
							placeholder={'tenantId'}
							required={true}
							name='tenantId'
							defaultValue={data?.tenantId}
						/>
					</FormEntry>
					<FormEntry label={'clientId'}>
						<Input
							isView={isView}
							placeholder={'clientId'}
							required={true}
							name='clientId'
							defaultValue={data?.clientId}
						/>
					</FormEntry>
					<FormEntry label={'clientSecret'}>
						<Input
							isView={isView}
							placeholder={'clientSecret'}
							required={true}
							name='clientSecret'
							defaultValue={data?.clientSecret}
						/>
					</FormEntry>
					<FormEntry label={'isSyncEnabled'}>
						<CheckBox
							isView={isView}
							name='isSyncEnabled'
							label='isSyncEnabled'
							selected={data?.isSyncEnabled}
						/>
					</FormEntry>

					<FormEntry label={'runIntervalInMinutes'}>
						<Input
							isView={isView}
							placeholder={'i.e. 5000'}
							type='number'
							min={10}
							required={true}
							name='runIntervalInMinutes'
							defaultValue={data?.runIntervalInMinutes}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});
export default EntraIdSetupForm;
