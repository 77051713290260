import RuleService from "../../../services";
const TYPE_ID = 2;

class Service extends RuleService {
	constructor() {
		super(TYPE_ID);
	}

	async mapData(data) {
		//filter all the data keys which start with "custom_input"
		const customFields = Object.keys(data).filter((key) =>
			key.startsWith("custom_input_")
		);

		const customFieldValues = customFields.map((key) => {
			const field = key.split("_")[2];

			const dataValue = data[key];
			//if it consists of ,
			if (dataValue.includes(",")) {
				const values = dataValue.split(",");
				return {
					customFieldId: parseInt(field),
					values: values,
				};
			} else {
				return {
					customFieldId: parseInt(field),
					values: [dataValue],
				};
			}
		});

		//get the field keys which are not custom_input_
		const fields = Object.keys(data).filter(
			(key) => !key.startsWith("custom_input_")
		);

		const otherFields = fields.reduce((obj, key) => {
			return {
				...obj,
				[key]: data[key],
			};
		}, {});

		let response = await super.mapData(otherFields, true);
		response["changeTaskTypeRule"]["customFields"] = customFieldValues;

		return response;
	}
}
export default Service;
