import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";

import { runAction } from "modules/utils";
import { Each } from "utils/Each";
import { useTranslations } from "hooks";

const InputTypes = forwardRef((props, ref) => {
	const [inputTypes, setInputTypes] = useState([]);
	const [activeInputType, setActiveInputType] = useState(
		props?.data?.activeInputType?.id
	);
	const { translate } = useTranslations();
	const activeInputRef = useRef(null); // use a ref to track the active element instantly

	useEffect(() => {
		setActiveInputType(props?.data?.activeInputType?.id);
	}, [props?.data]);

	useEffect(() => {
		if (activeInputRef.current) {
			activeInputRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "nearest",
			});
		}
	}, [inputTypes]);

	const getTenantInputTypes = async () => {
		try {
			const res = await runAction("tenants", "getInputTypes");
			return res ?? null;
		} catch (err) {
			console.error(err);
			throw err;
		}
	};
	const chooseInputType = (inputType) => {
		setActiveInputType(inputType.id);
		props?.chooseInputType(inputType);
	};

	useEffect(() => {
		getTenantInputTypes().then((res) => setInputTypes(res));
	}, []);

	const getIcon = (inputName) => {
		let path;
		let filename = inputName + ".png";
		try {
			path = require(`../assets/InputIcons/${filename}`);
		} catch (error) {
			path = require(`../assets/InputIcons/Other.png`);
		}
		return <img src={path} alt={inputName} className="h-16 w-16 mr-2" />;
	};

	const InputType = ({ inputType, active = false, onClick = () => {} }) => {
		const activeClass = active ? "hover:bg-blue-100 bg-blue-100 " : "";
		return (
			<div
				ref={active ? activeInputRef : null}
				className={`hover:bg-gray-50 rounded-md ${activeClass}`}
				onClick={onClick}
			>
				<div
					className={`flex items-center  space-x-2 cursor-pointer opacity-90 pt-3 pl-2  hover:opacity-100 } `}
				>
					{getIcon(inputType.name)}
					<div className="flex flex-col justify-between">
						<span className="text-gray-700 font-semibold">
							{translate(inputType.name, true)}
						</span>
						<span className="text-xs text-gray-500">
							{translate(
								`inputType${inputType.name}Description`,
								true
							)}
						</span>
					</div>
				</div>
				<div className=" border-gray-200 border-b w-full  mt-3" />
			</div>
		);
	};

	return (
		<Each
			of={inputTypes}
			render={(inputType) => (
				<div>
					<InputType
						key={inputType.id}
						onClick={() => chooseInputType(inputType)}
						active={activeInputType === inputType.id}
						inputType={inputType}
					/>
				</div>
			)}
		/>
	);
});

export default InputTypes;
