import { StatusRender } from 'core/renders';
import { StringColumn } from './baseColumns';

/**
 *
 * map should be something like {1:green, 2:red, 3:blue}
 */
class StatusColumn extends StringColumn {
	constructor(name = 'status', map = null, options = {}) {
		if (!map && !options.render) {
			throw new Error('StatusColumn requires a map');
		}

		super(options.name || name, options);
		this.noDataValue = 'N/A';
		this.keyToRender = options.keyToRender || 'name';
		this.mapKey = options.mapKey || 'id';
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.render =
			options.render ||
			((value) => {
				if (!value) return this.noDataValue;
				return <StatusRender value={value[this.keyToRender]} color={map[value[this.mapKey]]} />;
			});
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default StatusColumn;
