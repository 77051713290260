import PermissionMap from 'config/PermissionMap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { withDashboardLayout } from '../../hoc';

const LocationsModule = () => {
	const { getPermissionMaps, findPageId, checkNestedPermissions } = PermissionMap();
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname.substring(1);
	const pageId = findPageId(pathName, config.pages);

	const permissionsMap = getPermissionMaps(pageId);
	const hasPermission = checkNestedPermissions(permissionsMap);

	// If no permission, navigate to the next available id
	if (!hasPermission) {
		config.pages.forEach((page) => {
			const permissionsMap = getPermissionMaps(page.id);
			const hasPermission = checkNestedPermissions(permissionsMap);

			if (hasPermission) {
				navigate(`/${page.id}`);
				return;
			}
		});
	}
	return <Outlet context={{ permissionsMap }} />;
};

const config = {
	page: 'locations',
	pages: [
		{ id: 'locations/locations', label: 'locations', to: '' },
		// {
		// 	id: "locations/external",
		// 	label: "externalLocations",
		// 	to: "external",
		// },
		{ id: 'locations/settings', label: 'settings', to: 'settings' },
	],
};

export default withDashboardLayout(LocationsModule, config);
