const PlannedDepartureRender = ({ value, row }) => {
	const now = new Date();
	const date = new Date(value);

	const isToday = now.toDateString() === date.toDateString();

	const localTime = isToday
		? date.toLocaleString('en-GB', {
				hour: '2-digit',
				minute: '2-digit',
		  })
		: date.toLocaleString('en-GB', {
				day: '2-digit',
				month: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
		  });

	if (row?.scheduledTimeType?.name?.toLowerCase() === 'departure') {
		return (
			<div>
				<span className='pr-2'>D</span>
				<span>{localTime}</span>
			</div>
		);
	} else return localTime;
};
export default PlannedDepartureRender;
