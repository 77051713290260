import { useState } from "react";
import { Toggle } from "components/ui/Input";
import { useTranslations } from "hooks";
const AllocationItem = ({ name, checked = false }) => {
	const { translate } = useTranslations();
	const [checkedState, setCheckedState] = useState(checked);

	return (
		<tr>
			<td colSpan={2}>
				<div className="flex flex-row space-x-3 items-center ml-1 pb-3">
					<Toggle
						onClick={(value) => setCheckedState(value)}
						className={"pr-3"}
						small={true}
						name={name}
						defaultChecked={checked}
					/>
					<div
						className={`text-sm ${
							checkedState ? "opacity-100" : "opacity-50"
						}`}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: translate(name),
							}}
						/>
					</div>
				</div>
			</td>
		</tr>
	);
};
export default AllocationItem;
