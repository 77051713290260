import { Navigate } from 'react-router-dom';

import NotImplemented from 'components/system/NotImplemented';
import Assets from '../pages/assets';
import Settings from '../pages/settings';

const routes = [
	{ index: true, element: <Navigate to='assets' replace /> },
	// { path: 'dashboard', element: <NotImplemented /> },
	{ path: 'assets', element: <Assets /> },
	{ path: 'audit', element: <NotImplemented /> },
	{ path: 'settings', element: <Settings /> },

	{ path: '*', element: <Navigate to={''} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
