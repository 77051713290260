import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';

const LocationsPermissions = () => {
	const { hasPermission } = usePermissions();
	return {
		locations: {
			Create: hasPermission(RESOURCE.Address, PERMISSION.Create),
			View: hasPermission(RESOURCE.Address, PERMISSION.View),
			Update: hasPermission(RESOURCE.Address, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.Address, PERMISSION.Delete),
			Import: hasPermission(RESOURCE.Address, PERMISSION.Import),
			Export: hasPermission(RESOURCE.Address, PERMISSION.Export),
		},
		settings: {
			General: {
				RoomType: {
					Create: hasPermission(RESOURCE.Address, PERMISSION.Create),
					View: hasPermission(RESOURCE.Address, PERMISSION.View),
					Update: hasPermission(RESOURCE.Address, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Address, PERMISSION.Delete),
				},
			},
		},
	};
};

export default LocationsPermissions;
