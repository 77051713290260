import { Navigate } from 'react-router-dom';

import NotImplemented from 'components/system/NotImplemented';

import Patients from '../pages/Patients';
import Users from '../pages/Users';

const routes = [
	{ index: true, element: <Navigate to='users' replace /> },
	{ path: 'users/*', element: <Users /> },
	{ path: 'patients/*', element: <Patients /> },
	{ path: 'visitors/*', element: <NotImplemented /> },
	{ path: 'settings/*', element: <NotImplemented /> },

	{ path: '*', element: <Navigate to={''} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
