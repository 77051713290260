import { useEffect, useState } from 'react';

const useDebounce = (value, delay, callBack = () => {}) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		// Set a timeout to update debouncedValue after the specified delay
		const handler = setTimeout(() => {
			callBack(value);
			setDebouncedValue(value);
		}, delay);

		// Clear the timeout if the value changes before the delay period
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

export default useDebounce;
