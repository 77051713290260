import { EnumLabelColumn, IdColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class DocumentationModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'documentation';
		this.pluralModelName = 'documentations';
	}
	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(
			new EnumLabelColumn('source', {
				label: 'type',
				enumKey: 'DocumentationSource',
			}),
		);
		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}
export default DocumentationModel;
