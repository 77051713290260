import React, { useState } from "react";

import PropTypes from "prop-types";

const SegmentedControl = ({
	segments,
	withIcons = false,
	onSegmentChange = () => {},
}) => {
	const [selectedSegment, setSelectedSegment] = useState(0);

	const handleSegmentChange = (index) => {
		setSelectedSegment(index);
		onSegmentChange(index);
	};

	return (
		<div className="flex rounded-md bg-gray-200 border border-slate-300">
			{segments.map((segment, index) => (
				<button
					key={index}
					className={`${
						index === 0
							? "rounded-l-md"
							: index === segments.length - 1
							? "rounded-r-md"
							: ""
					} ${
						index === selectedSegment
							? "bg-primary-500 text-white"
							: "bg-gray-200 text-gray-500"
					} py-1 px-3`}
					onClick={() => handleSegmentChange(index)}
				>
					{withIcons ? <i className={segment}></i> : { segment }}
				</button>
			))}
		</div>
	);
};

SegmentedControl.propTypes = {
	segments: PropTypes.arrayOf(PropTypes.string),
	withIcons: PropTypes.bool,
	onSegmentChange: PropTypes.func,
};

export default SegmentedControl;
