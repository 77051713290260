import { renderToString } from 'react-dom/server';
import { TruncateBeginningString, TruncateString } from 'utils';
import StringWrapRender from './components/StringWrapRender';

const TruncatedTextRender = ({ value, num = 64, showTooltip = true, fromBeginning = false }) => {
	if (!value) return '';

	const stringToReturn = fromBeginning ? TruncateBeginningString(value, num) : TruncateString(value, num);
	return (
		<div
			data-tooltip-variant='dark'
			data-tooltip-html={showTooltip && value.length > num ? renderToString(StringWrapRender(value)) : ''}
			data-tooltip-id={`table-tooltip`}
		>
			{stringToReturn}
		</div>
	);
};
export default TruncatedTextRender;
