import { CheckBox } from "components/ui/Input";

const FooterInput = ({
	name = "",
	isDisabled = false,
	onChange = () => {},
	selected = null,
}) => {
	return (
		<tr className="border-b border-gray-200">
			<td></td>
			<td>
				<div className="pb-1">
					<CheckBox
						onChange={(e) => {
							onChange(e.target.checked);
						}}
						selected={selected}
						disabled={isDisabled}
						name={name}
						labelClass="text-xs"
						label={"hideThisFieldInTemplatesWithNote"}
					/>
				</div>
			</td>
		</tr>
	);
};
export default FooterInput;
