import React, { useState } from "react";
import { useTranslations } from "hooks";

const Node = ({
	id,
	name,
	taskGroups = [],
	dispatchingMethod = null,
	dispatchingMethods = [],
	level = 0,
	color = "#fffff",
	children = [],
	onClick = () => {},
	openEditTeam = () => {},
	extraProps = {},
}) => {
	const { translate } = useTranslations();
	const [expand, setExpand] = useState(false);

	const position = 100 - 4 * level;
	return (
		<div className="flex flex-col items-end w-[100%]  ">
			<div
				style={{ width: `${position}%` }}
				onClick={() => level !== 5 && setExpand((e) => !e)}
				className={`group flex flex-row items-center overflow-hidden cursor-pointer  hover:bg-gray-100 hover:opacity-80 border-b border-gray-200 py-3`}
			>
				<div className={`h-full w-[5px] bg-slate-800`} />
				<div className=" flex flex-row w-full items-center ">
					{children ? (
						<i
							className={`${
								expand
									? "ri-checkbox-indeterminate-line"
									: "ri-add-box-line"
							} ml-3 text-xl text-slate-500`}
						></i>
					) : (
						<i
							className={`ri-checkbox-blank-line ml-3 text-xl text-slate-500`}
						></i>
					)}

					<div className="ml-3 grid grid-cols-1 md:grid-cols-3 gap-4 w-full items-center  ">
						<div className="flex flex-row items-center space-x-5">
							<div>
								<div
									className="rounded ml-2"
									style={{
										backgroundColor: color,
										width: "22px",
										height: "22px",
									}}
								/>
							</div>
							<div>
								<div className="h-4 text-xs font-semibold text-slate-500">
									{translate("team")}
								</div>
								<div
									onClick={(e) => {
										e.stopPropagation();
										onClick(e);
									}}
									className="hover:underline font-small text-md"
								>
									{name}
								</div>
							</div>
						</div>

						<div className="hidden md:block">
							<div className="h-4 text-xs font-semibold text-slate-500">
								{translate("authorizedForTaskGroups")}
							</div>
							<div className="font-small text-md">
								{taskGroups &&
									taskGroups
										.map((taskGroup) => taskGroup.name)
										.join(", ")}
							</div>
						</div>

						<div className="hidden md:block">
							<div className="h-4 text-xs font-semibold text-slate-500">
								{translate("dispatchingMethod")}
							</div>
							<div className="font-small text-md">
								{translate(
									dispatchingMethods.find(
										(method) =>
											method.id === dispatchingMethod
									)?.name
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{expand ? (
				children &&
				children.map((item) => (
					<Node
						dispatchingMethod={item.dispatchingMethod}
						dispatchingMethods={dispatchingMethods}
						key={`${level + 1}-${item.id}`}
						level={level + 1}
						{...item}
						onClick={() => {
							onClick(item);
							openEditTeam(item, extraProps); // call openEditTeam with item
						}}
						extraProps={extraProps}
						openEditTeam={openEditTeam}
					/>
				))
			) : (
				<></>
			)}
		</div>
	);
};

export default Node;
