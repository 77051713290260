export const getDefaultValue = (defaultValue, isMultiple) => {
	if (defaultValue) {
		try {
			let parsedValue = JSON.parse(defaultValue);
			if (Array.isArray(parsedValue)) {
				if (isMultiple) {
					if (
						parsedValue &&
						Object.keys(parsedValue[0]).includes("Id")
					) {
						parsedValue = parsedValue.map((value) => {
							const id = value.Id || value.id;
							return isNaN(id) ? id : parseInt(id);
						});
					}

					return parsedValue;
				}
				if (parsedValue.length === 1) {
					return parsedValue[0];
				}
				return parsedValue;
			}

			return parsedValue;
		} catch (e) {
			return defaultValue;
		}
	}
	return null;
};

export const convertOptions = (options) => {
	return options.map((option) => {
		const id = option.Id || option.id;

		const value = isNaN(id) ? id : parseInt(id);

		return {
			value,
			label: option.Name || option.name,
		};
	});
};
