import { useTranslations } from 'hooks';
import { forwardRef } from 'react';

const FieldControl = forwardRef(
	(
		{
			field,
			index,
			orderUpVisible = false,
			orderUp = () => {},
			orderDownVisible = false,
			orderDown = () => {},
			duplicateField = () => {},
			removeField = () => {},
			isView = false,
		},
		ref,
	) => {
		const { translate } = useTranslations();

		if (isView) return <></>;
		return (
			<tr ref={ref}>
				<td colSpan={2}>
					<div className='flex justify-between pt-2 pb-2'>
						<div className='pt-3 pb-1 flex  space-x-8 items-center'>
							<div
								onClick={() => orderUpVisible && orderUp(field)}
								className={`text-sm space-x-2 font-light  ${
									!orderUpVisible ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
								}`}
							>
								<i className='ri-arrow-up-line'></i> <span>{translate('orderUp')}</span>
							</div>
							<div
								onClick={() => orderDownVisible && orderDown(field)}
								className={`text-sm space-x-2 font-light 
												${!orderDownVisible ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
												`}
							>
								<i className='ri-arrow-down-line'></i> <span>{translate('orderDown')}</span>
							</div>
						</div>
						{field?.customFieldId && (
							<div className='flex pt-3 pb-1 space-x-6'>
								<div
									onClick={() => duplicateField(field)}
									className='font-semibold space-x-1 text-sm cursor-pointer opacity-70 hover:opacity-100'
								>
									<i className='ri-file-copy-line'></i> <span>{translate('copy')}</span>
								</div>
								<div
									onClick={() => removeField(index)}
									className='font-semibold space-x-1 text-sm cursor-pointer opacity-70 hover:opacity-100'
								>
									<i className='ri-delete-bin-2-line'></i> <span>{translate('delete')}</span>
								</div>
							</div>
						)}
					</div>
				</td>
			</tr>
		);
	},
);
export default FieldControl;
