import { Form, FormEntry } from 'components/form';
import { Input, SearchSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm } from 'hooks';
import TeamService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import OptionsInput from '../../../components/OptionsInput';

const ActionForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const teamService = new TeamService();

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							name='name'
							defaultValue={data?.name || ''}
							required={true}
							placeholder={'name'}
						/>
					</FormEntry>

					<FormEntry label='teams'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='teamIdsArray'
							service={teamService}
							defaultSelected={data?.teams?.map((team) => team.id)}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='requestForOverXMinutes'>
						<OptionsInput
							isView={isView}
							name='completeAndCopy.requestForOverXMinutesJson'
							defaultValue={data?.completeAndCopy?.requestForOverXMinutes}
							required={false}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default ActionForm;
