import { SyncLoader } from "react-spinners";
const Loading = ({ status = true, isAbsolute = true }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: isAbsolute ? 'absolute' : 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000
        }}>
            <SyncLoader
                color={"#179eff"}
                loading={status}
                size={20}
                aria-label="Loading Spinner"
            />
        </div>
    )
}

export default Loading;