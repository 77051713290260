import AssetService from 'modules/assets/pages/assets/services';
import PatientService from 'modules/persons/pages/Patients/services';
import DocumentationService from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/Documentations/services';
import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import UrgenciesService from 'modules/tasks/pages/Settings/pages/Urgencies/services';

const getService = (serviceName, taskGroupId = null) => {
	switch (serviceName) {
		case 'UrgenciesService':
			return new UrgenciesService();
		case 'PatientsService':
			return new PatientService();
		case 'AssetsService':
			return new AssetService();
		case 'DocumentationService':
			const documentationService = new DocumentationService();
			documentationService.setParent(taskGroupId);
			return documentationService;
		case 'TaskTypeService':
			const taskTypeService = new TaskTypeService();
			taskTypeService.setParent(taskGroupId);
			return taskTypeService;
		default:
			return null;
	}
};
export default getService;
