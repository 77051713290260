import { DateTimeRender } from 'core/renders';
import { forwardRef, useEffect, useRef, useState } from 'react';
import Input from './Input';
import ViewInput from './internal/ViewInput';

const DateTimeInput = forwardRef(({ name, defaultValue, value, isView = false, ...props }, ref) => {
	const [date, setDate] = useState(null);
	const [isoDate, setIsoDate] = useState(null);
	const hasMounted = useRef(false);
	const inputRef = useRef(ref);

	const convertToIsoDate = (date) => {
		try {
			const isoDate = new Date(date).toISOString();
			setIsoDate(isoDate);
		} catch (error) {
			setIsoDate(null);
		}
	};

	const applyCurrentTime = () => {
		const now = new Date();
		const timezoneOffset = now.getTimezoneOffset() * 60000;
		const localISOTime = new Date(now - timezoneOffset).toISOString().substring(0, 16);
		setDate(localISOTime);
		setIsoDate(now.toISOString().substring(0, 16));

		inputRef.current.value = localISOTime;
		inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
	};

	const convertToDate = (isoDate) => {
		if (!isoDate) return;
		try {
			const date = new Date(isoDate);
			const timezoneOffset = date.getTimezoneOffset() * 60000;
			const localISOTime = new Date(date - timezoneOffset).toISOString().substring(0, 16);
			setDate(localISOTime);
		} catch (error) {
			console.error('Invalid ISO date format:', error);
		}
	};

	useEffect(() => {
		if (!hasMounted.current && defaultValue) {
			setIsoDate(defaultValue);
			convertToDate(defaultValue);
			hasMounted.current = true;
		}
	}, [defaultValue]);

	useEffect(() => {
		if (value) {
			setIsoDate(value);
			convertToDate(value);
		}
	}, [value]);

	if (isView) {
		return <ViewInput value={<DateTimeRender value={isoDate} />} {...props} />;
	}

	return (
		<>
			<input type='hidden' name={name} value={isoDate} />
			<div className='relative'>
				<Input
					ref={inputRef}
					{...props}
					defaultValue={date}
					onChange={(event) => convertToIsoDate(event.target.value)}
					type='datetime-local'
				/>
				<button
					type='button'
					onClick={applyCurrentTime}
					className='absolute inset-y-0 right-6 flex items-center pr-3 opacity-60 hover:opacity-100 cursor-pointer'
					aria-label='Set current time'
				>
					<i className='ri-map-pin-time-line'></i>
				</button>
			</div>
		</>
	);
});

export default DateTimeInput;
