import React from "react";
import { Link } from "react-router-dom";
import { useTranslations } from "hooks";

const LinkTabs = ({ activeItem = "", items = [], itemKeyName = "path" }) => {
	const { translate } = useTranslations();

	return (
		<div className="flex border-b bg-white py-2 space-x-4 -m-4 mb-4">
			{items.map((item, index) => (
				<Link
					key={index}
					className={`py-2 px-4 font-medium ${
						activeItem === item[itemKeyName]
							? "text-black"
							: "text-gray-500"
					}`}
					to={item[itemKeyName]}
				>
					{translate(item.name)}
				</Link>
			))}
		</div>
	);
};

export default LinkTabs;
