import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, ReSelect, SearchSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm, useTranslations } from 'hooks';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

import RoomTypeService from 'modules/addresses/pages/settings/pages/RoomType/services';
import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';

const PriorityRuleForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();

	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const myForm = useRef(null);

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [taskTypesOptions, setTaskTypesOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();
	const roomTypeService = new RoomTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				const l_taskGroupId = res?.priorityRule?.taskGroup?.id;
				setData(res);
				setLoading(false);

				fetchTaskTypes(l_taskGroupId);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='type' value={props.service.getTypeId()} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='description'>
						<TextArea
							isView={isView}
							placeholder={'description'}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>

					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							required={true}
							name='priorityRule.taskGroupId'
							defaultValue={data?.priorityRule?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTypes'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='priorityRule.taskTypeIdsArray'
							options={taskTypesOptions}
							defaultSelected={data?.priorityRule?.taskTypeIds}
							excludeSelectedOptionsWhenNotInOptionsList
							isSingleValueOnSelectAll
						/>
					</FormEntry>

					<FormEntry label='fromRoomTypes'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='priorityRule.fromRoomTypeIdsArray'
							service={roomTypeService}
							defaultSelected={data?.priorityRule?.fromRoomTypeIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='toRoomTypes'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='priorityRule.toRoomTypeIdsArray'
							service={roomTypeService}
							defaultSelected={data?.priorityRule?.toRoomTypeIds}
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label='priority'>
						<Input
							isView={isView}
							type='number'
							min={0}
							max={5}
							placeholder={'priority'}
							required={true}
							name='priorityRule.priority'
							defaultValue={data?.priorityRule?.priority}
						/>
					</FormEntry>
					<FormEntry label={'considerLateAfterMin'}>
						<Input
							isView={isView}
							placeholder={'considerLateAfterMin'}
							type='number'
							min={0}
							name='priorityRule.considerLateAfterMin'
							defaultValue={data?.priorityRule?.considerLateAfterMin}
							sideLabel={'mins'}
						/>
					</FormEntry>
					<FormEntry label='ifLateIncreasePriorityWith'>
						<Input
							isView={isView}
							type='number'
							min={0}
							max={5}
							placeholder={'ifLateIncreasePriorityWith'}
							name='priorityRule.ifLateIncreasePriorityWith'
							defaultValue={data?.priorityRule?.ifLateIncreasePriorityWith}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default PriorityRuleForm;
