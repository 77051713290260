import React from "react";

const InternalFormDivider = ({ children, className = "" }) => {
	return (
		<tr>
			<td colSpan="2">
				<div className={`font-bold my-4 text-sm ${className} `}>{children}</div>
			</td>
		</tr>
	);
};

export default InternalFormDivider;
