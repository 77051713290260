import TaskTemplatesPage from "./../pages/TaskTemplates/index";
import TaskTypesPage from "../pages/TaskTypes";

const items = [
	{
		name: "taskTemplates",
		path: "/tasks/manage/{taskgroup}/taskTemplates",
		key: "taskTemplates",
		element: <TaskTemplatesPage />,
	},
	{
		name: "taskTypes",
		path: "/tasks/manage/{taskgroup}/taskTypes",
		key: "taskTypes",
		element: <TaskTypesPage />,
	},
	/** We decided to comment this for now, maybe we will use this one later */
	// {
	// 	name: "settings",
	// 	key: "settings",
	// 	path: "/tasks/manage/{taskgroup}/settings",
	// 	element: <NotImplemented />,
	// },
];
export default items;
