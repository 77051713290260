import React, { useState, useMemo } from "react";

import { Input } from "components/ui/Input";

import { checkStrength } from "../../../services/passwordUtils";

import Strength from "../../../components/PasswordStrength";
import { useTranslations } from "hooks";
import PasswordValidator from "modules/auth/components/PasswordValidator";

const Password = ({ selectAndNext = () => {}, isFinish = false }) => {
	const { translate } = useTranslations();
	const [input, setInput] = useState({ password: "", confPassword: "" });
	const [passwordIsValid, setPasswordIsValid] = useState(false);
	const [passwordHelperIsOpen, setPasswordHelperIsOpen] = useState(false);

	const disabledButton = useMemo(() => {
		return !(
			input.password.length > 6 && input.password === input.confPassword
		);
	}, [input]);

	return (
		<div className="flex flex-col pt-5 pb-0 w-full h-full">
			<div className="flex flex-col items-center mb-6">
				<img
					src={require("../../../assets/password.png")}
					className="w-20 h-20 mb-4"
					alt="password icon"
				/>
				<h1 className="text-4xl font-medium">
					{translate("changePassword")}
				</h1>
			</div>
			<div className="flex flex-col w-2/3 self-center items-center h-full">
				<Input
					label="newPassword"
					type="password"
					value={input.password}
					onChange={(e) =>
						setInput((i) => ({ ...i, password: e.target.value }))
					}
					placeholder="newPassword"
					className="mb-4"
				/>
				<div>
					<PasswordValidator
						password={input.password}
						onChange={(isValid) => {
							setPasswordHelperIsOpen(!isValid);
							setPasswordIsValid(isValid);
						}}
						isOpen={passwordHelperIsOpen}
					/>
				</div>
				{input.password.length > 0 && passwordIsValid && (
					<Input
						label="confirmPassword"
						type="password"
						value={input.confPassword}
						onChange={(e) =>
							setInput((i) => ({
								...i,
								confPassword: e.target.value,
							}))
						}
						placeholder="confirmPassword"
						className="mb-4"
					/>
				)}
			</div>
			<div className="flex flex-row-reverse pt-2 pb-2">
				<div
					className={`${
						disabledButton
							? "cursor-not-allowed opacity-50"
							: "cursor-pointer"
					} group font-medium flex px-3 py-2 hover:pr-2 justify-center items-center transition-all`}
					onClick={() =>
						!disabledButton && selectAndNext(input.password)
					}
				>
					{isFinish ? (
						translate("finish")
					) : (
						<>
							{translate("next")}{" "}
							<i className="ri-arrow-right-line ml-2 text-xl "></i>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Password;
