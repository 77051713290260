import AbstractService from 'services/Service';
const endpoint = 'assets';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async create(data) {
		const { file, image, imageString, fileString, ...dataToMap } = data;
		const mappedData = await this.mapData(dataToMap);
		const formData = this.#getFormDataFromObject(mappedData);
		if (image) {
			formData.append('image', image);
		}
		if (file) {
			formData.append('file', file);
		}
		return await this.api.postForm(this.endpoint, formData);
	}

	async update(id, data) {
		const { file, image, imageString, fileString, ...dataToMap } = data;
		const mappedData = await this.mapData(dataToMap);
		const formData = this.#getFormDataFromObject(mappedData);
		if (image && image.name) {
			formData.append('image', image);
		} else if (imageString) {
			formData.append('image', imageString);
		}
		if (file && file.name) {
			formData.append('file', file);
		} else if (fileString) {
			formData.append('file', fileString);
		}
		return await this.api.putForm(`${this.endpoint}/${id}`, formData);
	}

	async postImport(file) {
		const form = new FormData();
		form.append('file', file);
		return await this.api.postForm(`${this.endpoint}/import`, form);
	}

	#getFormDataFromObject(data) {
		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			if (data[key] !== null) {
				formData.append(key, data[key]);
			}
		});
		return formData;
	}
}
export default Service;
