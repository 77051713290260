import React, { useState, useCallback, useMemo, useEffect } from "react";
import AsideContext from "./AsideContext";

import Aside from "components/system/Aside";
import HelperAside from "components/system/HelperAside";

const initialState = {
	open: false,
	right: false,
	Component: null,
	componentProps: {},
	saveCallback: (data) => {},
	autoClose: true,
	isLoading: false,
};

const AsideProvider = ({ children, offset = 0 }) => {
	const [asideState, setAsideState] = useState(initialState);
	const [helperAsideState, setHelperAsideState] = useState(initialState);

	const updateToggleState = useCallback((forceClose = false) => {
		setAsideState((prevState) => ({
			...prevState,
			open: forceClose ? false : !Boolean(prevState?.open),
		}));

		if (forceClose) updateToggleHelperState(true);
	}, []);

	const updateToggleHelperState = useCallback((forceClose = false) => {
		setHelperAsideState((prevState) => ({
			...prevState,
			open: forceClose ? false : !Boolean(prevState?.open),
		}));
	}, []);

	const updateConfig = useCallback((config = false, secondConfig = false) => {
		config && setAsideState((prevState) => ({ ...prevState, ...config }));
		secondConfig &&
			setHelperAsideState((prevState) => ({
				...prevState,
				...secondConfig,
			}));

		//We were having problem when hiding at first the save button on first aside and displaying second aside.
		//When we close both, it was hiding save button on next render
		if (config && secondConfig && !secondConfig.open && !config.open) {
			setAsideState((prevState) => ({
				...prevState,
				hideSaveButton: false,
			}));
		}
	}, []);

	const providerValue = useMemo(
		() => ({
			asideState,
			updateConfig,
			updateToggleState,
		}),
		[asideState, updateConfig, updateToggleState]
	);

	const { open } = asideState;
	return (
		<AsideContext.Provider value={providerValue}>
			<div
				className={`relative overflow-auto`}
				style={{ height: `calc(100% - ${offset}px)` }}
			>
				<Aside
					onClose={() => updateToggleState(true)}
					{...asideState}
				/>
				<HelperAside
					onClose={() => updateToggleHelperState(true)}
					{...helperAsideState}
				/>
				<main
					className={`p-4 relative h-full w-full ${
						open ? "overflow-y-hidden" : "overflow-y-auto"
					}`}
				>
					{children}
					<div className="h-5" />
				</main>
			</div>
		</AsideContext.Provider>
	);
};

export default AsideProvider;
