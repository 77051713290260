import OnboardingPortal from "./OnboardingPortal";
import UserSettingsPortal from "./UserSettingsPortal";

export const portals = [
	{
		id: "auth:onboarding",
		element: OnboardingPortal,
		initialState: {},
	},
	{
		id: "auth:user-settings",
		element: UserSettingsPortal,
		initialState: {},
	},
];

export const PORTALS = {
	onboarding: "auth:onboarding",
	userSettings: "auth:user-settings",
};

export default portals;
