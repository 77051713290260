import { useState, useEffect } from "react";
import { Toggle, Input } from "components/ui/Input";
import { useTranslations } from "hooks";

const DayToggle = ({
	day,
	startTime = "",
	endTime = "",
	isChecked = false,
	scheduleDay = null,
	index = 0,
}) => {
	const { translate } = useTranslations();
	const [checked, setChecked] = useState(isChecked);
	const [startTimeState, setStartTimeState] = useState(startTime);
	const [endTimeState, setEndTimeState] = useState(endTime);
	const [error, setError] = useState(false);

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);
	return (
		<div>
			<div className="flex flex-row space-x-2 items-center">
				<input
					type="hidden"
					name={`timeScheduleItems[${index}].scheduleDay`}
					value={scheduleDay}
				/>

				<div className="flex-2">
					<Toggle
						name={`timeScheduleItems[${index}].enabled`}
						value={checked}
						checked={checked}
						onClick={() => {
							setChecked(!checked);
						}}
					/>
				</div>
				<div className="flex-1 pl-1">{translate(day)}</div>
				<div className="flex-1">
					<Input
						ref={(input) => {
							if (input) {
								input.setCustomValidity(
									startTimeState > endTimeState
										? translate(
												"startTimeMustBeBeforeEndTime"
										  )
										: ""
								);
							}
						}}
						onChange={(e) => {
							if (endTimeState && e.target.value > endTimeState) {
								setError(
									translate(
										"startTimeCannotBeLaterThanEndTime"
									)
								);
							} else {
								setError(false);
							}
							setStartTimeState(e.target.value);
						}}
						name={`timeScheduleItems[${index}].start`}
						defaultValue={startTime}
						required={checked}
						disabled={!checked}
						type="time"
						placeholder={"i.e. 08:00"}
					/>
				</div>
				<div className="flex-3 ">-</div>
				<div className="flex-1">
					<Input
						ref={(input) => {
							if (input) {
								input.setCustomValidity(
									startTimeState > endTimeState
										? translate(
												"startTimeMustBeBeforeEndTime"
										  )
										: ""
								);
							}
						}}
						onChange={(e) => {
							if (e.target.value < startTimeState) {
								setError(
									translate(
										"endTimeCannotBeEarlierThanStartTime"
									)
								);
							} else {
								setError(false);
							}
							setEndTimeState(e.target.value);
						}}
						name={`timeScheduleItems[${index}].end`}
						defaultValue={endTime}
						required={checked}
						disabled={!checked}
						type="time"
						placeholder={"i.e. 08:00"}
					/>
				</div>
			</div>
			{error && (
				<div className="flex justify-end text-red-500 text-sm">
					{" "}
					{error}{" "}
				</div>
			)}
		</div>
	);
};
export default DayToggle;
