// Import necessary modules and styles
import React, { useState } from "react";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 38;

// Define the MultiSelect component
const MultiLineSelect = ({
	options,
	onChange,
	multiSelect,
	lines,
	showFilter,
	defaultValue,
}) => {
	// State to manage selected options
	const [selectedOptions, setSelectedOptions] = useState(
		defaultValue ? [defaultValue] : []
	);
	const [filtered, setFiltered] = useState([...options]);

	// Function to handle option selection
	const handleOptionClick = (option) => {
		let updatedSelection;

		if (multiSelect) {
			// For multi-select, toggle the selected state
			updatedSelection = selectedOptions.includes(option)
				? selectedOptions.filter((selected) => selected !== option)
				: [...selectedOptions, option];
		} else {
			// For single select, replace the selection with the clicked option
			updatedSelection = [option];
		}

		setSelectedOptions(updatedSelection);
		onChange(updatedSelection);
	};

	return (
		<div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 my-1">
			{/* Search bar (you can add your own search logic) */}
			{showFilter && (
				<input
					type="text"
					className="border-0 border-b px-1 py-2 w-full bg-gray-50 focus:ring-0 focus:ring-offset-0 focus:border-0"
					placeholder="Search..."
					onChange={(e) =>
						setFiltered(
							e.target.value.length > 0
								? [
										...options.filter((o) =>
											o.name.includes(e.target.value)
										),
								  ]
								: options
						)
					}
				/>
			)}
			<div
				className={`flex flex-col overflow-y-auto mt-2`}
				style={{ height: ITEM_HEIGHT * lines }}
			>
				{filtered.map((option) => (
					<div
						key={`${option.id}-${option.name}`}
						style={{ height: ITEM_HEIGHT }}
						className={`flex px-1 rounded py-2 flex-row items-center cursor-pointer hover:bg-primary-100 ${
							selectedOptions.includes(option.id)
								? "bg-primary-50"
								: ""
						}`}
						onClick={() => handleOptionClick(option.id)}
					>
						<div className="flex flex-1">{option.name}</div>
						{selectedOptions.includes(option.id) && (
							<i className="ri-check-line"></i>
						)}
					</div>
				))}
			</div>
			<input type="hidden" value={selectedOptions.join(",")} />
		</div>
	);
};

// Define prop types for the MultiSelect component
const OptionsProps = {
	id: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
	name: PropTypes.string,
};

MultiLineSelect.propTypes = {
	options: PropTypes.arrayOf(OptionsProps).isRequired,
	onChange: PropTypes.func.isRequired,
	multiSelect: PropTypes.bool,
	lines: PropTypes.number,
	showFilter: PropTypes.bool,
	defaultValue: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
};

// Set default prop values
MultiLineSelect.defaultProps = {
	multiSelect: false,
	lines: 5,
	showFilter: false,
	defaultValue: null,
};

export default MultiLineSelect;
