import { withSuspense } from "hoc";
import TaskTemplateTable from "./pages/TaskTemplates/parts/TaskTemplatesTable";
import DocumentationTable from "./pages/Documentations/parts/DocumentationsTable";
const TaskTemplatesPage = ({ taskGroupId, permissions }) => {
	return (
		<div className="grid grid-cols-1 gap-2">
			{permissions.TaskTemplates.View && (
				<TaskTemplateTable
					permissions={permissions.TaskTemplates}
					taskGroupId={taskGroupId}
				/>
			)}

			{permissions.Documentations.View && (
				<DocumentationTable
					permissions={permissions.Documentations}
					taskGroupId={taskGroupId}
				/>
			)}
		</div>
	);
};
export default withSuspense(TaskTemplatesPage);
