import Swal from "sweetalert2";
import { useTranslations } from "hooks";
import toast from "react-hot-toast";

const UserStatus = ({ props }) => {
	const { translate } = useTranslations();

	const changeUserStatusConfirmation = (id, status = false) => {
		const statusName = status
			? translate("activate")
			: translate("deactivate");
		Swal.fire({
			title: translate("areYouSure"),
			text: translate(
				"youAreAboutTo",
				false,
				statusName,
				props?.data?.name
			),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: translate("yesActionIt", false, statusName),
			cancelButtonText: translate("cancel"),
			confirmButtonColor: "#EF4444",
			cancelButtonColor: "#b5bec9",
		}).then((result) => {
			if (result.isConfirmed) changeUserStatus(id, status);
		});
	};

	const changeUserStatus = async (id, status = false) => {
		const successMessage = status
			? translate("userHasBeenActivatedSuccessfully")
			: translate("userHasBeenDeactivatedSuccessfully");
		await props.service.changeStatus(id, status).then((res) => {
			if (res.succeeded) {
				props?.requestReload();
				toast(successMessage, {
					duration: 2000,
					icon: "✅",
				});
			} else {
				toast(res.message || translate("somethingWentWrong"), {
					duration: 2000,
					icon: "❗️",
				});
			}
		});
	};

	return {
		changeUserStatusConfirmation,
	};
};
export default UserStatus;
