import Card from 'components/ui/Card';
import QRCode from 'react-qr-code';

import tenant from 'config/tenant';
import configMap from 'configmap.json';
import { useTranslations } from 'hooks';

const PorterConfig = () => {
	const envConfig = {
		...configMap,
		tenant: tenant.id,
	};
	const { translate } = useTranslations();

	return (
		<Card className='mt-2' collapsible header={'porterConfig'} defaultOpen={true}>
			<div className='text-lg'>{translate('stepsToConfigurePorterForYourEnvironment')}:</div>
			<ul className='mt-4 pl-8 list-disc'>
				<li>{translate('openPorterAppPressTheEnvFooterInLoginScreen')}</li>
				<li>{translate('pressConfigureDifferentEnvironment')}</li>
				<li>{translate('scanTheBellowQRCode')}</li>
			</ul>
			<div className='p-8'>
				<QRCode size={256} value={JSON.stringify(envConfig)} />
			</div>
		</Card>
	);
};

export default PorterConfig;
