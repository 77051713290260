import Component from "./component";
import routes from "./routes";
import portals from "./portals";

const module = {
	Component,
	routes,
	portals,
};

export default module;
