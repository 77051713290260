import RuleService from "../../../services";
const TYPE_ID = 3;

class Service extends RuleService {
	constructor() {
		super(TYPE_ID);
	}

	async mapData(data) {
		const {
			id,
			description,
			enabled,
			name,
			type,
			"groupingTaskRule.aroundMinutes": aroundMinutes,
			"groupingTaskRule.maxTaskGroupedCount": maxTaskGroupedCount,
			"groupingTaskRule.regroupWithTaskInTransit":
				regroupWithTaskInTransit,
			"groupingTaskRule.roomTypeIdsArray": roomTypeIdsArray,
			"groupingTaskRule.taskGroupId": taskGroupId,
			"groupingTaskRule.taskTypeIdsArray": taskTypeIdsArray,
			"groupingTaskRule.teamIdsArray": teamIdsArray,
			...rest
		} = data;

		let departments = [];
		if (!rest.departments) {
			departments = super.destructObjectSubmit(rest, "departments");

			departments.forEach((department) => {
				let idsArr = [];

				const departmentToIds = super.destructObjectSubmit(
					department,
					"departmentToIds"
				);
				departmentToIds.forEach((departmentToId) => {
					idsArr.push(super.parseValue(departmentToId?.id));
				});
				department["departmentToIds"] = idsArr;
			});
		} else {
			rest.departments.forEach((department) => {
				let idsArr = [];

				department?.departmentsTo?.forEach((departmentTo) => {
					idsArr.push(super.parseValue(departmentTo?.id));
				});

				departments.push({
					departmentId: super.parseValue(department?.department?.id),
					departmentToIds: idsArr,
				});
			});
		}

		const dataToRet = {
			name: super.parseValue(name),
			description: super.parseValue(description),
			enabled: super.parseValue(enabled),
			type: super.parseValue(type),
			groupingTaskRule: {
				aroundMinutes: super.parseValue(aroundMinutes),
				maxTaskGroupedCount: super.parseValue(maxTaskGroupedCount),
				regroupWithTaskInTransit: super.parseValue(
					regroupWithTaskInTransit
				),
				roomTypeIds: super.prepareArrayPayload(roomTypeIdsArray),
				taskGroupId: super.parseValue(taskGroupId),
				taskTypeIds: super.prepareArrayPayload(taskTypeIdsArray),
				teamIds: super.prepareArrayPayload(teamIdsArray),
				departments,
			},
		};

		return dataToRet;
	}
}
export default Service;
