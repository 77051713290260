import { withDashboardLayout } from "../../hoc";
import { Outlet } from "react-router-dom";

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: "reports",
	pages: [],
};

export default withDashboardLayout(CoursesModule, config);
