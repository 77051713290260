import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Scanner } from '@yudiel/react-qr-scanner';
import { Button } from 'components/ui/Input';

const formats = [
	'aztec',
	'code_128',
	'code_39',
	'code_93',
	'codabar',
	'databar',
	'databar_expanded',
	'data_matrix',
	'dx_film_edge',
	'ean_13',
	'ean_8',
	'itf',
	'maxi_code',
	'micro_qr_code',
	'pdf417',
	'qr_code',
	'rm_qr_code',
	'upc_a',
	'upc_e',
	'linear_codes',
	'matrix_codes',
	'unknown',
];

const QrScanner = forwardRef((props, ref) => {
	const [show, setShow] = useState(false);
	const [scanned, setScanned] = useState(null);

	const dataCallback = useRef(null);

	const closeAndReset = () => {
		console.log('Closing');
		setShow(false);
		setScanned(null);
		dataCallback.current = null;
	};

	useImperativeHandle(ref, () => ({
		callAction: async (action, data, callback) => {
			console.log('shown qr');
			switch (action) {
				case 'open':
					console.log('SHOWING');
					setShow(true);
					dataCallback.current = callback;

					break;
				default:
					throw Error(`No action named ${action} for portal ${props.id}`);
			}
		},
	}));

	return (
		<>
			{show &&
				createPortal(
					<div
						onClick={() => closeAndReset()}
						className='absolute z-50 top-0 bottom-0 right-0 left-0 bg-slate-800/50 flex justify-center items-center'
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className={`relative flex flex-col h-fit w-3/4 sm:w-2/3 md:w-3/5 lg:w-1/3 rounded-lg bg-white p-4 ${
								scanned ? 'py-12' : 'aspect-square'
							}`}
						>
							{!scanned ? (
								<Scanner
									formats={formats}
									onScan={(result) => {
										if (result.length > 0) {
											setScanned(result[0].rawValue);
										}
									}}
								/>
							) : (
								<>
									<div className='text-xl text-center'>{scanned}</div>
									<Button
										onClick={() => {
											dataCallback.current(scanned);
											closeAndReset();
										}}
										className='mt-4'
									>
										Done
									</Button>
								</>
							)}
						</div>
					</div>,
					document.body,
				)}
		</>
	);
});

export default QrScanner;
