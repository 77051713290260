export const parseDate = (dt) => {
	const date = new Date(dt);

	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
};

export const parseDateForInput = (dt) => {
	const date = new Date(dt);

	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
	const year = date.getFullYear();

	return `${year}-${month}-${day}`;
};

export const getToday = () => {
	const day = new Date();
	return `${day.getFullYear()}-${String(day.getMonth() + 1).padStart(
		2,
		"0"
	)}-${String(day.getDate()).padStart(2, "0")}`;
};

export const getCurrentTime = () => {
	const day = new Date();
	return `${String(day.getHours()).padStart(2, "0")}:${String(
		day.getMinutes()
	).padStart(2, "0")}:${String(day.getSeconds()).padStart(2, "0")}`;
};
