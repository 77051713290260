import AbstractService from 'services/Service';

const endpoint = 'tenant/custom-fields';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	mapData(data) {
		const { id, taskGroupIds, InputType, displayInTaskListScreen, ...rest } = data;
		return {
			...rest,
			taskGroupIds: taskGroupIds,
			inputType: InputType ? parseInt(InputType) : null,
			displayInTaskListScreen: displayInTaskListScreen === 'true',
		};
	}
}
export default Service;
