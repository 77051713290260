import React from "react";
import ContentLoader from "react-content-loader";

const ITEMS = 6;

const MatrixLoading = () => (
	<ContentLoader
		speed={2}
		width={56 * ITEMS}
		height={56 * ITEMS}
		backgroundColor="#e2e8f0"
		foregroundColor="#ecebeb"
	>
		{Array.from({ length: ITEMS }, (_, index_x) =>
			Array.from({ length: ITEMS }, (_, index_y) => {
				if (index_x === 0 && index_y === 0) return <></>;
				return (
					<rect
						key={`loading-${index_y}`}
						x={
							48 * index_x +
							8 * index_x +
							(index_y === 0 ? 16 : 0)
						}
						y={
							48 * index_y +
							8 * index_y +
							(index_x === 0 ? 16 : 0)
						}
						rx="5"
						ry="5"
						width={index_y === 0 ? "16" : "48"}
						height={index_x === 0 ? "16" : "48"}
					/>
				);
			})
		)}
	</ContentLoader>
);

export default MatrixLoading;
