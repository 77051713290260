import React from "react";
import DashboardLayout from "../layouts/Dashboard";

const withDashboardLayout = (Component, config) => {
	return (props) => {
		return (
			<DashboardLayout {...config}>
				<Component {...props} />
			</DashboardLayout>
		);
	};
};

export default withDashboardLayout;
