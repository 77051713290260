import AbstractService from "services/Service";

const endpoint = "users/departments";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	mapData(data) {
		const { id, ...rest } = data;
		return {
			...rest,
			addresses: JSON.parse(data.addresses),
		};
	}
}
export default Service;
