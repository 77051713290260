import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

const ENABLE_ARROW_CONTROLS = true;

const MatrixEdit = forwardRef(({ values, items, isView = false }, ref) => {
	const [matrix, setMatrix] = useState(values);
	const [focusedCell, setFocusedCell] = useState({
		rowIndex: null,
		colIndex: null,
	});
	const tableRef = useRef(null);
	const inputRefs = useRef({});

	useImperativeHandle(ref, () => ({
		getValues: () => matrix,
	}));

	const handleInputChange = (item, otherItem, value) => {
		const newMatrix = { ...matrix };
		console.log('NEW VALUE INPUT', value);
		value = parseInt(value, 10);
		console.log('AFTER PARSE', value);
		newMatrix[item][otherItem] = value;
		newMatrix[otherItem][item] = value;
		setMatrix(newMatrix);
	};

	const trackFocus = (rowIndex, colIndex) => {
		if (!ENABLE_ARROW_CONTROLS) return;
		setFocusedCell({ rowIndex, colIndex });
	};

	const handleKeyDown = (e) => {
		if (!ENABLE_ARROW_CONTROLS) return;

		if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) >= 0) {
			e.preventDefault();
		}

		const { rowIndex, colIndex } = focusedCell;

		let newRow = rowIndex;
		let newCol = colIndex;

		let rowChange = 0;
		let colChange = 0;

		switch (e.key) {
			case 'ArrowUp':
				rowChange = -1;
				break;
			case 'ArrowDown':
				rowChange = 1;
				break;
			case 'ArrowLeft':
				colChange = -1;
				break;
			case 'ArrowRight':
				colChange = 1;
				break;
			default:
				return;
		}

		newRow += rowChange;
		newCol += colChange;

		if (newRow === newCol) {
			newRow += rowChange;
			newCol += colChange;
		}

		if (newRow < 0) newRow = 0;
		if (newRow > items.length - 1) newRow = items.length - 1;
		if (newCol < 0) newCol = 0;
		if (newCol > items.length - 1) newCol = items.length - 1;

		setFocusedCell({ rowIndex: newRow, colIndex: newCol });
	};

	useEffect(() => {
		if (!ENABLE_ARROW_CONTROLS) return;

		const inputElement = document.querySelector(`#input-${focusedCell.rowIndex}-${focusedCell.colIndex}`);

		if (inputElement) {
			inputElement.focus();
		}
	}, [focusedCell]);

	return (
		<div
			className='resize overflow-auto border border-slate-200/50'
			style={{
				maxHeight: 'calc(100vh - 250px)',
				height: 'calc(100vh - 250px)',
				width: '100%',
			}}
			tabIndex='0'
			onKeyDown={handleKeyDown}
			ref={tableRef}
		>
			<table className='border-collapse'>
				<thead>
					<tr className=''>
						<th className='sticky top-0 px-4 py-2 bg-white'></th>
						{items.map((item) => (
							<th
								key={item.id}
								className='sticky top-0 font-normal bg-white whitespace-pre w-content h-content'
							>
								<div className='w-full h-full flex justify-center'>
									<div className='transform z-50 py-2 [writing-mode:vertical-lr] rotate-[180deg]'>
										{item.name}
									</div>
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{items.map((item, rowIndex) => (
						<tr key={item.id}>
							<th className='sticky whitespace-pre w-content left-0 bg-white px-4 py-2 font-normal'>
								{item.name}
							</th>
							{items.map((otherItem, colIndex) => (
								<td
									key={otherItem.id}
									className={`${
										item.id === otherItem.id ? 'bg-gray-200' : ''
									} border w-16 h-16 px-0 py-0`}
								>
									{item.id === otherItem.id ? (
										<input
											ref={(r) => (inputRefs.current[`${rowIndex}:${colIndex}`] = r)}
											id={`input-${rowIndex}-${colIndex}`}
											type='number'
											value=''
											disabled
											readOnly={isView}
											className='bg-gray-200 cursor-not-allowed w-16 h-16 text-center'
										/>
									) : (
										<input
											autoFocus={
												focusedCell.rowIndex === rowIndex && focusedCell.colIndex === colIndex
											}
											ref={(r) => (inputRefs.current[`${rowIndex}:${colIndex}`] = r)}
											onFocus={() => trackFocus(rowIndex, colIndex)}
											id={`input-${rowIndex}-${colIndex}`}
											type='number'
											defaultValue={0}
											min={0}
											readOnly={isView}
											value={matrix[item.id][otherItem.id]}
											onChange={(e) =>
												handleInputChange(item.id, otherItem.id, parseInt(e.target.value, 10))
											}
											className='w-16 h-16 text-center'
										/>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});

export default MatrixEdit;
