const useForm = () => {
	const getData = (form, assert = true) => {
		if (form && form.checkValidity() && assert) {
			const formData = new FormData(form);
			const formDataObject = {};

			for (let [key, value] of formData.entries()) {
				if (form.elements[key] && form.elements[key].multiple) {
					formDataObject[key] = formData.getAll(key);
				} else {
					formDataObject[key] = value;
				}
			}

			return formDataObject;
		} else if (form) {
			form.reportValidity();
			return false;
		}
	};
	return {
		getData,
	};
};
export default useForm;
