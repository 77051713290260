const Button = ({
	children,
	className = '',
	onClick = () => {},
	small = false,
	disabled = false,
	type = 'button',
	color = 'primary',
	iconOnly = false,
	isLoading = false,
}) => {
	const colorClasses = {
		primary: {
			base: 'bg-primary-500',
			hover: 'hover:bg-primary-400',
			focus: 'focus:ring-primary-300',
			text: 'text-white',
		},
		success: {
			base: 'bg-green-500',
			hover: 'hover:bg-green-400',
			focus: 'focus:ring-green-300',
			text: 'text-white',
		},
		danger: {
			base: 'bg-red-500',
			hover: 'hover:bg-red-400',
			focus: 'focus:ring-red-300',
			text: 'text-white',
		},
		warning: {
			base: 'bg-yellow-500',
			hover: 'hover:bg-yellow-400',
			focus: 'focus:ring-yellow-300',
			text: 'text-white',
		},
		secondary: {
			base: 'bg-gray-500',
			hover: 'hover:bg-gray-400',
			focus: 'focus:ring-gray-300',
			text: 'text-white',
		},
		transparent: {
			base: 'bg-transparent border-2',
			hover: 'hover:bg-gray-100 hover:text-gray-800',
			focus: 'focus:ring-gray-300',
			text: 'text-gray-700',
		},
	};

	if (!colorClasses[color]) {
		color = 'primary';
	}

	const classes = colorClasses[color];

	return (
		<button
			type={type}
			onClick={onClick}
			className={`${classes.text}  ${
				disabled || isLoading
					? `${classes.base} cursor-not-allowed opacity-50`
					: `${classes.base} ${classes.hover}`
			} focus:ring-4   ${classes.focus} 
			${iconOnly ? 'pt-1 pb-0.5 px-2 text-2xl' : small ? 'text-xs px-3 py-2' : 'text-sm px-5 py-2.5'}
			 font-medium rounded-lg focus:outline-none ${className}`}
			disabled={disabled || isLoading}
		>
			{isLoading ? <i className='ri-loader-4-line animate-spin text-lg '></i> : children}
		</button>
	);
};

export default Button;
