import { useTranslations } from 'hooks';
import { forwardRef, useEffect, useRef, useState } from 'react';
import ViewInput from './internal/ViewInput';

const TagsInput = forwardRef(
	(
		{
			isView = false,
			name,
			required = false,
			minLength = 3,
			placeholder = 'typeByDividingWithComma',
			containerClassName = '',
			labelClassName = '',
			...props
		},
		ref,
	) => {
		const { translate } = useTranslations();
		let inputRef = useRef(null);
		if (ref) {
			inputRef = ref;
		}
		const tagsInputRef = useRef(null);
		const ERROR_MESSAGE = translate('atLeastThisCharacters', false, minLength);

		const [tags, setTags] = useState([]);
		const [inputValue, setInputValue] = useState('');
		const [error, setError] = useState('');

		useEffect(() => {
			if (props.defaultValue) {
				setTags(props.defaultValue.split(',').map((tag) => tag.trim()));
			}
		}, [props.defaultValue]);

		const handleKeyDown = (e) => {
			if (e.key === ',' || e.key === 'Enter') {
				e.preventDefault();
				const value = inputValue.trim();
				if (value && !tags.includes(value)) {
					setTags([...tags, value]);
					setInputValue('');
				}
			}
		};

		useEffect(() => {
			if (minLength && tags.join(', ').length > 0 && tags.join(', ').length < minLength) {
				setError(ERROR_MESSAGE);
			} else {
				setError('');
			}
		}, [tags]);

		const handleInputChange = (e) => {
			setInputValue(e.target.value);
		};

		const removeTag = (index) => {
			setTags(tags.filter((_, i) => i !== index));
		};

		return (
			<div
				className={`relative w-full ${containerClassName}`}
				onClick={() => {
					tagsInputRef.current.focus();
				}}
			>
				{!props.hideLabel && props.label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}>
						{translate(props?.label || '', true)}
					</label>
				)}
				<div className='flex flex-row items-center flex-wrap border border-gray-300 rounded-lg p-2'>
					<input
						ref={inputRef}
						type='text'
						className='sr-only'
						required={required}
						name={`${name}`}
						onChange={() => {}}
						minLength={minLength}
						value={tags ? tags.join(', ') : null}
					/>
					{tags.map((tag, index) => (
						<span
							key={index}
							className='bg-blue-100 text-blue-800 text-xs font-medium mr-1 mt-1 pl-2.5 pr-1.5 py-0.5 pb-1 rounded flex items-center'
						>
							{tag}
							<button
								type='button'
								className='ml-1 text-blue-600 hover:text-blue-800'
								onClick={() => removeTag(index)}
							>
								&times;
							</button>
						</span>
					))}
					{props.isView ? (
						<ViewInput value={tags.join(', ') || '-'} />
					) : (
						<input
							type='text'
							ref={tagsInputRef}
							{...props}
							value={inputValue}
							placeholder={translate(placeholder, true)}
							className={` border-none mt-1 text-gray-900 text-xs font-medium mr-2 px-2.5 py-0.5 rounded flex-grow focus:ring-gray-100 focus:border-gray-100 ${props.className}`}
							onKeyDown={handleKeyDown}
							onChange={handleInputChange}
						/>
					)}
				</div>
				{error && <p className='text-red-500 text-xs font-medium mt-1'>{error}</p>}
			</div>
		);
	},
);

export default TagsInput;
