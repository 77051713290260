import { TruncatedTextRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class NameColumn extends StringColumn {
	constructor(options = {}) {
		super(options.name || 'name', options);
		this.key = options.key || 'name';
		this.label = options.label || 'name';
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || 'Name';
		this.hidden = options.hidden || false;
		this.truncateLength = options.truncateLength || 32;
		this.render = options.render || ((value) => <TruncatedTextRender value={value} num={this.truncateLength} />);
		this.filter = options.filter || {
			type: 'text',
			key: 'Prefix',
		};
		this.order = options.order || 0;
	}
}
export default NameColumn;
