import PropTypes from "prop-types";

const getColorByType = (type) => {
	switch (type) {
		case "primary":
			return "text-primary-800 bg-primary-50";
		case "error":
			return "text-red-800 bg-red-100";
		case "warning":
			return "text-yellow-800 bg-yellow-100";
		case "info":
			return "text-cyan-800 bg-cyan-50";
		default:
			return "text-primary-800 bg-primary-50";
	}
};

const Alert = ({
	title,
	children,
	className = "",
	hideIcon = false,
	icon = "ri-information-fill",
	type = "primary",
}) => {
	return (
		<div
			className={`flex flex-col justify-center p-4 mb-4 text-sm rounded-lg ${getColorByType(
				type
			)} ${className}`}
			role="alert"
		>
			<div className="flex flex-row mb-2 items-center">
				{!hideIcon && <i className={`${icon} text-lg mr-2`}></i>}
				<span className="font-bold">{title}</span>
			</div>
			<div>{children}</div>
		</div>
	);
};

Alert.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	className: PropTypes.string,
	hideIcon: PropTypes.bool,
	icon: PropTypes.string,
	type: PropTypes.oneOf(["primary", "error", "warning", "info"]),
};

export default Alert;
