import { IdColumn, NameColumn, SimpleObjectListColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class TaskTemplateModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'taskTemplate';
		this.pluralModelName = 'taskTemplates';
	}
	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(new SimpleObjectListColumn('fields'));
		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}
export default TaskTemplateModel;
