import { NumberColumn } from './baseColumns';

class IdColumn extends NumberColumn {
	constructor(options = {}) {
		super(options.name || 'id', options);
		this.key = options.key || 'id';
		this.label = options.label || 'id';
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || 'id';
		this.hidden = options.hidden || false;
		this.render = options.render || null;
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default IdColumn;
