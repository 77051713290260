import AbstractService from 'services/Service';
import { extractMeta } from 'utils/pagination';
const endpoint = 'taskgroups/{parentId}/types';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
		this.parentId = null;
	}

	setParent(id) {
		this.endpoint = endpoint.replace('{parentId}', id);
		this.parentId = id;
	}

	beforeEach() {
		this.assertIfParentIdIsSet();
		return true;
	}

	assertIfParentIdIsSet() {
		if (this.parentId === null) {
			throw new Error('Parent ID is not set');
		}
	}

	mapData(data) {
		const { id, expectedTaskTime, anticipationTime, advancedRequestTime, enabled, ...rest } = data;
		return {
			...rest,
			expectedTaskTime: expectedTaskTime ? parseInt(expectedTaskTime) : null,
			anticipationTime: anticipationTime ? parseInt(anticipationTime) : null,
			advancedRequestTime: advancedRequestTime ? parseInt(advancedRequestTime) : null,
			enabled: enabled === 'true',
		};
	}

	async getOptionsList(queryString = '') {
		if (this.parentId) {
			return await super.getOptionsList(queryString);
		}
		return {
			data: [],
			meta: extractMeta(
				{
					page: 1,
					pageSize: 10,
					data: [],
					message: '',
					succeeded: true,
				},
				queryString,
			),
		};
	}

	async getMultiParentOptionsList(taskGroupIds = []) {
		const queryString = taskGroupIds?.map((id) => `taskgroupids=${id}`).join('&');
		this.endpoint = 'taskgroups/types';
		this.parentId = -1;
		const res = await super.getOptionsList(queryString + '&Page=1&PageSize=200');
		this.endpoint = endpoint;
		this.parentId = null;
		return res;
	}

	async searchOptionsList(queryString = null, queryParams = [], returnAllData = false) {
		if (this.parentId === null) {
			this.endpoint = 'taskgroups/types';
			this.parentId = -1;
		}

		const response = await super.searchOptionsList(queryString, queryParams, returnAllData);
		this.endpoint = endpoint;
		this.parentId = null;
		return response;
	}
}

export default Service;
