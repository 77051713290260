import api from "services/api";

const endpoint = "languages";
const translationsEndpoint = endpoint + "/%s/translations";

export const getTranslations = async (languageCode) => {
	const translations = await api.get(
		`${translationsEndpoint.replace("%s", languageCode)}`
	);
	return translations?.data;
};

export const getLanguages = async () => {
	const languages = await api.get(`${endpoint}`);
	return languages?.data;
};
