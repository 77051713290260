import React from "react";

const withPaddinglessLayout = (Component) => {
	return (props) => {
		return (
			<div className="-m-4">
				<Component {...props} />
			</div>
		);
	};
};

export default withPaddinglessLayout;
