import { logout } from "./services/auth";
import { loadUserPermissions } from "./services/settings";
import { PORTALS } from "./portals";
import { localStorageKeys } from "config";

const getPermissions = async () => {
	let permissions = localStorage.getItem(
		localStorageKeys.USER_PERMISSIONS_STORAGE_KEY
	);
	if (permissions && JSON.parse(permissions)) {
		return JSON.parse(permissions);
	} else {
		try {
			permissions = await loadUserPermissions();
			if (permissions.data)
				localStorage.setItem(
					localStorageKeys.USER_PERMISSIONS_STORAGE_KEY,
					JSON.stringify(permissions.data)
				);
			return permissions.data;
		} catch (error) {
			console.error("Error:", error);
		}
	}
};

const utils = {
	logout,
	permissions: async () => getPermissions(),
	openOnboarding: ({ callAction, callback }) =>
		callAction(PORTALS.onboarding, "open", {}, callback),
	openUserSettings: ({ callAction, data = {}, callback }) =>
		callAction(PORTALS.userSettings, "open", data, callback),
};

export default utils;
