import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";

import { PortalManager } from "contexts/PortalManager";
import { TranslationProvider } from "contexts/TranslationManager";

import { router, publicRouter, portals } from "./router";
import tokenService from "services/token/token.service";

const APP_STATES = {
	init: 0,
	logged_in: 1,
	not_logged_in: -1,
};

function App() {
	const [appState, setAppState] = useState(APP_STATES.init);

	const checkAuth = () => {
		const { token, isOneTimeLogin } = tokenService.getUserObject() || {
			token: null,
			isOneTimeLogin: false,
		};

		if (token !== null && !isOneTimeLogin) {
			setAppState(APP_STATES.logged_in);
		} else {
			setAppState(APP_STATES.not_logged_in);
		}
	};

	useEffect(() => {
		checkAuth();

		window.addEventListener("login", () => {
			checkAuth();
		});

		return () => {
			window.removeEventListener("login", () => {});
		};
	}, []);

	return (
		<TranslationProvider>
			<PortalManager portals={portals}>
				<React.Suspense
					fallback={() => (
						<div
							className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
							role="status"
						>
							<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
								Loading...
							</span>
						</div>
					)}
				>
					{appState !== 0 ? (
						<RouterProvider
							router={appState === 1 ? router : publicRouter}
						/>
					) : (
						<div className="flex justify-center items-center h-screen w-screen">
							<div
								className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-orange-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
								role="status"
							>
								<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
									Loading...
								</span>
							</div>
						</div>
					)}
				</React.Suspense>
			</PortalManager>
		</TranslationProvider>
	);
}

export default App;
