import { localStorageKeys } from 'config';
import TaskService from 'modules/tasks/pages/Tasks/pages/Tasks/services';
import ModulesService from '../services/modules';

const FIELDS = [
	{
		dbId: 1,
		description: 'patientTransportationDescriptionText',
	},
	{
		dbId: 2,
		description: 'cleaningDescriptionText',
	},
	{
		dbId: 3,
		description: 'logisticDescriptionText',
	},
	{
		dbId: 4,
		description: 'assetDescriptionText',
	},
	{
		dbId: 5,
		description: 'assetDescriptionText',
	},
];

const TaskModuleUtils = () => {
	const modulesService = new ModulesService();
	const taskService = new TaskService();

	const getOptionsList = async () => {
		let taskModules = localStorage.getItem(localStorageKeys.TASK_MODULE_OPTIONS_STORAGE_KEY);
		if (taskModules && JSON.parse(taskModules)) {
			return JSON.parse(taskModules);
		} else {
			const res = await modulesService.getOptionsList();
			localStorage.setItem(localStorageKeys.TASK_MODULE_OPTIONS_STORAGE_KEY, JSON.stringify(res.data));
			return res.data;
		}
	};

	const getModules = async () => {
		let taskModules = localStorage.getItem(localStorageKeys.TASK_MODULE_STORAGE_KEY);
		if (taskModules && JSON.parse(taskModules)) {
			return JSON.parse(taskModules);
		} else {
			const res = await modulesService.getAll();
			res.data.forEach((item) => {
				item.description = FIELDS.find((d) => d.dbId === item.id).description || '';
			});
			localStorage.setItem(localStorageKeys.TASK_MODULE_STORAGE_KEY, JSON.stringify(res.data));
			return res.data;
		}
	};

	const getTaskActions = async () => {
		let taskActions = localStorage.getItem(localStorageKeys.TASK_ACTIONS_STORAGE_KEY);
		if (taskActions && JSON.parse(taskActions)) {
			return JSON.parse(taskActions);
		} else {
			const res = await taskService.getActions();
			localStorage.setItem(localStorageKeys.TASK_ACTIONS_STORAGE_KEY, JSON.stringify(res));
			return res;
		}
	};

	const getTaskStatuses = async () => {
		let taskStatuses = localStorage.getItem(localStorageKeys.TASK_STATUSES_STORAGE_KEY);
		if (taskStatuses && JSON.parse(taskStatuses)) {
			return JSON.parse(taskStatuses);
		} else {
			const res = await taskService.getTaskStatuses();
			localStorage.setItem(localStorageKeys.TASK_STATUSES_STORAGE_KEY, JSON.stringify(res));
			return res;
		}
	};

	return {
		getOptionsList,
		getModules,
		getTaskActions,
		getTaskStatuses,
	};
};
const utils = TaskModuleUtils();

export default utils;
