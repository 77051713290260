import ViewInput from 'components/ui/Input/internal/ViewInput';
import { BadgedListRender } from 'core/renders';
import { useTranslations } from 'hooks';
import { useEffect, useRef, useState } from 'react';

function OptionsInput({
	name,
	required,
	defaultValue,
	onChange = () => {},
	containerClassName,
	viewBadgeClassName = '',
	isView = false,
}) {
	const [options, setOptions] = useState([]);
	const valueRef = useRef(null);
	const nameRef = useRef(null);
	const hasMounted = useRef(false);

	const [editMode, setEditMode] = useState(false);
	const [editIndex, setEditIndex] = useState(null);

	const { translate } = useTranslations();

	useEffect(() => {
		if (!hasMounted.current && defaultValue) {
			setOptions(defaultValue);

			hasMounted.current = true;
		}
	}, [defaultValue]);

	const submitOption = () => {
		const value = valueRef.current.value;
		const name = nameRef.current.value;

		if (!value || !name || value > 99 || value < 0) return;

		const opt = { id: value, name: name };

		if (editMode) {
			const newOptions = [...options];
			newOptions[editIndex] = opt;
			setOptions(newOptions);
			onChange(newOptions);
			setEditMode(false);
			setEditIndex(false);
			nameRef.current.value = '';
			valueRef.current.value = '';
		} else {
			const l_options = [...options, opt];
			setOptions(l_options);
			nameRef.current.value = '';
			valueRef.current.value = '';
			onChange(l_options);
		}
	};

	const cancelEdit = () => {
		setEditMode(false);
		setEditIndex(null);
		nameRef.current.value = '';
		valueRef.current.value = '';
	};

	const editOption = (index, option) => {
		nameRef.current.value = option.name;
		valueRef.current.value = option.id;
		setEditMode(true);
		setEditIndex(index);
	};

	const deleteOption = (index) => {
		const newOptions = [...options];
		newOptions.splice(index, 1);
		setOptions(newOptions);
		onChange(newOptions);
	};

	if (isView) {
		return (
			<ViewInput
				value={
					<BadgedListRender
						items={options.map((option) => ({ ...option, name: option.name + ' (' + option.id + ')' }))}
						tooltipContainerId='aside-tooltip'
						itemLengthToTruncate={32}
						displayMax={3}
						itemClassName={`w-80 ${viewBadgeClassName}`}
					/>
				}
			/>
		);
	}

	return (
		<div
			className={`relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 cursor-pointer ${containerClassName}`}
		>
			<input
				type='text'
				name={name}
				required={required}
				className='sr-only'
				value={options?.length > 0 ? JSON.stringify(options) : ''}
				onChange={() => {}}
			/>
			{options.map((option, index) => (
				<div
					key={index}
					title={translate('editThisText')}
					className='flex justify-between items-center mb-2 border-b border-gray-200 pb-1 px-1 opacity-60 hover:opacity-100'
				>
					<span
						className='cursor-pointer'
						onClick={() => {
							editOption(index, option);
						}}
					>
						({option.id}) <b className='ml-1'>{option.name}</b>
					</span>
					<div className='flex flex-row'>
						<div
							className='opacity-70 hover:opacity-100'
							onClick={() => {
								editOption(index, option);
							}}
						>
							<i className={`ri-pencil-line text-md mr-2 text-primary ${editMode ? 'hidden' : ''}`}></i>
						</div>

						<div
							className='opacity-70 hover:opacity-100'
							onClick={(e) => {
								e.stopPropagation();
								deleteOption(index);
							}}
						>
							<i className='ri-delete-bin-line text-md '></i>
						</div>
					</div>
				</div>
			))}

			<div className='relative'>
				<input
					type='number'
					ref={valueRef}
					placeholder={translate('value')}
					min={1}
					max={99}
					className='mr-2 pt-2 rounded-md border border-gray-300 w-2/5 '
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							nameRef.current.focus();
						}
					}}
				/>
				<input
					type='text'
					ref={nameRef}
					placeholder={translate('name')}
					className='mr-2 pt-2 rounded-md border border-gray-300 w-1/2'
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							submitOption();
						}
					}}
				/>
				<button
					title={editMode ? translate('editThisOption') : translate('addOption')}
					type='button'
					onClick={() => {
						submitOption();
					}}
					className='absolute inset-y-0 right-0 pr-0 text-primary flex items-center'
				>
					{editMode ? (
						<div className=''>
							<i
								onClick={(e) => {
									e.stopPropagation();
									cancelEdit();
								}}
								className=' ri-close-circle-line text-xl opacity-40 hover:opacity-100'
							></i>
							<br />
							<i className=' text-green-800 ri-checkbox-circle-line text-xl opacity-40 hover:opacity-100'></i>
						</div>
					) : (
						<i className=' ri-add-circle-line text-lg opacity-40 hover:opacity-100'></i>
					)}
				</button>
			</div>
		</div>
	);
}

export default OptionsInput;
