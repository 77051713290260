import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
	createRef,
} from "react";
import { useAside } from "hooks";

const DuplicateTaskTemplateButton = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const { asideBuilder } = useAside();

	const _duplicateTaskTemplate = () => {
		setIsLoading(true);
		props.service.copy(props.data.id).then((res) => {
			if (res.succeeded) {
				asideBuilder.setOpen(false);
				asideBuilder.reset();
				props.requestReload();
				setIsLoading(false);
			}
		});
	};
	return (
		<button
			disabled={isLoading}
			onClick={() => _duplicateTaskTemplate()}
			className={` w-10 h-8 flex items-center justify-center  rounded-lg border border-black hover:bg-black hover:bg-opacity-5 h-39 w-41 top-919 left-90 focus:ring-2 text-lg focus:ring-gray-400 active:bg-gray-400 ${
				isLoading ? "opacity-3 cursor-progress" : "opacity-100"
			} `}
		>
			{isLoading ? (
				<i className="ri-loader-4-line animate-spin"></i>
			) : (
				<i className="ri-file-copy-line"></i>
			)}
		</button>
	);
};

export default DuplicateTaskTemplateButton;
