const AddressLocationRender = ({ address }) => {
	const renderAddressTree = (address, level = 0) => {
		if (!address) return null;

		return (
			<div key={address.name} style={{ marginLeft: `${level * 5}px` }}>
				<div style={{ marginBottom: '8px' }}>
					<b> - </b>
					{address.name}
				</div>
				{renderAddressTree(address.child, level + 1)}
			</div>
		);
	};

	return <div className='font-medium bg-gray-50 p-4 rounded-xl'>{renderAddressTree(address)}</div>;
};

export default AddressLocationRender;
