import { useEffect, useMemo } from "react";
import { useTranslations } from "hooks";

const validationCases = [
	{
		label: "uppercase",
		regex: /[A-Z]/,
		message: "atLeastOneUppercaseLetter",
	},
	{
		label: "lowercase",
		regex: /[a-z]/,
		message: "atLeastOneLowercaseLetter",
	},
	{
		label: "number",
		regex: /[0-9]/,
		message: "atLeastOneNumber",
	},
	{
		label: "special",
		regex: /[^A-Za-z0-9]/,
		message: "atLeastOneSpecialCharacter",
	},
	{
		label: "length",
		regex: /.{7,}/,
		message: "atLeastEightCharacters",
	},
];

const PasswordValidator = ({
	password,
	isOpen = false,
	onChange = () => {},
}) => {
	const { translate } = useTranslations();

	const validations = useMemo(() => {
		return validationCases.map(({ label, regex, message }) => {
			return {
				label,
				valid: regex.test(password),
				message,
			};
		});
	}, [password]);

	useEffect(() => {
		onChange(validations.every(({ valid }) => valid));
	}, [validations, onChange]);

	if (!isOpen) return null;

	return (
		<div className="border  border-gray-300 bg-gray-100  p-4 rounded-3xl  w-72 h-38 shadow-xl">
			<div className="flex flex-col items-left w-full my-1 ">
				<span className="text-slate-600 pb-1">
					{translate("passwordMustContain")}:
				</span>
				{validations.map(({ label, valid, message }) => (
					<div key={label} className="flex items-center  mt-2">
						<div
							className={`w-3 h-3 rounded-full  ${
								valid ? "bg-green-500" : "bg-red-500"
							}`}
						></div>
						<div
							className={`text-sm pl-5 ${
								valid ? "text-green-900" : "text-red-800"
							} `}
						>
							{translate(message)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
export default PasswordValidator;
