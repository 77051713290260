import { EnumLabelRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class EnumLabelColumn extends StringColumn {
	constructor(name, options = {}) {
		super(name, options);
		this.enumKey = options.enumKey || name;
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.render = (value) => <EnumLabelRender value={value} enumKey={this.enumKey} />;
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default EnumLabelColumn;
