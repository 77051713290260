import { useTranslations } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { TruncateBeginningString } from 'utils';

const SingleSelectInput = forwardRef(
	(
		{
			disabled = false,
			sideButtonIcon = 'ri-road-map-line',
			onSideButtonClick = null,
			containerClassName = '',
			options = [],
			onOptionSelect = () => {},
			onSearchInput = null,
			onOptionSelectAction = null,
			onClear = () => {},
			debounceTime = 300,
			required = false,
			name = null,
			onChange = () => {},
			defaultValue = null,
			defaultSelected = null,
			placeholder = 'search',
			defaultLabel = null,
			displayTooltip = true,
			onEndReached = null,
			truncateLength = 42,
		},
		ref,
	) => {
		const { translate } = useTranslations();

		const inputRef = useRef(null);
		const selectInputRef = useRef(null);
		const hasMounted = useRef(false);
		const observer = useRef();
		const lastOptionElementRef = useRef();
		const displayOptions = useRef(options);
		const optionsDivRef = useRef(null);

		const [searchTerm, setSearchTerm] = useState('');
		const [selectedOption, setSelectedOption] = useState(null);
		const [isFocused, setIsFocused] = useState(false);
		const [isMouseOverOptions, setIsMouseOverOptions] = useState(false);
		const [isLoading, setIsLoading] = useState(true);
		const [inputInvalid, setInputInvalid] = useState(false);

		const checkValidity = () => {
			setInputInvalid(selectInputRef.current && selectInputRef.current.validity.valid === false);
		};

		useImperativeHandle(ref, () => ({
			setActiveSelectedOption: (option) => {
				setSelectedOption(option);
			},
		}));

		const search = (term) => {
			if (options.length > 0) {
				filterOptions();
			} else if (term.length > 0 && !isLoading) {
				setIsLoading(true);
			}
			if (onSearchInput) {
				onSearchInput(term)
					.then((resp) => {
						displayOptions.current = resp;
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		};

		useDebounce(searchTerm, debounceTime, search);

		const filterOptions = () => {
			const filteredOptions = options.filter((option) =>
				option.label.toLowerCase().includes(searchTerm.toLowerCase()),
			);
			displayOptions.current = filteredOptions;
		};

		/************************  HANDLERS ******************************/

		const handleSelectOption = (option) => {
			if (onOptionSelectAction) {
				setIsLoading(true);
				handleSetSelectOption(option);
				onOptionSelectAction(option).then((newOption) => {
					setIsLoading(false);
					setSelectedOption(newOption);
				});
			} else {
				handleSetSelectOption(option);
			}
		};

		const handleSetSelectOption = (option) => {
			setSelectedOption(option);
			setSearchTerm('');
			setIsFocused(false);
			onOptionSelect(option);
			onChange(selectInputRef.current.event);
		};

		const handleRemoveSelectedOption = () => {
			setSearchTerm('');
			setSelectedOption(null);
			onClear();
		};

		/************************* EFFECTS *******************************/

		useEffect(() => {
			if (!hasMounted.current && options && (defaultValue || defaultSelected)) {
				const val = defaultValue || defaultSelected;

				if (typeof val === 'object' && val.label && val.id) {
					setSelectedOption(val);
					hasMounted.current = true;
					return;
				}

				const defaultOption = options.find((option) => option.id === val);
				if (defaultOption) setSelectedOption(defaultOption);
				else {
					setSelectedOption({
						id: val,
						label: defaultLabel || val,
					});
				}
				hasMounted.current = true;
			}
		}, [defaultValue, defaultSelected, options, defaultLabel]);

		useEffect(() => {
			checkValidity();
		}, [selectedOption]);

		useEffect(() => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver(async (entries) => {
				if (entries[0].isIntersecting && !isLoading) {
					if (onEndReached) {
						setIsLoading(true);
						onEndReached()
							.then((resp) => {
								displayOptions.current = [...displayOptions.current, ...resp];
							})
							.finally(() => {
								setIsLoading(false);
							});
					}
				}
			});
			if (lastOptionElementRef.current) {
				observer.current.observe(lastOptionElementRef.current);
			}
		}, [isFocused, onEndReached]);

		useEffect(() => {
			if (isFocused && optionsDivRef.current) {
				optionsDivRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
			}
		}, [isFocused]);

		return (
			<div className='w-full'>
				<div
					className={`relative ${
						disabled ? 'opacity-50 pointer-events-none cursor-not-allowed' : 'opacity-100'
					}
					border rounded-lg my-1 bg-gray-50 ${containerClassName}
					${isFocused ? 'border-blue-500 border-2' : inputInvalid ? 'border-red-500' : 'border-gray-300'}
					`}
					data-tooltip-content={
						displayTooltip && selectedOption?.label.length > truncateLength ? selectedOption?.label : ''
					}
					data-tooltip-id='search-select-input-tooltip'
				>
					<select
						ref={selectInputRef}
						className='sr-only pt-10 pl-10'
						required={required}
						name={name}
						onChange={() => {}}
					>
						<option disabled selected></option>
						{selectedOption && (
							<option value={selectedOption.id} selected>
								{selectedOption.label}
							</option>
						)}
					</select>
					<div className='flex flex-row items-center justify-center'>
						<input
							disabled={disabled}
							ref={inputRef}
							type='text'
							maxLength={35}
							value={
								isFocused
									? searchTerm
									: TruncateBeginningString(selectedOption?.label, truncateLength) ||
									  `${translate(placeholder)}...`
							}
							onChange={(e) => setSearchTerm(e.target.value)}
							onFocus={() => setIsFocused(true)}
							onBlur={() => {
								// Delay onBlur to allow click event to fire on options
								if (!isMouseOverOptions) {
									setIsFocused(false);
								}
							}}
							placeholder={
								isFocused && selectedOption
									? TruncateBeginningString(selectedOption?.label, truncateLength)
									: `${translate(placeholder)}...`
							}
							className={`group border-none bg-transparent ring-0 focus:ring-transparent text-gray-900 text-sm w-full p-2.5`}
						/>
						<div className='flex flex-row '>
							{(selectedOption || searchTerm) && (
								<button
									type='button'
									onClick={() => {
										if (!disabled) {
											if (searchTerm) setSearchTerm('');
											else handleRemoveSelectedOption();
										}
									}}
									className={` ${
										isLoading && 'opacity-5'
									} flex items-center pr-3 opacity-60 hover:opacity-100 cursor-pointer`}
									aria-label='Clear selected option'
								>
									<i className=' ri-close-circle-fill'></i>
								</button>
							)}
							{onSideButtonClick && (
								<button
									type='button'
									onClick={(event) => {
										if (!disabled) onSideButtonClick(event);
									}}
									className={` flex items-center pr-3 opacity-60 hover:opacity-100 cursor-pointer ${
										isLoading && 'opacity-5'
									}`}
									aria-label='Side Button'
								>
									<i className={sideButtonIcon}></i>
								</button>
							)}
						</div>
					</div>

					{isLoading && (
						<div>
							<div
								className={`absolute inset-y-0 right-0  flex items-center pr-3 opacity-100  cursor-progress`}
							>
								<i className='ri-loader-4-line animate-spin text-blue-500 text-xl'></i>
							</div>
						</div>
					)}
				</div>

				{isFocused && (
					<div
						onWheel={(e) => e.stopPropagation()}
						className='options mt-1 max-h-60 overflow-y-auto border border-gray-300 rounded-md'
						onMouseEnter={() => setIsMouseOverOptions(true)}
						onMouseLeave={() => setIsMouseOverOptions(false)}
						ref={optionsDivRef}
					>
						{displayOptions.current?.map((option, index) => (
							<div
								key={index}
								onClick={() => handleSelectOption(option)}
								className={`option cursor-pointer text-sm px-4 py-2 hover:bg-gray-100 
                                ${selectedOption?.id === option?.id ? 'bg-gray-200' : ''}
                                `}
							>
								{option?.html ? (
									<div dangerouslySetInnerHTML={{ __html: option.html }}></div>
								) : (
									<div>{option.label}</div>
								)}
							</div>
						))}
						<div ref={lastOptionElementRef}></div>
					</div>
				)}
				<Tooltip place='top' id='search-select-input-tooltip' />
			</div>
		);
	},
);

export default SingleSelectInput;
