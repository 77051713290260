import NewNode from "./NewNode";
import NodeLoader from "./NodeLoader";

const RootNode = ({
	rootLevel = 6,
	onAddOpen = () => {},
	loading = false,
	children,
}) => {
	// ================================================
	// COMPONENT
	// ================================================
	return (
		<>
			{loading ? (
				<NodeLoader level={0} rootLevel={rootLevel} />
			) : (
				<>
					{children}
					<NewNode
						onClick={() => onAddOpen()}
						level={rootLevel}
						rootLevel={rootLevel}
					/>
				</>
			)}
		</>
	);
};

export default RootNode;
