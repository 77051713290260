import React from "react";
import { Navigate } from "react-router-dom";

const Main = React.lazy(() => import("../pages/main"));

const routes = [
	{ index: true, element: <Main /> },
	{ path: "*", element: <Navigate to={""} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
