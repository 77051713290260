import AbstractService from "services/Service";
const endpoint = "patients";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}
}

export default Service;
