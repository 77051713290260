import AbstractService from "services/Service";
const endpoint = "employees/online";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	getOne() {
		throw new Error("Method does not exist");
	}

	create() {
		throw new Error("Method does not exist");
	}

	update() {
		throw new Error("Method does not exist");
	}

	remove() {
		throw new Error("Method does not exist");
	}

	getExport() {
		throw new Error("Method does not exist");
	}
}
export default Service;
