export const PaginationMap = {
	page: "page",
	size: "pageSize",
	total: "totalFiltered",
};

export const QueryMap = {
	sortBy: "sortBy",
	sortAsc: "sortAsc",
};

export const extractMeta = (data, queryParams = "") => {
	const meta = {
		others: {},
	};

	Object.keys(PaginationMap).forEach((key) => {
		const apiKey = PaginationMap[key];
		if (data.hasOwnProperty(apiKey)) {
			// If not found in query parameters, use the value from data
			meta[key] = data[apiKey];
		}
	});

	Object.keys(QueryMap).forEach((key) => {
		const apiKey = QueryMap[key];

		// If the query parameters string is provided and contains the key
		if (queryParams && queryParams.includes(`${apiKey}=`)) {
			// Extract the value from the query parameters
			const regex = new RegExp(`${apiKey}=(.*?)(&|$)`);
			const match = queryParams.match(regex);
			meta[key] = match ? match[1] : null;

			if (key === "sortAsc") meta[key] = meta[key] === "true";
		}
	});

	// Extract everything else and store in meta.others
	const queryPairs = queryParams.split("&");
	queryPairs.forEach((pair) => {
		const [key, value] = pair.split("=");

		if (
			!Object.keys(QueryMap).includes(key) &&
			!Object.values(PaginationMap).includes(key)
		) {
			// If the key is not in QueryMap, store in meta.others
			if (meta.others[key]) {
				// If the key already exists, push the new value to the array
				meta.others[key].push(value);
			} else {
				// If the key doesn't exist, create a new array with the value
				meta.others[key] = [value];
			}
		}
	});

	// console.log("Extracted Meta", meta);

	return meta;
};

export const buildPagination = (meta) => {
	const { page, size, sortBy, sortAsc } = meta;
	const queryParams = {
		[PaginationMap.page]: page ?? 1,
		[PaginationMap.size]: size ?? 10,
	};

	if (sortBy) {
		queryParams[QueryMap.sortBy] = sortBy;
		queryParams[QueryMap.sortAsc] = sortAsc;
	}

	return new URLSearchParams(queryParams).toString();
};

export const buildExportParams = (meta, l_filters) => {
	const { sortBy, sortAsc } = meta;
	const queryParams = {};

	if (sortBy) {
		queryParams[QueryMap.sortBy] = sortBy;
		queryParams[QueryMap.sortAsc] = sortAsc;
	}
	const params = new URLSearchParams(queryParams);

	if (l_filters) {
		const filters = sanitizeFilters(l_filters);

		for (const key in filters) {
			if (Array.isArray(filters[key])) {
				filters[key].forEach((value) => params.append(key, value));
			} else {
				params.append(key, filters[key]);
			}
		}
	}

	return params.toString();
};

export const buildFilterParams = (l_filters) => {
	const filters = sanitizeFilters(l_filters);

	const params = new URLSearchParams();

	for (const key in filters) {
		if (Array.isArray(filters[key])) {
			filters[key].forEach((value) => params.append(key, value));
		} else {
			params.append(key, filters[key]);
		}
	}

	return params.toString();
};

export const sanitizeFilters = (filters) => {
	Object.keys(filters).forEach((key) => {
		if (filters[key] === undefined) {
			return;
		}

		if (key.startsWith("query_")) {
			//check if the value is longer than 3 characters
			const newKey = key.replace("query_", "");

			if (Array.isArray(filters[key])) {
				if (filters[key].length > 0 && filters[key][0].length > 2) {
					filters[newKey] = filters[key];
				} else {
					delete filters[newKey];
				}
			} else {
				if (filters[key].length > 2) {
					filters[newKey] = filters[key];
				} else {
					delete filters[newKey];
				}
			}

			delete filters[key];
		}
	});

	return filters;
};
