import NfcScanner from "./nfc.portal";
import QrScanner from "./qr.portal";

export const portals = [
	{
		id: "scanners:qr",
		element: QrScanner,
		initialState: {},
	},
	{
		id: "scanners:nfc",
		element: NfcScanner,
		initialState: {},
	},
];

export const PORTALS = {
	qr: "scanners:qr",
	nfc: "scanners:nfc",
};

export default portals;
