export const getRandomColor = () => {
	return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

/**
 *
 * @param {*} color #RRGGBB
 * @param {*} percent negative for darker, positive for lighter
 * @returns
 */
export const changeColorContrast = (color, percent) => {
	const num = parseInt(color.replace('#', ''), 16),
		amt = Math.round(2.55 * percent),
		R = (num >> 16) + amt,
		G = ((num >> 8) & 0x00ff) + amt,
		B = (num & 0x0000ff) + amt;
	return (
		'#' +
		(
			0x1000000 +
			(R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
			(G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
			(B < 255 ? (B < 1 ? 0 : B) : 255)
		)
			.toString(16)
			.slice(1)
			.toUpperCase()
	);
};
