import { useEffect, useRef } from "react";

function useParentFormSubmission(callback) {
	const inputRef = useRef();

	useEffect(() => {
		const form = inputRef.current.closest("form");
		if (!form) return;

		const handleSubmit = (event) => {
			callback(event, form);
		};

		form.addEventListener("submit", handleSubmit);
		return () => form.removeEventListener("submit", handleSubmit);
	}, [callback]);

	return inputRef;
}

export default useParentFormSubmission;
