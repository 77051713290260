import Swal from "sweetalert2";
import { useTranslations } from "hooks";
import toast from "react-hot-toast";

const ResetPassword = ({ props }) => {
	const { translate } = useTranslations();

	const resetPasswordConfirmation = (username) => {
		Swal.fire({
			title: translate("areYouSure"),
			text: translate("youAreAboutToResetPasswordFor", false, username),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: translate("yesSendIt"),
			cancelButtonText: translate("cancel"),
			confirmButtonColor: "#EF4444",
			cancelButtonColor: "#b5bec9",
		}).then((result) => {
			if (result.isConfirmed) resetPassword(username);
		});
	};

	const resetPassword = async (username) => {
		await props.service.forgotPassword(username).then((res) => {
			if (res.succeeded) {
				props?.requestReload();
				toast(translate("resetPasswordMailHasBeenSentSuccessfully"), {
					duration: 2000,
					icon: "✅",
				});
			} else {
				toast(res.message || translate("somethingWentWrong"), {
					duration: 2000,
					icon: "❗️",
				});
			}
		});
	};

	return {
		resetPasswordConfirmation,
	};
};
export default ResetPassword;
