import { MarkedBooleanRender } from 'core/renders';
import { capitalizeFirstLetter } from 'utils';
import { BooleanColumn } from './baseColumns';

class MarkedBooleanColumn extends BooleanColumn {
	constructor(name, options = {}) {
		super(name, options);
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || (name ? capitalizeFirstLetter(name) : null);
		this.hidden = options.hidden || false;
		this.trueValue = options.trueValue || 'Yes';
		this.falseValue = options.falseValue || 'No';
		this.trueClass = options.trueClass || 'text-green-500';
		this.falseClass = options.falseClass || 'text-red-500';
		this.render = (value) => (
			<MarkedBooleanRender
				value={value}
				trueClass={this.trueClass}
				falseClass={this.falseClass}
				trueVal={this.trueValue}
				falseVal={this.falseValue}
			/>
		);
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default MarkedBooleanColumn;
