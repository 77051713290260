import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { withPaddinglessLayout, withSuspense } from 'hoc';
import { useToolbar } from 'hooks';

import SettingsSidebar from './components/SettingsSidebar';

import ToggleButton from './components/SidebarToggleButton';
import routes from './routes';

const SettingsPage = ({ permissions: permissionsMap }) => {
	const [sidebarToggled, setSidebarToggled] = useState(false);
	const location = useLocation();
	const { crumbsBuilder } = useToolbar();

	const hash = location.hash; // Use this to dynamically render pages

	useEffect(() => {
		crumbsBuilder.addPath('/persons', 'users');
		crumbsBuilder.addCurrent('settings');
		crumbsBuilder.build();
	}, []);

	const checkNestedPermissions = (permissions) => {
		for (let key in permissions) {
			if (typeof permissions[key] === 'boolean' && permissions[key]) {
				return true;
			} else if (typeof permissions[key] === 'object') {
				if (checkNestedPermissions(permissions[key])) {
					return true;
				}
			}
		}
		return false;
	};

	const renderComponent = () => {
		if (!hash && routes.length > 0 && routes[0].items.length > 0) {
			const Component = routes[0].items[0].component;
			const permissions = permissionsMap[routes[0].key][routes[0].items[0].key];
			if (permissions.View) {
				return <Component permissions={permissions} />;
			} else return <></>;
		}
		for (let section of routes) {
			for (let item of section.items) {
				if (item.hash === hash) {
					const Component = item.component;
					const permissions = permissionsMap[section.key][item.key];
					return <Component permissions={permissions} />;
				}
			}
		}
		return <></>;
	};

	// Filter the routes array
	const filteredRoutes = routes
		.filter((route) => checkNestedPermissions(permissionsMap[route.key]))
		.map((route) => {
			if (route.items) {
				return {
					...route,
					items: route.items.filter((item) => checkNestedPermissions(permissionsMap[route.key][item.key])),
				};
			}
			return route;
		});

	return (
		<div className='w-full h-full flex flex-row' style={{ height: 'calc(100vh - 115px)' }}>
			<SettingsSidebar
				toggled={sidebarToggled}
				setToggled={(t) => setSidebarToggled(t)}
				hash={hash}
				routes={filteredRoutes}
				permissions={permissionsMap}
			/>

			<div className='sm:hidden'>
				<ToggleButton onClick={() => setSidebarToggled(true)} />
			</div>
			<div className='p-4 w-full h-full overflow-y-auto'>{renderComponent()}</div>
		</div>
	);
};

export default withSuspense(withPaddinglessLayout(SettingsPage));
