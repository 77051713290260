const StringWrapRender = (value, wrapWordCount = 6) => {
	if (!value) return '';
	// Split the string into words
	const words = value.split(' ');

	// Group the words into chunks of 6
	const lines = [];
	for (let i = 0; i < words.length; i += wrapWordCount) {
		lines.push(words.slice(i, i + wrapWordCount).join(' '));
	}

	// Join the chunks with newline characters
	const formattedValue = lines.join('\n');

	return (
		<div className='whitespace-pre-wrap'>
			{formattedValue?.split('\n').map((line, index) => (
				<div key={index}>{line}</div>
			))}
		</div>
	);
};

export default StringWrapRender;
