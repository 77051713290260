import { DateTimeRender } from 'core/renders';
import { useTranslations } from 'hooks';
import { renderToString } from 'react-dom/server';
import PlannedDepartureRender from './PlannedDepartureRender';
import ScheduledDepartureRender from './ScheduleDepartureRender';
import UserRender from './UserRender';
const TaskInformationRender = ({ value = null, row }) => {
	const { translate } = useTranslations();

	const tooltipHtml = (
		<div className='-my-1 -mx-3 p-3 border border-gray-100 rounded-md'>
			<table className='table-auto text-left'>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('taskNumber')}
						{': '}
					</th>
					<td>{row.id}</td>
				</tr>
				<tr className='pb-1'>
					<th className='pr-2'>
						{translate('createdOn')}
						{': '}
					</th>
					<td>
						{DateTimeRender({ value: row.createdOn })}
						<span className='pl-2 text-xs'>
							{UserRender({ value: row.createdBy, includeUsername: true })}
						</span>
					</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('scheduleTime')}
						{': '}
					</th>
					<td className='pl-1'>{ScheduledDepartureRender({ value: row?.scheduledArrival, row: row })}</td>
				</tr>
				<tr className='pb-1'>
					<th className='pr-2'>
						{translate('plannedDeparture')}
						{': '}
					</th>
					<td className='pl-1'>{PlannedDepartureRender({ value: row?.scheduledDeparture, row: row })}</td>
				</tr>
				{row.startedOn && (
					<tr className='pb-1'>
						<th className=' pr-2'>
							{translate('startedOn')}
							{': '}
						</th>
						<td>{DateTimeRender({ value: row.startedOn })}</td>
					</tr>
				)}

				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('dispatchingMethod')}
						{': '}
					</th>
					<td>{translate(row?.taskExtraDetails?.dispatchingMethod?.name)}</td>
				</tr>
				<tr className='pb-1'>
					<th className=' pr-2'>
						{translate('team')}
						{': '}
					</th>
					<td>
						<div
							style={{
								backgroundColor: row?.team?.color,
							}}
							className={`inline-block  rounded-full h-3 w-3 mr-2`}
						></div>
						{row?.team?.name}
					</td>
				</tr>
				<tr className='pb-3'>
					<th className='pr-2'>
						{translate('taskTemplate')}
						{': '}
					</th>
					<td>
						<div>
							<div
								style={{
									backgroundColor: row?.taskTemplate?.color,
								}}
								className={`inline-block  rounded-full h-3 w-3 mr-2`}
							></div>
							{row?.taskTemplate?.name}
						</div>
					</td>
				</tr>
			</table>
			{row?.taskExtraDetails?.taskActionModels?.length > 0 && (
				<div className='pb-3 pt-3'>
					<div className='text-lg text-center opacity-60 font-bold  '>{translate('taskActions')}</div>
					<div className='border-b-2 border-gray-200  mb-2'></div>

					{row?.taskExtraDetails?.taskActionModels?.map((action) => (
						<div className='pb-2 text-xs'>
							<div className='text-xs text-primary-600 font-bold'>{translate(action?.action?.name)}</div>
							<table className='table-auto pt-1 text-left'>
								{action?.performedOn && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('performedOn')}
											{': '}
										</th>
										<td>{DateTimeRender({ value: action?.performedOn })}</td>
									</tr>
								)}
								{action?.performedBy && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('performedBy')}
											{': '}
										</th>
										<td>{action?.performedBy?.name}</td>
									</tr>
								)}
								{action?.blockedUntil && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('blockedUntil')}
											{': '}
										</th>
										<td>{DateTimeRender({ value: action?.blockedUntil })}</td>
									</tr>
								)}
								{action?.complexTaskActionName && (
									<tr className='pb-1'>
										<th className=' pr-2'>
											{translate('complexTaskActionName')}
											{': '}
										</th>
										<td>{translate(action.complexTaskActionName)}</td>
									</tr>
								)}
							</table>
						</div>
					))}
				</div>
			)}
		</div>
	);

	return (
		<div
			data-tooltip-variant='light'
			data-tooltip-id={`table-tooltip`}
			data-tooltip-delay-hide={300}
			data-tooltip-html={renderToString(tooltipHtml)}
			className='text-primary-500 hover:opacity-75'
		>
			<i class='ri-information-line'></i>
		</div>
	);
};

export default TaskInformationRender;
