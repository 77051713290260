import configMap from "configmap.json";

const config = {
	// baseURL: "proxyapi",
	baseURL: configMap.base_url,
	loginURL: configMap.login_url,
	refreshURL: configMap.refresh_url,
};

export default config;
