import axiosClient from './AxiosClient';
import fetchClient from './FetchClient';

class NetworkService {
	constructor(client = 'axios') {
		switch (client) {
			case 'axios':
				this.client = axiosClient;
				break;
			case 'fetch':
				this.client = fetchClient;
				break;
			default:
				throw new Error(`Network client ${client} does not exist in Factory config`);
		}
		this.clientType = client;
	}

	async _handleClientResponse(response) {
		switch (this.clientType) {
			case 'axios':
				return response.data;
			case 'fetch':
				return response.json();
			default:
				return null;
		}
	}

	_isResponseSuccess(response) {
		return [200, 201].indexOf(response.status) >= 0;
	}

	async request(url, options = {}) {
		if (!this.client) {
			throw new Error('Network client is not set.');
		}

		try {
			const response = await this.client(url, options);
			if (this._isResponseSuccess(response)) {
				return this._handleClientResponse(response);
			}

			throw new Error(`HTTP error: ${response?.status}`);
		} catch (error) {
			if (error.response) {
				//we need to handle 400 errors differently
				if (error?.response?.status === 400) {
					return error?.response?.data;
				}
				throw new Error(`HTTP error: ${error?.response?.status}. ${error?.response?.data}`);
			} else if (error?.request) {
				throw new Error('No response was received from the server.');
			} else {
				throw new Error(`Network error: ${error?.message}`);
			}
		}
	}

	async get(url, options = {}) {
		options.method = 'GET';
		return this.request(url, options);
	}

	async postForm(url, formData, options = {}) {
		options.method = 'POST';
		options.headers = options.headers || {};
		options.headers['Content-Type'] = 'multipart/form-data';
		options.data = formData;
		return this.request(url, options);
	}

	async post(url, data = {}, options = {}) {
		options.method = 'POST';
		options.headers = options.headers || {};
		options.headers['Content-Type'] = 'application/json';
		options.data = JSON.stringify(data);
		return this.request(url, options);
	}

	async putForm(url, formData, options = {}) {
		options.method = 'PUT';
		options.headers = options.headers || {};
		options.headers['Content-Type'] = 'multipart/form-data';
		options.data = formData;
		return this.request(url, options);
	}

	async put(url, data = {}, options = {}) {
		options.method = 'PUT';
		options.headers = options.headers || {};
		options.headers['Content-Type'] = 'application/json';
		options.data = JSON.stringify(data);
		return this.request(url, options);
	}

	async patch(url, data = {}, options = {}) {
		options.method = 'PATCH';
		options.headers = options.headers || {};
		options.headers['Content-Type'] = 'application/json';
		options.data = JSON.stringify(data);
		return this.request(url, options);
	}

	async delete(url, options = {}) {
		options.method = 'DELETE';
		return this.request(url, options);
	}
}

export default NetworkService;
