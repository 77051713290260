import Component from "./component";
import routes from "./routes";

const module = {
	Component,
	routes,
	portals: [],
};

export default module;
