import PermissionMap from 'config/PermissionMap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { withDashboardLayout } from '../../hoc';

const AssetsModule = () => {
	const { getPermissionMaps, findPageId, checkNestedPermissions } = PermissionMap();
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname.substring(1);
	const pageId = findPageId(pathName, config.pages);

	const permissionsMap = getPermissionMaps(pageId);
	const hasPermission = checkNestedPermissions(permissionsMap);

	// If no permission, navigate to the next available id
	if (!hasPermission) {
		config.pages.forEach((page) => {
			const permissionsMap = getPermissionMaps(page.id);
			const hasPermission = checkNestedPermissions(permissionsMap);

			if (hasPermission) {
				navigate(`/${page.id}`);
				return;
			}
		});
	}
	return <Outlet context={{ permissionsMap }} />;
};

const config = {
	page: 'assets',
	pages: [
		{ id: 'assets/assets', label: 'assets', to: '' },
		// { id: "assets/audit", label: "Audit", to: "audit" },
		{ id: 'assets/settings', label: 'Settings', to: 'settings' },
	],
};

export default withDashboardLayout(AssetsModule, config);
