import { DescriptionColumn, IdColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class PermissionGroupModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'permissionGroup';
		this.pluralModelName = 'permissionGroups';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new NameColumn());
		this.columns.push(new DescriptionColumn());
		this.assignColumnOrder();
	}

	isExportable() {
		return false;
	}

	areSelectsVisible() {
		return false;
	}
}
export default PermissionGroupModel;
