import React from "react";
import { Navigate } from "react-router-dom";

import NotImplemented from "components/system/NotImplemented";

import Addresses from "../pages/addresses";
import CallTester from "../pages/addresses/parts/CallTester";
import Settings from "../pages/settings";

const routes = [
	{ index: true, element: <Navigate to="locations" replace /> },
	{ path: "locations", element: <Addresses /> },
	{ path: "external", element: <NotImplemented /> },
	{ path: "settings", element: <Settings /> },
	{ path: "call-tester", element: <CallTester /> },

	{ path: "*", element: <Navigate to={""} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
