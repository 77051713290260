import { Button } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useRef } from 'react';
import ReturnTaskTable from './Table';

const ReturnTask = ({
	fetchAndLoad,
	service,
	item,
	isVisible,
	onClose = () => {},
	onReturnBack = () => {},
	onCreateAdHoc = () => {},
	onToTaskClick = () => {},
}) => {
	const { translate } = useTranslations();

	const returnDialogRef = useRef(null);

	useEffect(() => {
		if (isVisible) {
			returnDialogRef.current.showModal();
		} else {
			returnDialogRef.current.close();
		}
	}, [isVisible]);

	const closeHandler = () => {
		returnDialogRef.current.close();
		onClose();
	};

	return (
		<dialog ref={returnDialogRef} className='rounded-lg overflow-y-visible'>
			<div className='flex flex-col  px-5 pt-5 pb-5 bg-gray-50'>
				<div className='flex flex-row justify-between items-center w-auto'>
					<div className='uppercase text-lg text-gray-500'>{translate('returnTask')}</div>
					<i className='ri-close-line cursor-pointer text-md text-gray-500' onClick={closeHandler}></i>
				</div>
				<div className='border-b-2 border-gray-200 mt-2 mb-5'></div>
				<div className='pt-5 pb-5 flex flex-row justify-center items-center space-x-10'>
					<Button small onClick={onReturnBack}>
						<i className={`ri-arrow-go-back-line mr-2`}></i>
						{translate('returnBack')}
					</Button>

					<Button small color='secondary' onClick={onCreateAdHoc}>
						<i className='ri-git-merge-line mr-2'></i>
						{translate('createAdHoc')}
					</Button>
				</div>
				<div>
					<div className='text-gray-500 text-sm'>{translate('pleaseSelectATaskToReturn')}</div>
				</div>
				{isVisible && (
					<div className='pt-3 px-3'>
						<ReturnTaskTable onTaskClick={onToTaskClick} taskId={item.id} />
					</div>
				)}
			</div>
		</dialog>
	);
};

export default ReturnTask;
