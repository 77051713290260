import AbstractService from 'services/Service';
const endpoint = 'assets/categories';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}
}
export default Service;
