import Auth from "./auth";
import Tasks from "./tasks";
import Persons from "./persons";
import Addresses from "./addresses";
import Assets from "./assets";
import Reports from "./reports";
import Settings from "./settings";
import Scanners from "./scanners";
import ExternalIntegratione from "./externalIntegration/";

import Sample from "./sample";
import { portals } from "router";

export const modules = [
	{
		id: "tasks",
		element: <Tasks.Component />,
		routes: Tasks.routes,
		portals: Tasks.portals,
	},
	{
		id: "persons",
		element: <Persons.Component />,
		routes: Persons.routes,
		portals: Persons.portals,
	},
	{
		id: "locations",
		element: <Addresses.Component />,
		routes: Addresses.routes,
		portals: Addresses.portals,
	},
	{
		id: "assets",
		element: <Assets.Component />,
		routes: Assets.routes,
		portals: Assets.portals,
	},
	{
		id: "reports",
		element: <Reports.Component />,
		routes: Reports.routes,
		portals: Reports.portals,
	},
	{
		id: "settings",
		element: <Settings.Component />,
		routes: Settings.routes,
		portals: Settings.portals,
	},
	{
		id: "auth",
		element: <Auth.Component />,
		routes: Auth.privateAccessibleRoutes,
		portals: Auth.portals,
	},
	{
		id: "scanners",
		element: <></>,
		routes: Scanners.routes,
		portals: Scanners.portals,
	},
];

export const publicModules = [
	{
		id: "auth",
		element: <Auth.Component />,
		routes: Auth.routes,
		portals: Auth.portals,
	},
	{
		id: "external-integration",
		element: <ExternalIntegratione.Component />,
		routes: ExternalIntegratione.routes,
		portals: ExternalIntegratione.portals,
	},
	{
		id: "dev",
		element: <Sample.Component />,
		routes: Sample.routes,
		portals: Sample.portals,
	},
];

export default modules;
