import React from "react";
import { Button, DropdownButton } from "components/ui/Input";
import { useTranslations } from "hooks";

const ActionButton = ({
	key,
	label,
	color = "primary",
	icon,
	children = null,
	item,
	onClick = () => {},
}) => {
	const { translate } = useTranslations();
	if (!children) {
		return (
			<Button small color={color} key={key} onClick={onClick}>
				{icon && <i className={`${icon} mr-2`}></i>}
				{translate(label, true)}
			</Button>
		);
	} else {
		return (
			<DropdownButton
				label={translate(label, true)}
				icon={icon}
				small={true}
				color={color}
				key={key}
			>
				{children.map((child, index) => {
					return (
						<Button
							className={"w-full mb-1"}
							small
							color={child.color}
							key={index}
							onClick={() => {
								child.onClick(item);
							}}
						>
							{child.icon && (
								<i className={`${child.icon} mr-2`}></i>
							)}
							{translate(child.label, true)}
						</Button>
					);
				})}
			</DropdownButton>
		);
	}
};

export default ActionButton;
