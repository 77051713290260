import AddressSelectorPortal from "./AddressSelector";

export const portals = [
	{
		id: "addresses:select",
		element: AddressSelectorPortal,
		initialState: {
			level: -1,
			defaultId: 1
		},
	},
];

export const PORTALS = {
	select: "addresses:select",
};

export default portals;
