import { PORTALS } from "./portals";

const utils = {
	openQr: ({ callAction, onScan }) =>
		callAction(PORTALS.qr, "open", {}, onScan),
	openNfc: ({ callAction, onScan }) =>
		callAction(PORTALS.nfc, "open", {}, onScan),
};

export default utils;
