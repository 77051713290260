import React from "react";
import { useTranslations } from "hooks";

const Item = ({ icon, name, text, hasDivider = true, onClick = () => {} }) => {
	const { translate } = useTranslations();
	return (
		<div
			onClick={onClick}
			className={`cursor-pointer flex flex-row items-center px-2 py-3 mb-3 opacity-50 hover:opacity-100 w-full ${
				hasDivider ? "border-b" : ""
			} border-slate-200`}
		>
			<div className="rounded w-10 h-10 mr-2 flex justify-center items-center border border-slate-200">
				<i className={`${icon} text-2xl`}></i>
			</div>
			<div className="flex flex-col">
				<div className="font-medium">{translate(name)}</div>
				<div className="text-xs text-slate-500">{translate(text)}</div>
			</div>
			<div className="flex flex-1"></div>
			<i className="ri-arrow-right-s-line text-3xl"></i>
		</div>
	);
};

const TwoFa = ({
	selectAndNext = () => {},
	skip = () => {},
	skipIsVisible = true,
}) => {
	const { translate } = useTranslations();
	return (
		<div className="flex flex-col pt-5 pb-0 w-full h-full">
			<div className="flex flex-col items-center mb-6">
				<img
					src={require("../../../assets/2fa.png")}
					className="w-20 h-20 mb-4"
					alt="2fa icon"
				/>
				<h1 className="text-4xl font-medium mb-1">
					{translate("2FactorAuth")}
				</h1>
				<p className="text-xs">
					{translate(
						"selectYourPreferred2FactorAuthenticationMethodBelow"
					)}
				</p>
			</div>
			<div className="flex w-7/8 flex-col self-center items-center h-full">
				<Item
					onClick={() => selectAndNext("app")}
					icon="ri-qr-code-line"
					name="appAuthenticator"
					text="useAMobileAppToAuthenticate"
				/>
				<Item
					onClick={() => selectAndNext("email")}
					icon="ri-mail-line"
					name="emailAuthentication"
					text="receiveAnEmailWithAnAuthenticationCode"
				/>
				{/* <Item
					onClick={selectAndNext}
					icon="ri-smartphone-line"
					name="SMS authentication"
					text="Receive a text message with an authentication code"
				/> */}
			</div>
			<div className="flex flex-row-reverse pt-2 pb-2">
				{skipIsVisible ? (
					<div
						className="cursor-pointer group font-medium flex px-3 py-2 hover:pr-2 justify-center items-center transition-all"
						onClick={() => skip()}
					>
						{translate("skip")}{" "}
						<i className="ri-arrow-right-line ml-2 text-xl "></i>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default TwoFa;
