import { IdColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';
class RoomTypeModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'roomType';
		this.pluralModelName = 'roomTypes';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new NameColumn('name'));
		this.assignColumnOrder();
	}
	isExportable() {
		return false;
	}

	areSelectsVisible() {
		return false;
	}
}

export default RoomTypeModel;
