import { Input, SearchSelect } from 'components/ui/Input';
import { useEffect, useState } from 'react';

import { useTranslations } from 'hooks';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
const Filters = ({ onChange = () => {} }) => {
	const { translate } = useTranslations();

	const taskGroupService = new TaskGroupService();

	const [taskGroups, setTaskGroups] = useState([]);

	const [team, setTeam] = useState(null);

	useEffect(() => {
		const request = prepareRequest();
		onChange(request);
	}, [team, taskGroups]);

	const prepareRequest = () => {
		const request = {};

		if (team && team.length > 2) {
			request.Name = team;
		} else {
			request.Name = null;
		}

		if (taskGroups.length > 0) {
			request.TaskGroupIds = taskGroups;
		} else {
			request.TaskGroupIds = null;
		}

		return request;
	};

	return (
		<div className='ml-3 grid grid-cols-1 gap-4 sm: md:grid-cols-4 w-full items-center '>
			<div>
				<div className='h-4 text-xs font-semibold text-slate-500'>{translate('team')}</div>
				<div className='font-small text-md'>
					<Input
						type='text'
						placeholder='searchByTeamName'
						onChange={(e) => setTeam(e.target.value)}
						value={team}
					/>
				</div>
			</div>

			<div>
				{/* //Task Group */}
				<div className='h-4 text-xs font-semibold text-slate-500'>{translate('taskGroup')}</div>
				<div className='font-small text-md'>
					<SearchSelect
						multiple
						service={taskGroupService}
						onChange={(value) => {
							const selected = value?.map((item) => item.value);
							setTaskGroups(selected);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Filters;
