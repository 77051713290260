import { EmailRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class EmailColumn extends StringColumn {
	constructor(name = 'email', options = {}) {
		super(options.name || name, options);
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.truncateLength = options.truncateLength || 24;
		this.render = (value) => <EmailRender value={value} num={this.truncateLength} />;
		this.filter = options.filter || {
			type: 'text',
			key: name,
		};
		this.order = options.order || 0;
	}
}
export default EmailColumn;
