import { DescriptionColumn, IdColumn, NameColumn } from 'core/columns';
import { BooleanColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';

class RuleModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'rule';
		this.pluralModelName = 'rules';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new NameColumn());
		this.columns.push(new BooleanColumn('enabled'));
		this.columns.push(new DescriptionColumn());
		this.assignColumnOrder();
	}
	isExportable() {
		return false;
	}
	areSelectsVisible() {
		return false;
	}
}
export default RuleModel;
