const customIcons = [
	{
		name: "hospitalThin",
		path: "ri-hospital-line",
	},
	{
		name: "hospitalBold",
		path: "ri-hospital-fill",
	},
	{
		name: "heartThin",
		path: "ri-heart-line",
	},
	{
		name: "heartBold",
		path: "ri-heart-fill",
	},
	{
		name: "heart2Thin",
		path: "ri-heart-2-line",
	},
	{
		name: "heart2Bold",
		path: "ri-heart-2-fill",
	},
	{
		name: "heartsThin",
		path: "ri-hearts-line",
	},
	{
		name: "heartsBold",
		path: "ri-hearts-fill",
	},
	{
		name: "heartPulseThin",
		path: "ri-heart-pulse-line",
	},
	{
		name: "heartPulseBold",
		path: "ri-heart-pulse-fill",
	},
	{
		name: "pulseThin",
		path: "ri-pulse-line",
	},
	{
		name: "pulseBold",
		path: "ri-pulse-fill",
	},
	{
		name: "empathizeThin",
		path: "ri-empathize-line",
	},
	{
		name: "empathizeBold",
		path: "ri-empathize-fill",
	},
	{
		name: "nurseThin",
		path: "ri-nurse-line",
	},
	{
		name: "nurseBold",
		path: "ri-nurse-fill",
	},
	{
		name: "dossierThin",
		path: "ri-dossier-line",
	},
	{
		name: "dossierBold",
		path: "ri-dossier-fill",
	},
	{
		name: "healthBookBold",
		path: "ri-health-book-fill",
	},
	{
		name: "healthBookThin",
		path: "ri-health-book-line",
	},
	{
		name: "firstAidKitThin",
		path: "ri-first-aid-kit-line",
	},
	{
		name: "firstAidKitBold",
		path: "ri-first-aid-kit-fill",
	},
	{
		name: "capsuleThin",
		path: "ri-capsule-line",
	},
	{
		name: "capsuleBold",
		path: "ri-capsule-fill",
	},
	{
		name: "medicineBottleThin",
		path: "ri-medicine-bottle-line",
	},
	{
		name: "medicineBottleBold",
		path: "ri-medicine-bottle-fill",
	},
	{
		name: "flaskThin",
		path: "ri-flask-line",
	},
	{
		name: "flaskBold",
		path: "ri-flask-fill",
	},
	{
		name: "testTubeThin",
		path: "ri-test-tube-line",
	},
	{
		name: "testTubeBold",
		path: "ri-test-tube-fill",
	},
	{
		name: "microscopeThin",
		path: "ri-microscope-line",
	},
	{
		name: "microscopeBold",
		path: "ri-microscope-fill",
	},
	{
		name: "handSanitizerThin",
		path: "ri-hand-sanitizer-line",
	},
	{
		name: "handSanitizerBold",
		path: "ri-hand-sanitizer-fill",
	},
	{
		name: "mentalHealthThin",
		path: "ri-mental-health-line",
	},
	{
		name: "mentalHealthBold",
		path: "ri-mental-health-fill",
	},
	{
		name: "psychotherapyBold",
		path: "ri-psychotherapy-fill",
	},
	{
		name: "psychotherapyThin",
		path: "ri-psychotherapy-line",
	},
	{
		name: "stethoscopeThin",
		path: "ri-stethoscope-line",
	},
	{
		name: "stethoscopeBold",
		path: "ri-stethoscope-fill",
	},
	{
		name: "syringeThin",
		path: "ri-syringe-line",
	},
	{
		name: "syringeBold",
		path: "ri-syringe-fill",
	},
	{
		name: "thermometerThin",
		path: "ri-thermometer-line",
	},
	{
		name: "thermometerBold",
		path: "ri-thermometer-fill",
	},
	{
		name: "infraredThermometerThin",
		path: "ri-infrared-thermometer-line",
	},
	{
		name: "infraredThermometerBold",
		path: "ri-infrared-thermometer-fill",
	},
	{
		name: "surgicalMaskThin",
		path: "ri-surgical-mask-line",
	},
	{
		name: "surgicalMaskBold",
		path: "ri-surgical-mask-fill",
	},
	{
		name: "virusThin",
		path: "ri-virus-line",
	},
	{
		name: "virusBold",
		path: "ri-virus-fill",
	},
	{
		name: "lungsThin",
		path: "ri-lungs-line",
	},
	{
		name: "lungsBold",
		path: "ri-lungs-fill",
	},
	{
		name: "restTimeThin",
		path: "ri-rest-time-line",
	},
	{
		name: "restTimeBold",
		path: "ri-rest-time-fill",
	},
	{
		name: "zzzThin",
		path: "ri-zzz-line",
	},
	{
		name: "zzzBold",
		path: "ri-zzz-fill",
	},
	{
		name: "brainThin",
		path: "ri-brain-line",
	},
	{
		name: "brainBold",
		path: "ri-brain-fill",
	},
];

export default customIcons;
