import { useEffect, useRef, useState } from 'react';

const DropdownButton = ({
	label = '',
	icon = '',
	children,
	className = '',
	small = false,
	disabled = false,
	type = 'button',
	color = 'primary',
	iconOnly = false,
	isLoading = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const dropdownRef = useRef(null);

	useEffect(() => {
		if (isOpen && dropdownRef.current) {
			dropdownRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}, [isOpen]);

	const colorClasses = {
		primary: {
			base: 'bg-primary-500',
			hover: 'hover:bg-primary-400',
			focus: 'focus:ring-primary-300',
			text: 'text-white',
		},
		success: {
			base: 'bg-green-500',
			hover: 'hover:bg-green-400',
			focus: 'focus:ring-green-300',
			text: 'text-white',
		},
		danger: {
			base: 'bg-red-500',
			hover: 'hover:bg-red-400',
			focus: 'focus:ring-red-300',
			text: 'text-white',
		},
		warning: {
			base: 'bg-yellow-500',
			hover: 'hover:bg-yellow-400',
			focus: 'focus:ring-yellow-300',
			text: 'text-white',
		},
		secondary: {
			base: 'bg-gray-500',
			hover: 'hover:bg-gray-400',
			focus: 'focus:ring-gray-300',
			text: 'text-white',
		},
		transparent: {
			base: 'bg-transparent border-2',
			hover: 'hover:bg-gray-100 hover:text-gray-800',
			focus: 'focus:ring-gray-300',
			text: 'text-gray-700',
		},
	};

	if (!colorClasses[color]) {
		color = 'primary';
	}

	const classes = colorClasses[color];

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className='relative inline-block text-left'>
			<button
				type={type}
				onClick={toggleDropdown}
				className={`${classes.text}  ${
					disabled || isLoading
						? `${classes.base} cursor-not-allowed opacity-50`
						: `${classes.base} ${classes.hover}`
				} focus:ring-4   ${classes.focus} 
        ${iconOnly ? 'pt-1 pb-0.5 px-2 text-2xl' : small ? 'text-xs px-3 py-2' : 'text-sm px-5 py-2.5'}
          font-medium rounded-lg focus:outline-none ${className}`}
				disabled={disabled || isLoading}
			>
				{isLoading ? (
					<i className='ri-loader-4-line animate-spin text-lg '></i>
				) : (
					<div className='flex justify-between'>
						<div>
							{icon && <i className={`mr-1 ${icon}`}></i>}
							{label}
						</div>

						<div className='pl-1 -mr-2'>
							<i className={` ${isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}`}></i>
						</div>
					</div>
				)}
			</button>
			{isOpen && (
				<div
					ref={dropdownRef}
					className='origin-top-right absolute left-1/2 transform -translate-x-1/2 mt-2 w-56 rounded-md shadow-lg bg-white bg-opacity-60 ring-1 ring-black ring-opacity-5'
				>
					<div className='pt-1' role='menu' aria-orientation='vertical' aria-labelledby='options-menu'>
						{children}
					</div>
				</div>
			)}
		</div>
	);
};

export default DropdownButton;
