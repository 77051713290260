import { useTranslations } from 'hooks';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';
import RadioElement from './internal/Radio';
import ViewInput from './internal/ViewInput';

const getLocationClass = (location) => {
	switch (location) {
		case 'start':
			return 'justify-start';
		case 'center':
			return 'justify-center';
		case 'end':
			return 'justify-end';
		default:
			return 'justify-start';
	}
};

const Radio = forwardRef(
	(
		{
			name = '',
			options = [],
			required = false,
			selected = null,
			defaultSelected = null,
			defaultValue = null,
			getFirstItemAsDefault = true,
			disabled = false,
			location = 'start',
			radioSize = 'text-xl',
			className = '',
			onChange = () => {},
			alignment = 'horizontal',
			title = null,
			isView = false,
			groupWidth = '',
		},
		ref,
	) => {
		const { translate } = useTranslations();
		const hasMounted = useRef(false);
		const [activeValue, setActiveValue] = useState(
			defaultSelected ? defaultSelected : getFirstItemAsDefault ? options[0]?.value : null,
		);

		useEffect(() => {
			if (!hasMounted.current && (defaultValue || defaultSelected)) {
				let value = defaultSelected || defaultValue;
				//if value is object then get the id of the object
				if (value && typeof value === 'object') {
					value = value.id || value.Id;
				}
				setActiveValue(value);

				hasMounted.current = true;
			}
		}, [defaultValue, defaultSelected]);

		const alignmentClass = alignment === 'horizontal' ? 'flex space-x-5' : 'flex-col -space-y-2';

		if (isView) {
			if (!options || options.length === 0) {
				return <ViewInput value={translate('noDataAvailable')} />;
			}
			return (
				<ViewInput
					value={translate(
						options
							.filter((option) =>
								selected !== null ? selected === option.value : activeValue === option.value,
							)
							.map((option) => option.label)[0],
					)}
				/>
			);
		}

		return (
			<div
				className={`  py-2 ${alignmentClass}  ${getLocationClass(location)} mr-5 ${groupWidth} overflow-y-auto`}
			>
				{options.map((option, index) => (
					<RadioElement
						required={required}
						key={index}
						className={className}
						disabled={disabled}
						name={name}
						value={option.value}
						selected={selected !== null ? selected === option.value : activeValue === option.value}
						label={option.label}
						radioSize={radioSize}
						onChange={(e) => {
							onChange(e);
							setActiveValue(option.value);
						}}
					/>
				))}
			</div>
		);
	},
);

Radio.propTypes = {
	className: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	radioSize: PropTypes.string,
	defaultSelected: PropTypes.any,
	name: PropTypes.string,
	location: PropTypes.oneOf(['start', 'center', 'end']),
	required: PropTypes.bool,
	selected: PropTypes.any,
	alignment: PropTypes.oneOf(['horizontal', 'vertical']),
	getFirstItemAsDefault: PropTypes.bool,
};

export default Radio;
