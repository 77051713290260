import { DescriptionColumn, IdColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';
class ExclusionModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'exclusion';
		this.pluralModelName = 'exclusions';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new NameColumn('name'));
		this.columns.push(new DescriptionColumn('description'));
		this.assignColumnOrder();
	}
	isExportable() {
		return false;
	}

	areSelectsVisible() {
		return false;
	}
}

export default ExclusionModel;
