import { useTranslations } from "hooks";
import toast from "react-hot-toast";

const SetupQrNfcLogin = ({ props }) => {
	const { translate } = useTranslations();

	const setupQr = async (id, code) => {
		const res = await props.service.setupQrLogin(id, code);
		if (res.succeeded) {
			props?.requestReload();
			toast("Successfully setup QR-code login", {
				duration: 2000,
				icon: "✅",
			});
		} else {
			toast(res.message || translate("somethingWentWrong"), {
				duration: 2000,
				icon: "❗️",
			});
		}
	};

	const setupNfc = async (id, code) => {
		const res = await props.service.setupNfcLogin(id, code);
		if (res.succeeded) {
			props?.requestReload();
			toast("Successfully setup NFC login", {
				duration: 2000,
				icon: "✅",
			});
		} else {
			toast(res.message || translate("somethingWentWrong"), {
				duration: 2000,
				icon: "❗️",
			});
		}
	};

	return {
		setupQr,
		setupNfc,
	};
};

export default SetupQrNfcLogin;
