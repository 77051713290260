import React from "react";
import ContentLoader from "react-content-loader";

const ITEMS = 2;

const NodeLoader = ({ level, rootLevel, ...props }) => {
	// const level = props.rootLevel - props.level;
	const position = 100 - 4 * level;

	return (
		<ContentLoader
			speed={2}
			width={`${position}%`}
			height={64 * ITEMS}
			backgroundColor="#e2e8f0"
			foregroundColor="#ecebeb"
			{...props}
		>
			{Array.from({ length: ITEMS }, (_, index) => (
				<rect
					key={`loading-${index}`}
					x="0"
					y={`${56 * index + 8 * index}`}
					rx="5"
					ry="5"
					width="100%"
					height="56"
				/>
			))}
		</ContentLoader>
	);
};

export default NodeLoader;
