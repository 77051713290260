import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';

const AssetsPermissions = () => {
	const { hasPermission } = usePermissions();
	return {
		assets: {
			assets: {
				Create: hasPermission(RESOURCE.Asset, PERMISSION.Create),
				View: hasPermission(RESOURCE.Asset, PERMISSION.View),
				Update: hasPermission(RESOURCE.Asset, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Asset, PERMISSION.Delete),
				Import: hasPermission(RESOURCE.Asset, PERMISSION.Import),
				Export: hasPermission(RESOURCE.Asset, PERMISSION.Export),
			},
		},
		settings: {
			General: {
				Category: {
					Create: hasPermission(RESOURCE.Asset, PERMISSION.Create),
					View: hasPermission(RESOURCE.Asset, PERMISSION.View),
					Update: hasPermission(RESOURCE.Asset, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Asset, PERMISSION.Delete),
				},
			},
		},
	};
};
export default AssetsPermissions;
