import { BadgedListColumn, EnumLabelColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class UserProfileModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'userProfile';
		this.pluralModelName = 'userProfiles';
	}

	initializeColumns() {
		this.columns.push(
			new NameColumn({
				label: 'userProfile',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(new BadgedListColumn('departments'));
		this.columns.push(
			new BadgedListColumn('permissionGroups', {
				renderProps: {
					itemClassName: 'bg-yellow-100 text-yellow-800',
				},
			}),
		);
		this.columns.push(
			new EnumLabelColumn('viewPatientsLevel', { label: 'showPatients', enumKey: 'ViewPatientsLevel' }),
		);
		this.columns.push(
			new BadgedListColumn('taskGroups', { renderProps: { itemClassName: 'bg-green-100 text-green-800' } }),
		);

		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}

export default UserProfileModel;
