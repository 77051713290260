import { BadgedListColumn, NameColumn, SimpleObjectColumn } from 'core/columns';
import { StringColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
import CustomFieldValues from '../components/CustomFieldValues';

class CustomFieldModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'customField';
		this.pluralModelName = 'customFields';
	}

	initializeColumns() {
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(new SimpleObjectColumn('inputType'));
		this.columns.push(
			new StringColumn('value', {
				label: 'fieldValue',
				render: (value) => <CustomFieldValues jsonValue={value} />,
			}),
		);
		this.columns.push(
			new BadgedListColumn('taskGroups', {
				label: 'availableForTaskGroups',
				renderProps: { itemClassName: 'bg-green-100 text-green-800' },
			}),
		);
		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}

export default CustomFieldModel;
