import AbstractService from "services/Service";
const endpoint = "users/permission-groups";
const permissionEndpoint = "users/permissions";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	getAllPermissions = async () => {
		return await this.api.get(`${permissionEndpoint}`);
	};

	mapData = (data) => {
		const { id, name, description, ...rest } = data;
		let permissions = {};
		Object.keys(rest).map((key) => {
			if (key !== "name" && key !== "description") {
				let permissionSplit = key.split("__");
				let permissionKey = permissionSplit[0];
				let permissionValue = permissionSplit[1];
				if (!permissions[permissionKey])
					permissions[permissionKey] = [];
				if (rest[key] === "true")
					permissions[permissionKey].push(parseInt(permissionValue));
			}
		});
		return {
			name,
			description,
			permissions,
		};
	};
}

export default Service;
