import api from 'services/api';
import { extractMeta } from 'utils/pagination';

const endpoint = 'addresses';

export const LEVELS = ['groups', 'campuses', 'sites', 'zones', 'rooms', 'beds'];

// ==========================================
// GET Tree
// ==========================================
export const loadTreeRoot = async (rootLevel = 6) => {
	const res = await api.get(`${endpoint}/tree?level=${rootLevel - 1}&pageSize=200`);

	return res.data
		.sort((a, b) => a.id - b.id)
		.map((group) => ({
			...group,
			children:
				group.children?.map((child) => ({
					...child,
					children: undefined,
				})) || undefined,
		}));
};

export const loadTreeChildren = async (id, level) => {
	return await api.get(`${endpoint}/tree/${id}/${level}`);
};

// TODO: DEPRECATE in favor of loadTreeChildren
const loadLevelChildren = async (level, id, perPage = 200, page = 1) => {
	if (level > LEVELS.length - 1) throw Error('Unknown level requested');

	let levelLoadUrl = '';
	if (level === -1) {
		levelLoadUrl = 'groups';
	} else {
		levelLoadUrl = `${LEVELS[level]}/${id}/${LEVELS[level + 1]}`;
	}

	const query = `?PageSize=${perPage}&page=${page}`;
	const result = await api.get(`${endpoint}/${levelLoadUrl}${query}`);
	if (!result.succeeded) throw Error(result.message);

	const data = result.data;
	delete result.data;
	const meta = result;

	return { data, meta };
};

export const loadChildren = loadLevelChildren;

// ==========================================
// GET [all]
// ==========================================
export const getAddressesForTable = async (level, queryString = 'Page=1&PageSize=20') => {
	const addr = await api.get(`${endpoint}/table?level=${level}&${queryString}`);
	return {
		data: addr.data,
		meta: extractMeta(addr, queryString),
	};
};

// ==========================================
// GET [:id]
// ==========================================
const loadSingleData = async (level, id) => {
	return api.get(`${endpoint}/${level}/${id}`);
};

export const loadSingle = {
	group: async (id) => loadSingleData('groups', id),
	campus: async (id) => loadSingleData('campuses', id),
	site: async (id) => loadSingleData('sites', id),
	zone: async (id) => loadSingleData('zones', id),
	room: async (id) => loadSingleData('rooms', id),
	bed: async (id) => loadSingleData('beds', id),
	6: async (id) => loadSingleData('groups', id),
	5: async (id) => loadSingleData('campuses', id),
	4: async (id) => loadSingleData('sites', id),
	3: async (id) => loadSingleData('zones', id),
	2: async (id) => loadSingleData('rooms', id),
	1: async (id) => loadSingleData('beds', id),
};

// ==========================================
// CREATE
// ==========================================
const createLevel = async (level, data) => {
	if (LEVELS.indexOf(level) < 0) throw Error('Unknown level requested');
	return api.post(`${endpoint}/${level}`, data);
};

export const create = {
	group: async (parent_id, data) => createLevel('groups', data),
	campus: async (parentId, data) => createLevel('campuses', { ...data, parentId }),
	site: async (parentId, data) => createLevel('sites', { ...data, parentId }),
	zone: async (parentId, data) => createLevel('zones', { ...data, parentId }),
	room: async (parentId, data) => createLevel('rooms', { ...data, parentId }),
	bed: async (parentId, data) => createLevel('beds', { ...data, parentId }),
	6: async (parent_id, data) => createLevel('groups', data),
	5: async (parentId, data) => createLevel('campuses', { ...data, parentId }),
	4: async (parentId, data) => createLevel('sites', { ...data, parentId }),
	3: async (parentId, data) => createLevel('zones', { ...data, parentId }),
	2: async (parentId, data) => createLevel('rooms', { ...data, parentId }),
	1: async (parentId, data) => createLevel('beds', { ...data, parentId }),
};

// ==========================================
// EDIT
// ==========================================
const editLevel = async (level, id, data) => {
	return api.put(`${endpoint}/${level}/${id}`, data);
};

export const edit = {
	6: async (id, data) => editLevel('groups', id, data),
	5: async (id, data) => editLevel('campuses', id, data),
	4: async (id, data) => editLevel('sites', id, data),
	3: async (id, data) => editLevel('zones', id, data),
	2: async (id, data) => editLevel('rooms', id, data),
	1: async (id, data) => editLevel('beds', id, data),
};

// ==========================================
// DELETE
// ==========================================
const deleteLevel = async (level, id) => {
	if (LEVELS.indexOf(level) < 0) throw Error('Unknown level requested');
	return api.delete(`${endpoint}/${level}/${id}`);
};

export const deleteAddress = {
	group: async (id) => deleteLevel('groups', id),
	campus: async (id) => deleteLevel('campuses', id),
	site: async (id) => deleteLevel('sites', id),
	zone: async (id) => deleteLevel('zones', id),
	room: async (id) => deleteLevel('rooms', id),
	bed: async (id) => deleteLevel('beds', id),
	6: async (id) => deleteLevel('groups', id),
	5: async (id) => deleteLevel('campuses', id),
	4: async (id) => deleteLevel('sites', id),
	3: async (id) => deleteLevel('zones', id),
	2: async (id) => deleteLevel('rooms', id),
	1: async (id) => deleteLevel('beds', id),
};

// ==========================================
// DISTANCE MATRIX
// ==========================================
export const loadDistances = async (id, level) => {
	return await api.get(`${endpoint}/distances/${id}/${level}`);
};

export const updateDistances = async (id, level, distances) => {
	return api.post(`${endpoint}/distances/${id}/${level}`, { distances });
};

// ==========================================
// UTILS / OTHER HELPERS
// ==========================================
export const getRoomTypes = async (HideAllItem = true) => {
	return await api.get(`${endpoint}/room-types/options-list?HideAllItem=${HideAllItem}`);
	// return runAction("tenants", "getEnum", "RoomType");
};

export const getExport = async (queryString = null) => {
	return await api.get(`${endpoint}/export` + (queryString ? `?${queryString}` : ''), { responseType: 'blob' });
};

export const postImport = async (file) => {
	const form = new FormData();
	form.append('file', file);
	return await api.postForm(`${endpoint}/import`, form);
};

export const search = async (queryString = null, term) => {
	let params = new URLSearchParams(queryString);
	if (term) params.set('Name', term);

	const result = await api.get(`${endpoint}/search?${params.toString()}`);
	return {
		data: result?.data,
		meta: extractMeta(result, params.toString()),
	};
};

export const getPath = async (id, level) => {
	return await api.get(`${endpoint}/path/${id}/${level}`);
};

const services = {
	loadChildren,
	loadSingle,
	create,
	deleteAddress,
	LEVELS,
	search,
	getPath,
};

export default services;
