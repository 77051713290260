import { withSuspense } from "hoc";
import TaskTypeTable from "./parts/TaskTypeTable";

const TaskTypesPage = ({ taskGroupId, permissions }) => {
	return (
		<div className="grid grid-cols-1 gap-2">
			{permissions.TaskTypes.View && (
				<TaskTypeTable
					permissions={permissions.TaskTypes}
					taskGroupId={taskGroupId}
				/>
			)}
		</div>
	);
};
export default withSuspense(TaskTypesPage);
