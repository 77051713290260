import { withSuspense } from 'hoc';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useToolbar } from 'hooks';
import { useOutletContext } from 'react-router-dom';
import TaskTableRefreshContext from './Contexts/index';
import OnlineEmployees from './pages/Employee/parts/EmployeeTable';
import TasksTable from './pages/Tasks/parts/TasksTable';
import TeamsTable from './pages/Teams/parts/TeamTable';

const TasksPage = () => {
	const { crumbsBuilder } = useToolbar();
	const [taskControls, setTaskControls] = useState({});
	const onlineEmployeesRef = useRef(null);
	const teamsRef = useRef(null);

	const { permissionsMap } = useOutletContext();

	useEffect(() => {
		crumbsBuilder.addCurrent('tasks');
		crumbsBuilder.build();
	}, []);

	const refreshHandler = useCallback(() => {
		onlineEmployeesRef.current?.refresh();
		teamsRef.current?.refresh();
	}, []);

	return (
		<TaskTableRefreshContext.Provider value={refreshHandler}>
			<div className='grid grid-cols-1 gap-2'>
				{permissionsMap.tasks?.View && (
					<TasksTable
						permissions={permissionsMap.tasks}
						onControlUpdate={(controls) => {
							setTaskControls(controls);
						}}
						onRefresh={refreshHandler}
					/>
				)}
				{permissionsMap.employees?.View && (
					<OnlineEmployees ref={onlineEmployeesRef} permissions={permissionsMap.employees} />
				)}
				{permissionsMap.teams?.View && (
					<TeamsTable ref={teamsRef} permissions={permissionsMap.teams} controls={taskControls} />
				)}
			</div>
		</TaskTableRefreshContext.Provider>
	);
};

export default withSuspense(TasksPage);
