import AbstractService from "services/Service";
const endpoint = "users/active-directory/config";
const ADEndpoint = "users/active-directory";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	test = async () => {
		return await this.api.get(`${ADEndpoint}/test`);
	};

	mapData = (data) => {
		return {
			...data,
			isSyncEnabled: data?.isSyncEnabled === "true",
			port: parseInt(data?.port) ?? null,
			runIntervalInMinutes: parseInt(data?.runIntervalInMinutes) ?? null,
		};
	};
}

export default Service;
