import { AddressRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class AddressColumn extends StringColumn {
	constructor(name, options = {}) {
		super(name, options);
		this.key = options.key || name;
		this.noDataValue = 'N/A';
		this.displayBellowLevel = options.displayBellowLevel || 2;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.render = (value) => {
			if (!value) return this.noDataValue;
			return <AddressRender value={value} belowLevel={this.displayBellowLevel} />;
		};

		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default AddressColumn;
