import { forwardRef, useEffect, useState } from "react";

import { Loading } from "components/ui/Interactive";
import { Each } from "utils/Each";

import { Link } from "react-router-dom";
import { useAside, useTranslations } from "hooks";

import CustomFieldService from "modules/tasks/pages/Settings/pages/CustomFields/services";

const CustomField = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	const [customFields, setCustomFields] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const customFieldService = new CustomFieldService();
	const closeAside = () => {
		asideBuilder.reset();
		asideBuilder.setOpen(false);
	};

	useEffect(() => {
		setIsLoading(true);
		customFieldService
			.getAll(
				customFieldService.queryString +
					"&taskGroupIds=" +
					props.taskGroupId
			)
			.then((res) => {
				setCustomFields(res.data);
				setIsLoading(false);
			});
	}, [props.taskGroupId]);

	const chooseField = (field) => {
		if (props?.chooseField) {
			props?.chooseField(field);
		}
	};

	const getIcon = (inputName) => {
		let path;
		let filename = inputName + ".png";
		try {
			path = require(`../../../../../../Settings/pages/CustomFields/assets/InputIcons/${filename}`);
		} catch (error) {
			path = require(`../../../../../../Settings/pages/CustomFields/assets/InputIcons/Other.png`);
		}
		return <img src={path} alt={inputName} className="h-16 w-16 mr-2" />;
	};

	const Field = ({ field, active = false, onClick = () => {} }) => {
		return (
			<div className={`hover:bg-gray-50 rounded-md`} onClick={onClick}>
				<div
					className={`flex items-center  space-x-2 cursor-pointer opacity-90 pt-3 pl-2  hover:opacity-100 } `}
				>
					{getIcon(field?.inputType?.name)}
					<div className="flex flex-col justify-between">
						<span className="text-xs text-gray-500">
							{field?.inputType?.name}
						</span>
						<span className="text-gray-700 font-semibold">
							{field.name}
						</span>
					</div>
				</div>
				<div className=" border-gray-200 border-b w-full  mt-3" />
			</div>
		);
	};

	if (isLoading || !customFields) {
		return <Loading status={isLoading} />;
	}

	return (
		<div className="w-full h-100 pb-10 overflow-y-visible">
			<div className={`font-bold my-2 text-sm `}>
				{translate("configurationFields")}
			</div>
			<div className="pt-1 pb-2 text-xs">
				{translate("youCanCreateOtherCustomFieldsIn")}
				<Link
					onClick={() => closeAside()}
					className="text-cyan-500 font-medium"
					to="/tasks/settings#custom-fields"
				>
					{" "}
					{translate("settings")}
				</Link>
			</div>

			<Each
				of={customFields}
				render={(field) => (
					<div>
						<Field
							key={field.id}
							onClick={() => {
								chooseField(field);
							}}
							field={field}
						/>
					</div>
				)}
			/>
		</div>
	);
});

export default CustomField;
