import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslations } from "hooks";

const Breadcrumbs = ({ items }) => {
	const { translate } = useTranslations();
	return (
		<ol className="inline-flex items-center space-x-1 md:space-x-3">
			{items.map((item) => {
				if (item.component) {
					return (
						<li
							key={`bc-${item.label}`}
							className="inline-flex items-center"
						>
							{item.component}
						</li>
					);
				}
				return (
					<li
						key={`bcc-${item.label}`}
						className="inline-flex items-center"
					>
						{item.current ? (
							<div className="flex items-center">
								{items.length > 1 && (
									<i className="ri-arrow-right-s-line"></i>
								)}
								<span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 ">
									{translate(item.label, true)}
								</span>
							</div>
						) : (
							<Link
								to={item.to}
								className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600  "
							>
								{translate(item.label, true)}
							</Link>
						)}
					</li>
				);
			})}
		</ol>
	);
};

const itemShape = PropTypes.shape({
	label: PropTypes.string.isRequired, // Required string
	to: PropTypes.string, // Optional string
	component: PropTypes.elementType, // Required React component
	current: PropTypes.bool,
});

Breadcrumbs.propTypes = {
	items: PropTypes.arrayOf(itemShape),
};

export default Breadcrumbs;
