import { withDashboardLayout } from "../../hoc";
import { Outlet } from "react-router-dom";

const MainModule = () => {
	return <Outlet />;
};

const config = {
	page: "main",
};

export default withDashboardLayout(MainModule, config);
