import axios from "axios";
import config from "./config";
import tenant from "config/tenant";

import { runAction } from "modules/utils"; // TODO: Check for circular dependency problems

import TokenService from "../token/token.service";

const api = axios.create({
	baseURL: config.baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {
		const token = TokenService.getLocalAccessToken();
		if (token) {
			if (!config.headers["Authorization"])
				config.headers["Authorization"] = "Bearer " + token;
		} else {
			config.headers["TenantId"] = tenant.id;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => {
		return response;
	},
	async (err) => {
		const originalConfig = err.config;
		const originalUrl = originalConfig.url.replace(/^\//, "");
		if (originalUrl === config.refreshURL) {
			console.log("Refresh response intercept", err);
		}

		if (originalUrl !== config.loginURL && err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;

				try {
					const req = {
						method: "post",
						url: `/${config.refreshURL}`,
						headers: {
							TenantId: tenant.id,
							"Content-Type": "application/json",
						},
						data: JSON.stringify({
							token: TokenService.getLocalAccessToken(),
							refreshToken: TokenService.getLocalRefreshToken(),
						}),
					};

					const rs = await api.request(req);
					if (!rs.data?.succeeded) throw new Error(rs.data?.message);

					const { token, refreshToken, expireOn } = rs.data;
					TokenService.updateLocalAccessToken(
						token,
						refreshToken,
						expireOn
					);

					originalConfig.headers["Authorization"] = "Bearer " + token;
					return api(originalConfig);
				} catch (_error) {
					console.log("On Refresh Error", _error);
					TokenService.removeUser();
					window.dispatchEvent(new Event("login"));
					return Promise.resolve();
				}
			} else if (err.response.status === 401 && originalConfig._retry) {
				TokenService.removeUser();
				window.dispatchEvent(new Event("login"));
			}
		}
		return Promise.reject(err);
	}
);

export default api;
