import { AddressColumn, IdColumn, SimpleObjectColumn } from 'core/columns';
import { DateColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
import ScheduledDepartureRender from '../../../../../components/ModelRenders/ScheduleDepartureRender';

class ReturnTaskModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'returnTask';
		this.pluralModelName = 'returnTasks';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new AddressColumn('from'));
		this.columns.push(new AddressColumn('to'));
		this.columns.push(
			new DateColumn('scheduledTime', {
				label: 'scheduled',
				render: (value, row) => <ScheduledDepartureRender value={value} row={row} />,
			}),
		);
		this.columns.push(new SimpleObjectColumn('taskType', 'name'));
		this.assignColumnOrder();
	}

	isModifiable() {
		return false;
	}
	isExportable() {
		return false;
	}
	isFilterable() {
		return false;
	}
}
export default ReturnTaskModel;
