/**
 * Utilities file to get tailwind resources in javascript.
 *
 * Usage:
 *
 * import themeConfig from 'utils/themeConfig'
 *
 * themeConfig.color.primary
 */

const themeConfig = {
	color: {
		primary_50: "#E8EFFF",
		primary_100: "#cce3f4",
		primary_200: "#99c8e9",
		primary_300: "#66acdf",
		primary_400: "#3391d4",
		primary_500: "#0075c9",
		primary_600: "#005ea1",
		primary_700: "#004679",
		primary_800: "#002f50",
		primary_900: "#001728",
		primary: "#0075c9",
	},
};

export default themeConfig;
