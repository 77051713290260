import { useTranslations } from 'hooks';

const FormEntry = ({
	label,
	children,
	inline = false,
	inputClass = '',
	helpText = <></>,
	longLabel = false,
	isRequired = null,
	className = '',
}) => {
	const { required: childRequired = false, isView = false } = children.props || {};

	const required = isView ? false : isRequired || childRequired || false;

	const { translate } = useTranslations();
	return (
		<>
			<tr className=''>
				<td
					className={`pt-3 pr-5  ${
						longLabel ? 'w-[50%]' : 'w-[25%]'
					} ${className} font-light align-top text-sm`}
				>
					{translate(label, true)}
					{required && <span className='inline-block text-red-600 font-bold pl-1'> *</span>}
				</td>
				<td className={`w-full ${inline ? 'flex flex-row items-center justify-stretch' : ''} ${inputClass}`}>
					{children}
				</td>
			</tr>
			{helpText && (
				<tr>
					<td className={`${longLabel ? 'w-[50%]' : 'w-[25%]'}`}></td>
					<td>
						<div className='text-sm text-slate-400'>{translate(helpText || '', true)}</div>
					</td>
				</tr>
			)}
		</>
	);
};

export default FormEntry;
