import { forwardRef, useEffect, useState } from "react";
import { useAside, useTranslations } from "hooks";
import { Each } from "utils/Each";
import { FormEntry } from "components/form";
import InputField from "modules/tasks/components/InputField";
import FieldHelper from "../utils/FieldHelper";

const PreviewForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (props?.helperIsOpen) {
			props?.helperIsOpen(props?.componentOpen || false);
		}
	}, [props.componentOpen]);

	useEffect(() => {
		setFields(props?.fields);
	}, [props?.fields]);

	return (
		<div className="w-full h-100 pb-10 overflow-y-visible">
			<div className="w-full border-b border-gray-200 " />
			<div className="py-5 text-xs opacity-60 ">
				<i className="ri-information-line text-sm pr-1"></i>
				<span>
					{translate(
						"dataChangesInPreviewWillHaveEffectOnceTaskTemplateIsSaved"
					)}
				</span>
			</div>
			<Each
				of={fields}
				render={(field) => {
					if (field?.isHidden) return null;
					return (
						<FormEntry
							label={field.name}
							required={field.isRequired}
						>
							<InputField
								// name={field.name}
								inputType={field.inputType}
								defaultData={field.defaultValue}
								options={
									field?.values
										? JSON.parse(field?.values)
										: []
								}
								isRequired={field.isRequired}
								isDisabled={field.isDisabled}
								parentId={props.taskGroupId}
							/>
						</FormEntry>
					);
				}}
			/>
		</div>
	);
});

export default PreviewForm;
