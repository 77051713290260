const IconButton = ({
	isLoading = false,
	onClick = () => {},
	ariaLabel = '',
	iconClass = '',
	className = 'opacity-60 hover:opacity-100',
}) => {
	return (
		<button
			type='button'
			onClick={onClick}
			className={` flex items-center pr-2  cursor-pointer ${className} ${isLoading && 'opacity-5'}`}
			aria-label={ariaLabel}
		>
			<i class={iconClass}></i>
		</button>
	);
};
export default IconButton;
