function fetchClient(url, options) {
	return fetch(url, options).then((response) => {
		if (!response.ok) {
			throw new Error(response.statusText);
		}
		return response.json();
	});
}

export default fetchClient;
