import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { Alert } from 'components/ui/Info';
import { Button, FileInput, ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates//pages/TaskTemplates/services';
import TaskGroupService from '../services';

const allowedTypes = [
	'.csv',
	'text/csv',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
];

const ImportForm = forwardRef(({ activeTaskGroup, ...props }, ref) => {
	const { translate } = useTranslations();
	const service = new TaskGroupService();
	const taskTemplateService = new TaskTemplateService();
	taskTemplateService.setParent(activeTaskGroup);

	const [importFile, setImportFile] = useState(null);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [taskTemplateIsLoading, setTaskTemplateIsLoading] = useState(false);
	const [activeTaskTemplate, setActiveTaskTemplate] = useState(null);

	const inputRef = useRef(null);

	useImperativeHandle(ref, () => ({
		getData: () => null,
		clear: () => null,
		runSubmit: () => null,
	}));

	const handleImport = useCallback(() => {
		console.log('Initiating import');
		if (importFile) {
			// TODO: File validation check
			props.onImportStart(importFile);
		}
	}, [importFile, props]);

	const onFileAdded = useCallback((e) => {
		setError(false);
		const file = e.target.files[0];
		if (allowedTypes.indexOf(file.type) < 0) {
			setError(true);
		} else {
			setImportFile(e.target.files[0]);
		}
	}, []);

	const getTaskGroupImportTemplate = async () => {
		setIsLoading(true);
		const res = await service.getExcelTemplate(activeTaskGroup);
		const exportData = res;

		const blob = new Blob([exportData]);
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = `taskgroup-${activeTaskGroup}-${Date.now()}.xlsx`;
		link.click();

		URL.revokeObjectURL(url);
		setIsLoading(false);
		return res;
	};

	const getTaskTemplateImportTemplate = async () => {
		setTaskTemplateIsLoading(true);
		const res = await taskTemplateService.getExcelTemplate(activeTaskTemplate);
		const exportData = res;

		const blob = new Blob([exportData]);
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = `tasktemplate-${activeTaskTemplate}-${Date.now()}.xlsx`;
		link.click();

		URL.revokeObjectURL(url);
		setTaskTemplateIsLoading(false);
		return res;
	};

	return (
		<>
			{' '}
			<div className='w-full h-100 pb-40 overflow-y-auto'>
				<div className='text-slate-500 '>{translate('thisFormWillImportAListOfTaskItemsInTheSystem')}</div>
				{isLoading && (
					<div className='w-full h-full flex items-center justify-center py-5'>
						<div className='animate-spin rounded-full h-8 w-8 border-b-2 border-primary-500'></div>
					</div>
				)}

				{!isLoading && (
					<div
						onClick={() => getTaskGroupImportTemplate()}
						className='w-full my-2 p-2  py-3 text-sm cursor-pointer text-primary-500 hover:font-medium border-slate-200 hover:text-primary-500'
					>
						<i class='ri-file-download-line text-lg'></i>
						&nbsp;&nbsp;&nbsp;
						{translate('getImportTemplateForThisTaskGroup')}
					</div>
				)}

				<hr className='border-slate-200 my-5' />
				<div className='text-slate-500'>
					{translate('OrYouCanSelectATaskTemplateForWhichYouWantToHaveTheImportFile')}
				</div>
				<div className='py-3'>
					<ReSelect
						label={translate('taskTemplate')}
						extraParams='HideAllItem=true'
						name='taskGroupId'
						service={taskTemplateService}
						onChange={(selected) => {
							setActiveTaskTemplate(selected);
						}}
					/>
				</div>
				{taskTemplateIsLoading && (
					<div className='w-full h-full flex items-center justify-center py-5'>
						<div className='animate-spin rounded-full h-8 w-8 border-b-2 border-primary-500'></div>
					</div>
				)}

				{!taskTemplateIsLoading && (
					<div>
						{activeTaskTemplate ? (
							<div
								onClick={() => getTaskTemplateImportTemplate()}
								className='w-full mb-8 text-sm cursor-pointer text-primary-500 hover:font-medium border-slate-200 hover:text-primary-500'
							>
								<i class='ri-file-download-line text-lg'></i>
								&nbsp;&nbsp;&nbsp;
								{translate('getImportTemplateForThisTaskTemplate')}
							</div>
						) : (
							<div>
								<div className='text-slate-400 mb-8 text-sm font-semibold'>
									{translate('pleaseSelectATaskTemplateToDownloadTheImportTemplate')}
								</div>
							</div>
						)}
					</div>
				)}

				<hr className='border-slate-200 my-8' />

				<div className='text-slate-500 mb-3 text-sm'>
					{translate(
						'pleaseUseTheProvidedFileAsTheImportTemplateUsingAnotherFileMayLeadToProblemsWhileImporting',
					)}
				</div>
				<FileInput ref={inputRef} accept={allowedTypes.join(',')} onChange={onFileAdded} />
				{error && (
					<Alert title={translate('invalidFile')} type={'error'}>
						{translate('pleaseChooseAValidFileOrUseTheProvidedTemplate')}
					</Alert>
				)}
				<div className='flex flex-row justify-end mt-5'>
					<Button onClick={handleImport} disabled={!importFile || error}>
						{translate('import')}
					</Button>
				</div>
			</div>
		</>
	);
});

export default ImportForm;
