const InputTypes = {
	Label: 1,
	Text: 2,
	TextArea: 3,
	Number: 4,
	Date: 5,
	DateTime: 6,
	Time: 7,
	Check: 8,
	CheckGroup: 9,
	RadioGroup: 10,
	List: 11,
	MultiSelectList: 12,
	Status: 13,
	Urgency: 14,
	TaskType: 15,
	Address: 16,
	Patient: 17,
	Asset: 18,
	Documentation: 19,
	ScheduledTimeType: 20,
};
const components = [
	{
		id: InputTypes.Label,
		value: {
			component: 'Label',
			type: null,
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.Text,
		value: {
			component: 'Input',
			type: 'text',
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.TextArea,
		value: {
			component: 'TextArea',
			type: null,
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.Number,
		value: {
			component: 'Input',
			type: 'number',
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.Date,
		value: {
			component: 'Input',
			type: 'date',
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.DateTime,
		value: {
			component: 'DateTimeInput',
			type: null,
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.Time,
		value: {
			component: 'Input',
			type: 'time',
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.Check,
		value: {
			component: 'Checkbox',
			type: null,
			isMultiple: false,
			extraParams: {},
		},
	},
	{
		id: InputTypes.CheckGroup,
		value: {
			component: 'CheckGroup',
			type: null,
			isMultiple: true,
			extraParams: {},
		},
	},
	{
		id: InputTypes.RadioGroup,
		value: {
			component: 'Radio',
			type: null,
			isMultiple: false,
			extraParams: {
				groupWidth: 'w-96',
			},
		},
	},
	{
		id: InputTypes.List,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			extraParams: {},
			onChangeObject: true,
		},
	},
	{
		id: InputTypes.MultiSelectList,
		value: {
			component: 'SearchSelect',
			type: null,
			isMultiple: true,
			extraParams: {
				multiple: true,
			},
		},
	},
	{
		id: InputTypes.Status,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			extraParams: {},
			onChangeObject: true,
		},
	},
	{
		id: InputTypes.Urgency,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			serviceName: 'UrgenciesService',
			extraParams: {},
			onChangeObject: true,
		},
	},
	{
		id: InputTypes.TaskType,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			serviceName: 'TaskTypeService',
			extraParams: {},
		},
	},
	{
		id: InputTypes.Address,
		value: {
			component: 'Address',
			type: null,
			isMultiple: false,
			extraParams: {
				initiallySelectDisabled: true,
				noHasMounted: true,
			},
		},
	},
	{
		id: InputTypes.Patient,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			serviceName: 'PatientsService',
			extraParams: {},
			onChangeObject: true,
		},
	},
	{
		id: InputTypes.Asset,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			serviceName: 'AssetsService',
			extraParams: {},
			onChangeObject: true,
		},
	},
	{
		id: InputTypes.Documentation,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			serviceName: 'DocumentationService',
			extraParams: {},
			onChangeObject: true,
		},
	},
	{
		id: InputTypes.ScheduledTimeType,
		value: {
			component: 'ReSelect',
			type: null,
			isMultiple: false,
			extraParams: {},
			onChangeObject: true,
		},
	},
];
export default components;
