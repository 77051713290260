import { useTranslations } from 'hooks';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
/**
 * @param {Object} service - The service object.
 * @param {function} service.create - The function to create a new item.
 * @param {function} service.update - The function to update an item.
 * @param {function} service.getOne - The function to get a single item.
 * @param {function} service.getExport - The function to export items.
 * @param {function} service.getAll - The function to get all items.
 * @param {function} service.remove - The function to remove an item.
 * @returns {Object} - The CRUD functions.
 */
const useCrud = (service) => {
	const { translate } = useTranslations();
	const global_dictionary = {
		creationSuccessMessage: 'itemCreatedSuccessfully',
		updateSuccessMessage: 'itemUpdatedSuccessfully',
		removeSuccessMessage: 'itemRemovedSuccessfully',
		removePromptTitle: 'areYouSure',
		removePromptMessage: 'youAreAboutToDeleteThis',
		removePromptMessageNoTitle: 'youAreAboutToDeleteSelectedItem',
		removePromptConfirm: 'yesDeleteIt',
		removePromptConfirmHard: 'yesDeleteItAnyway',
		removePromptCancel: 'cancel',
		errorMessage: 'somethingWentWrong',
		hardCreateTitle: 'duplicateEntity',
		hardCreateMessage: 'thisItemAlreadyExists',
		hardCreateButtonText: 'createAnyWay',
		hardUpdateTitle: 'duplicateEntity',
		hardUpdateMessage: 'thereIsAnExistentItemWithThisPayload',
		hardUpdateButtonText: 'updateAnyWay',
	};

	const create = async (data, dictionary = null, hardCreateLabel = null, hardCreateVariable = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.create) {
			console.error('Error on Create request: create method not found in service');
			throw new Error('create method not found in service');
		}

		const createResp = await doCreate(data, dictionary, hardCreateLabel);
		if (createResp?.actionError) {
			const result = await Swal.fire({
				title: translate(dictionary.hardCreateTitle),
				text: translate(dictionary.hardCreateMessage),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: translate(dictionary.hardCreateButtonText),
				cancelButtonText: translate(dictionary.removePromptCancel),
				confirmButtonColor: '#EF4444',
				cancelButtonColor: '#b5bec9',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						await doCreate({ ...data, [hardCreateVariable]: true }, dictionary, hardCreateLabel);
					} catch (error) {
						Swal.hideLoading();
						Swal.showValidationMessage(translate(error.message) || translate(dictionary.errorMessage));
						console.error('Error on Create request: ', error);
						throw error;
					}
				},
			});
			return result;
		}
		return createResp;
	};

	const doCreate = async (data, dictionary, actionErrorLabel) => {
		try {
			const res = await service.create(data);
			if (res?.succeeded) {
				toast(translate(dictionary.creationSuccessMessage), {
					duration: 2000,
					icon: '✅',
				});
				return res;
			} else {
				if (res?.message === actionErrorLabel) {
					return { actionError: true };
				}
				toast(translate(res?.message || dictionary.errorMessage), {
					duration: 2000,
					icon: '❗️',
				});
				throw new Error(res?.message || dictionary.errorMessage);
			}
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Create request: ', error);
			throw error;
		}
	};

	const update = async (id, data, dictionary = null, hardCreateLabel = null, hardCreateVariable = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.update) {
			console.error('Error on Update request: update method not found in service');
			throw new Error('update method not found in service');
		}

		const updateResp = await doUpdate(id, data, dictionary, hardCreateLabel);
		if (updateResp?.actionError) {
			const result = await Swal.fire({
				title: translate(dictionary.hardUpdateTitle),
				text: translate(dictionary.hardUpdateMessage),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: translate(dictionary.hardUpdateButtonText),
				cancelButtonText: translate(dictionary.removePromptCancel),
				confirmButtonColor: '#EF4444',
				cancelButtonColor: '#b5bec9',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						await doUpdate(id, { ...data, [hardCreateVariable]: true }, dictionary, hardCreateLabel);
					} catch (error) {
						Swal.hideLoading();
						Swal.showValidationMessage(translate(error.message) || translate(dictionary.errorMessage));
						console.error('Error on Update request: ', error);
						throw error;
					}
				},
			});
			return result;
		}
		return updateResp;
	};

	const doUpdate = async (id, data, dictionary, actionErrorLabel) => {
		try {
			const res = await service.update(id, data);
			if (res?.succeeded) {
				toast(translate(dictionary.updateSuccessMessage), {
					duration: 2000,
					icon: '✅',
				});
				return res;
			} else {
				if (res?.message === actionErrorLabel) {
					return { actionError: true };
				}
				toast(translate(res?.message || dictionary.errorMessage), {
					duration: 2000,
					icon: '❗️',
				});
				throw new Error(res?.message || dictionary.errorMessage);
			}
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Update request: ', error);
			throw error;
		}
	};

	const getAll = async (queryString = '', dictionary = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.getAll) {
			console.error('Error on Get All request: getAll method not found in service');
			throw new Error('getAll method not found in service');
		}

		try {
			const res = await service.getAll(queryString);
			return res;
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Get All request: ', error);
			throw error;
		}
	};

	const search = async (queryString = '', searchParams = {}, dictionary = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.search) {
			console.error('Error on Search request: search method not found in service');
			throw new Error('search method not found in service');
		}

		try {
			const res = await service.search(queryString, searchParams);
			return res;
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Search request: ', error);
			throw error;
		}
	};

	const getOne = async (id = null, dictionary = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.getOne) {
			console.error('Error on Get One request: getOne method not found in service');
			throw new Error('getOne method not found in service');
		}

		try {
			const res = await service.getOne(id);
			if (res?.succeeded) {
				return res.data;
			} else {
				toast(translate(res?.message || dictionary.errorMessage), {
					duration: 2000,
					icon: '❗️',
				});
			}
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Get One request: ', error);
			throw error;
		}
	};

	const remove = async (id, withPrompt = true, title = null, dictionary = null, twoStepDeleteMessage = null) => {
		dictionary = getDictionary(dictionary);

		let isHardDelete = false;
		//check if this method exists at services
		if (!service.remove) {
			console.error('Error on Remove request: remove method not found in service');
			throw new Error('remove method not found in service');
		}
		if (withPrompt) {
			const result = await Swal.fire({
				title: translate(dictionary.removePromptTitle),
				text: translate(
					title ? dictionary.removePromptMessage : dictionary.removePromptMessageNoTitle,
					false,
					title,
				),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: translate(dictionary.removePromptConfirm),
				cancelButtonText: translate(dictionary.removePromptCancel),
				confirmButtonColor: '#EF4444',
				cancelButtonColor: '#b5bec9',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						return await doRemove(id, dictionary, isHardDelete);
					} catch (error) {
						Swal.update({
							confirmButtonText: translate(dictionary.removePromptConfirmHard),
						});

						Swal.hideLoading();
						Swal.showValidationMessage(translate(error.message) || translate(dictionary.errorMessage));

						if (error.message === twoStepDeleteMessage && !isHardDelete && twoStepDeleteMessage !== null) {
							isHardDelete = true;
							return;
						}

						console.error('Error on Remove request: ', error);
						throw error;
					}
				},
			});

			//any action you want to do after the prompt
			return result;
		} else {
			return await doRemove(id, dictionary, isHardDelete);
		}
	};

	const doRemove = async (id, dictionary = null, isHardDelete = false) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.remove) {
			console.error('Error on Remove request: remove method not found in service');
			return;
		}

		try {
			const res = await service.remove(id, isHardDelete);
			if (res?.succeeded) {
				toast(translate(dictionary.removeSuccessMessage), {
					duration: 2000,
					icon: '✅',
				});
				return res;
			} else {
				toast(translate(res?.message || dictionary.errorMessage), {
					duration: 2000,
					icon: '❗️',
				});
				throw new Error(res?.message || dictionary.errorMessage);
			}
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Remove request: ', error);
			throw error;
		}
	};

	const postExport = async (queryString = '', searchParams = {}, fileName = 'data-export', dictionary = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.postExport) {
			console.error('Error on Post Export request: postExport method not found in service');
			return;
		}

		try {
			const res = await service.postExport(queryString, searchParams);
			const exportData = res;

			const blob = new Blob([exportData]);
			const url = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = url;
			link.download = `${fileName}-${Date.now()}.xlsx`;
			link.click();

			URL.revokeObjectURL(url);
			return res;
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Post Export request: ', error);
			throw error;
		}
	};

	const getExport = async (queryString = '', fileName = 'data-export', dictionary = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.getExport) {
			console.error('Error on Get Export request: getExport method not found in service');
			return;
		}

		try {
			const res = await service.getExport(queryString);
			const exportData = res;

			const blob = new Blob([exportData]);
			const url = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = url;
			link.download = `${fileName}-${Date.now()}.xlsx`;
			link.click();

			URL.revokeObjectURL(url);
			return res;
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on Get Export request: ', error);
			throw error;
		}
	};

	const getDictionary = (dictionary) => {
		if (dictionary === null) {
			dictionary = global_dictionary;
		} else {
			dictionary = { ...global_dictionary, ...dictionary };
		}
		return dictionary;
	};

	const fieldGating = async (data, dictionary = null) => {
		dictionary = getDictionary(dictionary);

		//check if this method exists at services
		if (!service.fieldGating) {
			console.error('Error on fieldGating request: fieldGating method not found in service');
			throw new Error('fieldGating method not found in service');
		}

		try {
			const res = await service.fieldGating(data);
			if (res?.succeeded) {
				return res.data;
			} else {
				console.error('Error on fieldGating request: ', res?.message);
				// toast(translate(res?.message || dictionary.errorMessage), {
				// 	duration: 2000,
				// 	icon: "❗️",
				// });
			}
		} catch (error) {
			toast(translate(error.message) || translate(dictionary.errorMessage), {
				duration: 2000,
				icon: '❗️',
			});
			console.error('Error on fieldGating request: ', error);
			throw error;
		}
	};

	return {
		create,
		update,
		getAll,
		getOne,
		search,
		remove,
		getExport,
		postExport,
		fieldGating,
	};
};

export default useCrud;
