import RoomType from "../pages/RoomType/parts/RoomTypeTable";
const routes = [
	{
		header: "general",
		key: "General",
		items: [
			{
				name: "roomType",
				key: "RoomType",
				hash: "#roomType",
				component: RoomType,
			},
		],
	},
];
export default routes;
