import React, { useState } from "react";

const Tooltip = ({ children, text }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<div className="relative">
			{showTooltip && text && (
				<div
					className="absolute z-10 p-2 -mt-1 text-sm leading-tight text-center text-white transform -translate-x-1/2 -translate-y-full bg-slate-800 rounded-lg shadow-lg"
					style={{ left: "50%" }}
				>
					{text}
				</div>
			)}
			<div
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				{children}
			</div>
		</div>
	);
};

export default Tooltip;
