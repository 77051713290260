import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { useState } from 'react';
import RoomTypeForm from '../forms/RoomType.form';
import RoomTypeModel from '../models';
import RoomTypeService from '../services';

const Tables = ({ permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const service = new RoomTypeService();
	const model = new RoomTypeModel();
	const { create, update, getAll, remove } = useCrud(service);

	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			setIsLoading(true);
			const res = await getAll(l_queryString || queryString);
			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: RoomTypeForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const rowClickHandler = (row) => {
		if (permissions.Update) {
			openEdit(row);
		} else if (permissions.View) {
			openView(row);
		}
	};

	return (
		<Card
			className='mt-2'
			collapsible
			header={model.getPluralModelName()}
			defaultOpen={true}
			headerButtonClick={openCreate}
			headerButtonLabel={permissions.Create && `+ ${translate('addRoomType')}`}
		>
			<Table
				isLoading={isLoading}
				isExportable={false}
				model={model}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryStr) => {
					setQueryString(queryStr);
					fetchAndLoad(queryStr);
				}}
				onRowClick={rowClickHandler}
			/>
		</Card>
	);
};
export default Tables;
