import React, { useRef } from "react";
import PortalContext from "./PortalContext";

const PortalProvider = ({ children, portals = {} }) => {
	const portalRefs = useRef({});

	const callAction = (id, action, data, callback = () => { }) =>
		portalRefs.current[id]?.callAction(action, data, callback);

	const eventContextValue = {
		config: portals,
		callAction,
	};

	return (
		<PortalContext.Provider value={eventContextValue}>
			{children}
			<>
				{Object.entries(portals).map(([key, value]) => {
					const PortalElement = value.element;
					return (
						<PortalElement
							ref={(el) => (portalRefs.current[key] = el)}
							key={key}
							id={key}
							{...value?.initialState}
						/>
					);
				})}
			</>
		</PortalContext.Provider>
	);
};

export default PortalProvider;
