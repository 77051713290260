import { PortalContext } from 'contexts/PortalManager';
import { runAction } from 'modules/utils';
import { useContext, useEffect, useState } from 'react';

const ExternalTests = () => {
	const { callAction } = useContext(PortalContext);
	const [data, setData] = useState({});
	const [p, setP] = useState('???');

	const checkP = async () => {
		const nfcPermissionStatus = await navigator.permissions.query({ name: 'nfc' });
		setP(nfcPermissionStatus.state);
	};
	useEffect(() => {
		checkP();
	}, []);
	return (
		<div className='container mx-auto py-5'>
			<div className='mb-5'>Tests</div>
			<button
				type={'button'}
				onClick={() =>
					// alert(translate("notImplementedYet"))
					runAction('scanners', 'openNfc', {
						callAction,
						onScan: (code) => alert('Scanned: ' + code),
					})
				}
				className='text-white rounded-md text-sm px-8 py-1.5 bg-gray-500 hover:bg-gray-400'
			>
				NFC START
			</button>
			<div className='mt-5 p-2 rounded-lg bg-gray-200'>{JSON.stringify(data)}</div>
			<div>
				<strong>Status:</strong> {p}
			</div>
		</div>
	);
};

export default ExternalTests;
