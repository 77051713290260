import React from "react";

const ExternalTask = React.lazy(() => import("./pages/ExternalTask"));

const routes = [
	{ path: "task", element: <ExternalTask /> },
	{ path: "*", element: <h1>Not Found</h1> },
];

export default routes;
