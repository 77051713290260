import React, { useRef } from "react";
import TaskService from "../../../services";
import { useTranslations } from "hooks";
import ActionButton from "../ActionButton";
import AllocateHandler from "../AllocateHandler";
import Swal from "sweetalert2";

const MultipleActionRow = ({
	selectedItems,
	taskActions,
	fetchAndLoad,
	allRowsLength = 0,
	clearSelectedItems = () => {},
}) => {
	console.log("selectedItems", selectedItems);
	const service = new TaskService();
	const { translate } = useTranslations();
	const allocateDialogRef = useRef(null);

	const buttons = [
		{
			id: 2,
			label: "accept",
			icon: "ri-check-line",
			color: "success",
			onClick: function (items) {
				TaskActionHandler(items, this.id, {
					title: "acceptTasks",
					text: "doYouWantToAcceptSelectedThisTasks",
					icon: "ri-check-line",
					confirmButtonText: "accept",
					confirmButtonColor: "rgb(34, 197, 94)",
					cancelButtonText: "cancel",
				});
			},
		},
		{
			id: 3,
			label: "start",
			icon: "ri-flag-line",
			color: "primary",
			onClick: function (items) {
				TaskActionHandler(items, this.id, {
					title: "startTasks",
					text: "doYouWantToStartSelectedThisTasks",
					icon: "ri-flag-line",
					confirmButtonText: "start",
					confirmButtonColor: "rgb(59, 130, 246)",
					cancelButtonText: "cancel",
				});
			},
		},
		{
			id: 4,
			label: "complete",
			icon: "ri-check-double-line",
			color: "success",
			onClick: function (items) {
				TaskActionHandler(items, this.id, {
					title: "completeTasks",
					text: "doYouWantToCompleteSelectedThisTasks",
					icon: "ri-check-double-line",
					confirmButtonText: "complete",
					confirmButtonColor: "rgb(34, 197, 94)",
					cancelButtonText: "cancel",
				});
			},
		},
		{
			id: 5,
			label: "cancel",
			icon: "ri-close-line",
			color: "danger",
			onClick: function (items) {
				TaskActionHandler(items, this.id, {
					title: "cancelTasks",
					text: "doYouWantToCancelSelectedThisTasks",
					icon: "ri-close-line",
					confirmButtonText: "cancel",
					confirmButtonColor: "rgb(239, 68, 68)",
					cancelButtonText: "cancel",
				});
			},
		},
		{
			id: 6,
			label: "Deactivate",
			icon: "ri-rotate-lock-line",
			color: "danger",
			onClick: function (items) {
				TaskActionHandler(items, this.id, {
					title: "deactivateTasks",
					text: "doYouWantToDeactivateSelectedThisTasks",
					icon: "ri-rotate-lock-line",
					confirmButtonText: "Deactivate",
					confirmButtonColor: "rgb(239, 68, 68)",
					cancelButtonText: "cancel",
				});
			},
		},
		{
			id: 7,
			label: "Activate",
			icon: "ri-refresh-line",
			color: "success",
			onClick: function (items) {
				TaskActionHandler(items, this.id, {
					title: "activateTasks",
					text: "doYouWantToActivateSelectedThisTasks",
					icon: "ri-refresh-line",
					confirmButtonText: "Activate",
					confirmButtonColor: "rgb(34, 197, 94)",
					cancelButtonText: "cancel",
				});
			},
		},
		{
			id: 8,
			label: "allocate",
			icon: "ri-map-pin-range-line",
			color: "warning",
			onClick: (item) => {
				allocateDialogRef?.current?.showModal();
			},
		},
	];

	const TaskActionHandler = (
		items,
		id,
		{
			title,
			text,
			icon,
			confirmButtonText,
			confirmButtonColor,
			cancelButtonText,
		},
		onSuccess = () => {}
	) => {
		Swal.fire({
			title: translate(title),
			text: translate(text, false, selectedItems.length),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: `<i class='${icon}'></i> ${translate(
				confirmButtonText
			)}`,
			confirmButtonColor: confirmButtonColor,
			cancelButtonText: `${translate(cancelButtonText)}`,
			cancelButtonColor: "#b5bec9",
			showLoaderOnConfirm: true,
			preConfirm: async () => {
				try {
					await service
						.changeStatus(
							selectedItems.map((item) => item.id),
							id
						)
						.then((response) => {
							if (response.succeeded === false) {
								Swal.showValidationMessage(
									translate(response.message) ||
										translate("somethingWentWrong")
								);
								return;
							} else {
								onSuccess(response.data);
							}
						});

					fetchAndLoad();
				} catch (error) {
					console.error("Error on action", error);
					Swal.hideLoading();
					Swal.showValidationMessage(
						translate(error.message) ||
							translate("somethingWentWrong")
					);
					throw error;
				}
			},
		});
	};

	const statuses = selectedItems
		.map((item) => item.status.id)
		.filter((v, i, a) => a.indexOf(v) === i);

	const actions = taskActions.filter((action) =>
		statuses.every((status) => action.statuses.includes(status))
	);

	const actionButtons = buttons.filter((button) =>
		actions.map((action) => action.action.id).includes(button.id)
	);

	// console.log("actionButtons", actionButtons);

	return (
		<>
			<div className="flex flex-row justify-between items-center">
				<div className="flex flex-row items-center space-x-2">
					<i className="ri-checkbox-circle-fill text-primary-500"></i>
					<span className="text-sm text-gray-500">
						{selectedItems.length === allRowsLength
							? translate("allItemsAreSelected")
							: selectedItems.length +
							  " " +
							  translate("itemsAreSelected")}
					</span>
					<span
						onClick={() => clearSelectedItems()}
						className="ml-3 text-primary-300 font-semibold cursor-pointer uppercase hover:text-primary-500"
					>
						{translate("deselect")}
					</span>
				</div>
				<div className="flex flex-row mr-3 space-x-2">
					{actionButtons.map((action, index) => (
						<ActionButton
							key={index}
							{...action}
							onClick={() => action.onClick(selectedItems)}
						/>
					))}
				</div>
			</div>
			<AllocateHandler
				fetchAndLoad={() => {
					fetchAndLoad();
					clearSelectedItems();
				}}
				service={service}
				items={selectedItems}
				ref={allocateDialogRef}
			/>
		</>
	);
};
export default MultipleActionRow;
