import { StringColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
class AddressModel extends BaseModel {
	constructor(levelToDisplay) {
		super();
		this.modelName = 'address';
		this.pluralModelName = 'addresses';
		this.levelToDisplay = levelToDisplay;
	}
	initializeColumns() {
		this.columns.push(
			new StringColumn(
				'groupName',
				{
					label: 'group',
					filter: {
						type: 'text',
						key: 'group',
					},
				},
				{
					level: 6,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'campusName',
				{
					label: 'campus',
					filter: {
						type: 'text',
						key: 'campus',
					},
				},
				{
					level: 5,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'siteName',
				{
					label: 'site',
					filter: {
						type: 'text',
						key: 'site',
					},
				},
				{
					level: 4,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'zoneName',
				{
					label: 'zone',
					filter: {
						type: 'text',
						key: 'zone',
					},
				},
				{
					level: 3,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'roomName',
				{
					label: 'room',
					filter: {
						type: 'text',
						key: 'room',
					},
				},
				{
					level: 2,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'bedName',
				{
					label: 'bed',
					filter: {
						type: 'text',
						key: 'bed',
					},
				},
				{
					level: 1,
				},
			),
		);
		//extra address fields
		this.columns.push(
			new StringColumn(
				'roomTypeName',
				{
					label: 'roomType',
					filter: {
						type: 'text',
						key: 'RoomType',
					},
				},
				{
					level: 5,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'roomExternalId',
				{
					label: 'roomExternalId',
					filter: {
						type: 'text',
						key: 'roomExternalId',
					},
				},
				{
					level: 5,
				},
			),
		);
		this.columns.push(
			new StringColumn(
				'bedExternalId',
				{
					label: 'bedExternalId',
					filter: {
						type: 'text',
						key: 'bedExternalId',
					},
				},
				{
					level: 5,
				},
			),
		);
		this.assignColumnOrder();
	}

	setLevel(level) {
		this.levelToDisplay = level;
	}

	getColumns() {
		return this.columns.filter((c) => c.meta.level <= this.levelToDisplay);
	}

	areSelectsVisible() {
		return false;
	}

	isModifiable() {
		return false;
	}
}
export default AddressModel;
