import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";

import { CodeInput } from "components/ui/Input";
import { useTranslations } from "hooks";
import { fa } from "@faker-js/faker";

const EmailTwoFa = ({
	email = "",
	register = async () => {},
	selectAndNext = () => {},
}) => {
	const { translate } = useTranslations();
	const [code, setCode] = useState("");
	const [error, setError] = useState("");

	const [loading, setLoading] = useState(false);

	const doRegister = async () => {
		if (code.length !== 6) return;

		setLoading(true);
		setError("");

		await register(code).then((r) => {
			setLoading(false);
			if (!r?.succeeded) {
				setError(r?.message || "TwoFactorCodeNotValid");
			} else {
				selectAndNext();
			}
		});
	};

	return (
		<div className="flex flex-col pt-5 pb-0 w-full h-full">
			<div className="flex flex-col items-center mb-6">
				<img
					src={require("../../../assets/email.png")}
					className="w-20 h-20 mb-4"
					alt="email icon"
				/>
				<h1 className="text-4xl font-medium mb-1">
					{translate("email2FAAuthentication")}
				</h1>
				<p className="text-xs">
					{translate(
						"youWillReceiveAVerificationCodeOnYourThisEmail",
						false,
						email
					)}
				</p>
			</div>
			<div className="flex w-2/3 px-50 flex-col self-center items-center h-full">
				<div className="text-xs mb-2">
					{translate("writeTheCodeYouReceivedOnYourEmail")}
				</div>
			</div>
			<div className="w-full border-b border-slate-200 my-4" />
			<div className="flex w-2/3 self-center items-center">
				<CodeInput onChange={(c) => setCode(c)} />
			</div>
			<div>
				{error && (
					<div className="text-red-500 text-sm mt-2 text-center">
						{translate(error)}
					</div>
				)}
			</div>
			<div className="flex flex-row-reverse pt-2 pb-2">
				{loading ? (
					<div
						className={`opacity-50 font-medium flex px-3 py-2 hover:pr-2 justify-center items-center`}
					>
						{translate("connecting")} ...
					</div>
				) : (
					<div
						className={`${
							code.length < 6
								? "cursor-not-allowed opacity-50"
								: "cursor-pointer"
						} group font-medium flex px-3 py-2 hover:pr-2 justify-center items-center transition-all`}
						onClick={doRegister}
					>
						{translate("connect")}{" "}
						<i className="ri-arrow-right-line ml-2 text-xl "></i>
					</div>
				)}
			</div>
		</div>
	);
};

export default EmailTwoFa;
