import AbstractService from 'services/Service';
const endpoint = 'generalcontent';

class GeneralContentsService extends AbstractService {
	constructor(typeId = null) {
		super();
		this.typeId = typeId;
		if (typeId) {
			this.endpoint = endpoint + '/' + typeId;
		}
	}

	async create(data) {
		const dataToSend = { ...data, type: this.typeId };
		return await super.create(dataToSend);
	}

	async update(id, data) {
		const dataToSend = { ...data, type: this.typeId };

		return await super.update(id, dataToSend);
	}

	getTypeId() {
		return this.typeId;
	}
	setTypeId(typeId) {
		this.typeId = typeId;
		this.endpoint = endpoint + '/' + typeId;
	}
}
export default GeneralContentsService;
