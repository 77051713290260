import { BadgedListRender } from 'core/renders';

import { ListColumn } from './baseColumns';

class BadgedListColumn extends ListColumn {
	constructor(name, options = {}) {
		super(name, options);
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || null;
		this.hidden = options.hidden || false;
		this.render = (items) => <BadgedListRender items={items} {...options?.renderProps} />;
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default BadgedListColumn;
