import { FormEntry } from "components/form";
import { forwardRef, useEffect, useState } from "react";
import InputField from "modules/tasks/components/InputField";
import HeaderInput from "./HeaderInput";
import FooterInput from "./FooterInput";

const StaticField = ({
	inputType,
	staticFieldId,
	name,
	isComplex = false,
	options = [],
	formKey = "",
	defaultData = null,
	taskGroupId = null,
	disabled = false,
	...args
}) => {
	const [isRequired, setIsRequired] = useState(
		defaultData?.isHidden ?? false
	);
	const [fieldName, setFieldName] = useState(name);

	useEffect(() => {
		setFieldName(name);
	}, [name]);

	return (
		<>
			<input
				type="hidden"
				name={formKey + ".staticFieldId"}
				value={staticFieldId}
			/>
			<input type="hidden" name={formKey + ".name"} value={fieldName} />

			<HeaderInput
				isDisabled={disabled}
				name={fieldName}
				onNameChanged={(name) => {
					setFieldName(name);
				}}
			/>
			<FormEntry label={"byDefault"} required={isRequired}>
				<InputField
					name={formKey + ".defaultValue"}
					inputType={inputType}
					defaultData={defaultData?.defaultValue}
					options={options}
					isRequired={isRequired}
					isDisabled={disabled}
					parentId={taskGroupId}
					{...args}
				/>
			</FormEntry>
			<FooterInput
				selected={defaultData?.isHidden || null}
				isDisabled={disabled}
				name={formKey + ".isHidden"}
				onChange={(c) => {
					setIsRequired(c);
				}}
			/>
		</>
	);
};

export default StaticField;
