import { withSuspense } from 'hoc';
import { useToolbar } from 'hooks';
import { useEffect } from 'react';
import UserProfileTable from './pages/UserProfiles/parts/UserProfileTable';
import UsersTable from './pages/Users/parts/UsersTable';

const UsersPage = ({ permissions }) => {
	const { crumbsBuilder } = useToolbar();

	useEffect(() => {
		crumbsBuilder.addPath('/persons', 'users');
		crumbsBuilder.addCurrent('catoUsers');
		crumbsBuilder.build();
	}, []);

	return (
		<div className='grid grid-cols-1 gap-2 '>
			{permissions.User.View && <UsersTable permissions={permissions.User} />}
			{permissions.UserProfile.View && <UserProfileTable permissions={permissions.UserProfile} />}
		</div>
	);
};
export default withSuspense(UsersPage);
