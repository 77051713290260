import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { Input, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useCrud, useTranslations } from 'hooks';
import toast from 'react-hot-toast';
import Permission from '../components/Permission';

const PermissionGroupForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const { getOne } = useCrud(props.service);
	const [allPermissions, setAllPermissions] = useState([]);

	const fetchPermissions = async () => {
		const res = await props.service.getAllPermissions();
		if (res.error) {
			toast(translate('somethingWentWrong'), {
				duration: 2000,
				icon: '❗️',
			});
		} else {
			setAllPermissions(res.data);
		}
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	useEffect(() => {
		fetchPermissions();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-auto'>
				<Form ref={myForm}>
					<Input type='hidden' name='id' value={data?.id || false} />
					<InternalFormDivider>{translate('generalInformation')}</InternalFormDivider>
					<FormEntry label={'groupName'}>
						<Input
							isView={isView}
							placeholder={'groupName'}
							type='text'
							required={true}
							name='name'
							defaultValue={data?.name || ''}
						/>
					</FormEntry>
					<FormEntry label={'description'}>
						<TextArea
							isView={isView}
							rows={4}
							placeholder={'description'}
							required={false}
							name='description'
							defaultValue={data?.description || ''}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('permissions')}</InternalFormDivider>
					<tr>
						<td colSpan={2}>
							{Object.keys(allPermissions).map((allPermissionKey, allPermissionIndex) => {
								const currentPermissions = allPermissions[allPermissionKey];

								return (
									<div>
										<div className='flex items-center text-md text-center font-semibold text-cyan-700 uppercase pt-8 pb-4'>
											<div className='flex-grow border-t border-cyan-500'></div>
											<span className='px-5 text-lg'>{translate(allPermissionKey)}</span>
											<div className='flex-grow border-t border-cyan-500'></div>
										</div>
										<div>
											{Object.keys(currentPermissions).map((key, index) => (
												<Permission
													isView={isView}
													permissionKey={key}
													key={index}
													index={index}
													activePermissions={data?.permissions || null}
													permissions={currentPermissions}
												/>
											))}
										</div>
									</div>
								);
							})}
						</td>
					</tr>
				</Form>
			</div>
		</Suspense>
	);
});
export default PermissionGroupForm;
