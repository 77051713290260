import Swal from "sweetalert2";
import { useTranslations } from "hooks";
import toast from "react-hot-toast";

const Reset2FA = ({ props }) => {
	const { translate } = useTranslations();

	const reset2FaConfirmation = (id) => {
		Swal.fire({
			title: translate("areYouSure"),
			text: translate(
				"youAreAboutToResetTwoFactorAuthenticationFor",
				false,
				props?.data?.name
			),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: translate("yesResetIt"),
			cancelButtonText: translate("cancel"),
			confirmButtonColor: "#EF4444",
			cancelButtonColor: "#b5bec9",
		}).then((result) => {
			if (result.isConfirmed) reset2FAAction(id);
		});
	};

	const reset2FAAction = async (id) => {
		await props.service.reset2Fa(id).then((res) => {
			if (res.succeeded) {
				props?.requestReload();
				toast(
					translate(
						"twoFactorAuthenticationHasBeenResetSuccessfully"
					),
					{
						duration: 2000,
						icon: "✅",
					}
				);
			} else {
				toast(res.message || translate("somethingWentWrong"), {
					duration: 2000,
					icon: "❗️",
				});
			}
		});
	};
	return {
		reset2FaConfirmation,
	};
};

export default Reset2FA;
