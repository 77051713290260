import { Table } from 'components/ui/Table';
import { useState } from 'react';
import TaskService from '../../../../services';

import { useCrud } from 'hooks';
import ReturnTaskModel from './models';

const ReturnTaskTable = ({ taskId = -1, onTaskClick = () => {}, permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const service = new TaskService();
	const model = new ReturnTaskModel();

	const { getAll } = useCrud({
		getAll: async (queryString) => await service.getReturnActions(queryString, taskId),
		getOne: null,
	});

	const fetchAndLoad = async (l_queryString = null) => {
		setIsLoading(true);
		try {
			const res = await getAll(l_queryString || queryString);

			setIsLoading(false);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
			isLoading(false);
		}
	};

	return (
		<Table
			isLoading={isLoading}
			displayCheckBoxes={false}
			isFilterable={false}
			columnsCanBeModified={false}
			isExportable={false}
			model={model}
			meta={data.meta}
			onTableRequestChange={(queryString) => {
				setQueryString(queryString);
				fetchAndLoad(queryString);
			}}
			onRowClick={onTaskClick}
			data={data.data}
		/>
	);
};
export default ReturnTaskTable;
