import AbstractService from 'services/Service';
const endpoint = 'users/entraid/config';
const testEndpoint = 'users/entraid/test';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async test() {
		return await this.api.get(`${testEndpoint}`);
	}

	mapData = (data) => {
		return {
			...data,
			isSyncEnabled: data?.isSyncEnabled === 'true',
			runIntervalInMinutes: parseInt(data?.runIntervalInMinutes) ?? null,
		};
	};
}

export default Service;
