import tokenService from "services/token/token.service";

const useUser = () => {
	if (window.location.href.startsWith("http://localhost:3001/dev")) {
		return {
			email: "indev@test.com",
		};
	}

	const user = tokenService.getUser();
	return user;
};

export default useUser;
