import { useTranslations } from "hooks";
import { BORDER_COLORS, getTranslatedName } from "./utils";

const NewNode = ({ level = 0, rootLevel = 6, onClick = () => {} }) => {
	const { translate } = useTranslations();

	const oglevel = level;
	level = rootLevel - level;
	const position = 100 - 4 * level;

	return (
		<div className="flex flex-col items-end w-[100%]">
			<div
				style={{ width: `${position}%` }}
				onClick={onClick}
				className={`group opacity-50 hover:opacity-75 flex flex-row items-center mb-2 overflow-hidden cursor-pointer border border-dashed ${BORDER_COLORS[oglevel]} rounded`}
			>
				<div className={`h-full w-[5px]`} />
				<div className="opacity-25 group-hover:opacity-50 py-2 flex flex-row w-full items-center">
					<i className={"ri-add-box-line ml-3 text-xl"}></i>
					<div className="ml-3 flex flex-1 w-full flex-col">
						{/* <div className="text-xs text-slate-500">Add new</div> */}
						<div className="font-medium">
							{translate(
								"addNewThis",
								false,
								getTranslatedName(translate, oglevel)
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewNode;
