import { Navigate } from 'react-router-dom';
import GeneralSettings from '../pages/generalSettings';

const routes = [
	{ index: true, element: <Navigate to='settings' replace /> },
	{ path: 'settings/*', element: <GeneralSettings /> },

	{ path: '*', element: <Navigate to={''} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
