import { CheckBox } from "components/ui/Input";
import { useTranslations } from "hooks";

export const ColumnItem = ({
	column,
	index = 0,
	moveUpDisabled = false,
	moveDownDisabled = false,
	onMoveUp = () => {},
	onMoveDown = () => {},
	onCheckChange = () => {},
	disabled = false,
}) => {
	const { translate } = useTranslations();

	const getLabel = (column) => {
		if (column.title && column.label) {
			return (
				translate(column.label) + " (" + translate(column.title) + ")"
			);
		}

		return column.label;
	};

	return (
		<div key={index} className="flex items-center space-x-3 h-8 ">
			<div className="flex flex-row   items-center">
				<button
					disabled={moveUpDisabled || disabled}
					className={`opacity-50 hover:opacity-100 ${
						moveUpDisabled && `cursor-not-allowed opacity-20`
					}`}
					type="button"
					onClick={() => onMoveUp(index)}
				>
					<i className="ri-arrow-up-fill"></i>
				</button>
				<button
					disabled={moveDownDisabled || disabled}
					className={`opacity-50 hover:opacity-100 ${
						moveDownDisabled && `cursor-not-allowed opacity-20`
					}`}
					type="button"
					onClick={() => onMoveDown(index)}
				>
					<i className="ri-arrow-down-fill"></i>
				</button>
			</div>
			<div className="flex flex-row items-center">
				<div>
					<CheckBox
						checkboxSize="text-lg"
						selected={!column.hidden}
						label={getLabel(column)}
						onChange={(e) => {
							onCheckChange(index, e.target.checked);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ColumnItem;
