import { Form, FormEntry } from 'components/form';
import { AddressSelector, FileInput, Input, ReSelect, TagsInput, TextArea } from 'components/ui/Input';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useCrud, useTranslations } from 'hooks';
import UserService from 'modules/persons/pages/Users/pages/Users/pages/Users/services';
import { runAction } from 'modules/utils';
import CategoryService from '../../settings/pages/categories/services';

const AssetForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const { getOne } = useCrud(props.service);

	const categoryService = new CategoryService();
	const userService = new UserService();

	const [data, setData] = useState([]);
	const [assetTypes, setAssetTypes] = useState([]);
	const [assetStatuses, setAssetStatuses] = useState([]);
	const [users, setUsers] = useState([]);

	const myForm = useRef(null);

	const fetchAssetTypes = async () => {
		await runAction('tenants', 'getEnum', 'AssetType')
			.then((enumValues) => {
				const l_assetTypes = [];
				Object.keys(enumValues).forEach((key) => {
					l_assetTypes.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setAssetTypes(l_assetTypes);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const fetchAssetStatuses = async () => {
		await runAction('tenants', 'getEnum', 'AssetStatus')
			.then((enumValues) => {
				const l_assetStatuses = [];
				Object.keys(enumValues).forEach((key) => {
					l_assetStatuses.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setAssetStatuses(l_assetStatuses);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const homeLocationChangeHandler = (address) => {
		if (address) {
			const leafAddress = getAddress(address);
			fetchUsers(leafAddress.id, leafAddress.level);
		}
	};

	const fetchUsers = async (locationId, locationLevel) => {
		await userService
			.getResponsiblesOptionsList(null, locationId, locationLevel)
			.then((res) => {
				setUsers(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				if (res.homeLocation) {
					const leafAddress = getAddress(res.homeLocation);
					fetchUsers(leafAddress.id, leafAddress.level);
				}
			});
		}
	};

	const getAddress = (address) => {
		if (address) {
			if (address.child) {
				return getAddress(address.child);
			} else {
				return address;
			}
		}
		return null;
	};

	useEffect(() => {
		initiateData();
		fetchAssetTypes();
		fetchAssetStatuses();
	}, []);

	return (
		<Suspense fallback={<>Loading</>}>
			<div className='w-full h-100 pb-10 overflow-y-auto'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<FormEntry label={'name'}>
						<Input
							minLength={3}
							type='text'
							required={true}
							isView={isView}
							name={'name'}
							placeholder={'name'}
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label={'number'}>
						<Input
							minLength={3}
							type='text'
							required={true}
							isView={isView}
							name={'number'}
							placeholder={'number'}
							defaultValue={data?.number}
						/>
					</FormEntry>
					<FormEntry label={'description'}>
						<TextArea
							isView={isView}
							minLength={3}
							rows={4}
							placeholder={'description'}
							required={false}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'type'}>
						<ReSelect
							required
							isView={isView}
							name='type'
							options={assetTypes}
							defaultValue={data?.type?.id}
						/>
					</FormEntry>
					<FormEntry label={'status'}>
						<ReSelect
							required
							isView={isView}
							name='status'
							options={assetStatuses}
							defaultValue={data?.status?.id}
						/>
					</FormEntry>
					<FormEntry label='homeLocation'>
						<AddressSelector
							isView={isView}
							truncateAt={40}
							address={data?.homeLocation}
							name='homeLocation'
							required={true}
							onChange={homeLocationChangeHandler}
						/>
					</FormEntry>
					<FormEntry label='currentLocation'>
						<AddressSelector
							isView={isView}
							truncateAt={40}
							address={data?.currentLocation}
							name='currentLocation'
						/>
					</FormEntry>
					<FormEntry label={'tags'}>
						<TagsInput minLength={3} isView={isView} name={'tags'} defaultValue={data?.tags} />
					</FormEntry>
					<FormEntry label='responsiblePerson'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							name='ResponsiblePersonId'
							defaultValue={data?.responsiblePerson}
							options={users}
						/>
					</FormEntry>
					<FormEntry label='category'>
						<ReSelect
							required
							isView={isView}
							name='CategoryId'
							defaultValue={data?.category}
							service={categoryService}
						/>
					</FormEntry>
					<FormEntry label={'manufacturer'}>
						<Input
							minLength={3}
							type='text'
							isView={isView}
							name={'manufacturer'}
							placeholder={'manufacturer'}
							defaultValue={data?.manufacturer}
						/>
					</FormEntry>
					<FormEntry label={'serialNumber'}>
						<Input
							required
							type='text'
							isView={isView}
							name={'serialNumber'}
							placeholder={'serialNumber'}
							defaultValue={data?.serialNumber}
						/>
					</FormEntry>
					<FormEntry label={'model'}>
						<Input
							minLength={3}
							type='text'
							isView={isView}
							name={'model'}
							placeholder={'model'}
							defaultValue={data?.model}
						/>
					</FormEntry>
					<FormEntry label={'expirationDate'}>
						<Input
							type='date'
							isView={isView}
							name={'expirationDate'}
							placeholder={'expirationDate'}
							defaultValue={data?.expirationDate ? data.expirationDate.split('T')[0] : ''}
						/>
					</FormEntry>
					<FormEntry label={'file'}>
						{data?.file && <input type='hidden' name='fileString' value={data?.file} />}
						<FileInput
							name='file'
							isView={isView}
							defaultValue={data?.file}
							onDelete={() => {
								setData((prev) => ({ ...prev, file: null }));
							}}
							accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						/>
					</FormEntry>
					<FormEntry label={'image'}>
						{data?.image && <input type='hidden' name='imageString' value={data?.image} />}
						<FileInput
							isView={isView}
							name='image'
							defaultValue={data?.image}
							onDelete={() => {
								setData((prev) => ({ ...prev, image: null }));
							}}
							accept='image/jpeg,image/png,image/bmp'
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default AssetForm;
