const FieldHelper = ({ fields }) => {
	const reOrder = (field, toUp = true, callBack = () => {}) => {
		let index = -1;

		if (field.id) {
			index = fields.findIndex((f) => f.id === field.id);
		} else {
			index = fields.findIndex((f) => f.order === field.order);
		}

		const newIndex = toUp ? index - 1 : index + 1;
		const newFields = [...fields];

		const newOrder = toUp ? field.order - 1 : field.order + 1;

		newFields[index] = { ...fields[newIndex], order: field.order };
		newFields[newIndex] = { ...fields[index], order: newOrder };
		callBack(newFields, newIndex);
	};

	const matchOptions = (value) => {
		if (!value) return [];
		const options = JSON.parse(value);

		return options.map((option) => {
			return { Id: option, Name: option };
		});
	};

	const addField = (field, callBack = () => {}) => {
		const greatestOrder = fields.reduce(
			(acc, field) => (field.order > acc ? field.order : acc),
			0
		);

		const fieldObject = {
			order: greatestOrder + 1,
			name: field.name,
			isHidden: false,
			isDisabled: false,
			isRequired: false,
			values:
				field?.inputType?.values ||
				JSON.stringify(matchOptions(field?.value) || []),
			customFieldId: field.id,
			inputType: field.inputType.id,
		};
		callBack(fieldObject);
	};

	const removeField = (index, callBack = () => {}) => {
		const newFields = fields.filter((field, i) => i !== index);

		newFields.forEach((field, i) => {
			field.order = i + 1;
		});
		callBack(newFields);
	};

	const parseJson = (data) => {
		if (!data) return null;
		if (typeof data === "object") {
			return data;
		} else {
			return matchOptions(data);
		}
	};

	const duplicateField = (field, callBack = () => {}) => {
		const newField = {
			...field,
			name: field.name + " copy",
			order: fields.length + 1,
		};

		callBack(newField);
	};

	return {
		reOrder,
		addField,
		parseJson,
		removeField,
		duplicateField,
		matchOptions,
	};
};

export default FieldHelper;
