import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { Alert } from 'components/ui/Info';
import { Button, FileInput } from 'components/ui/Input';
import { useTranslations } from 'hooks';

const allowedTypes = [
	'.csv',
	'text/csv',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
];

const ImportForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	const [importFile, setImportFile] = useState(null);
	const [error, setError] = useState(false);

	const inputRef = useRef(null);

	useImperativeHandle(ref, () => ({
		getData: () => null,
		clear: () => null,
		runSubmit: () => null,
	}));

	const handleImport = useCallback(() => {
		console.log('Initiating import');
		if (importFile) {
			// TODO: File validation check
			props.onImportStart(importFile);
		}
	}, [importFile, props]);

	const onFileAdded = useCallback((e) => {
		setError(false);
		const file = e.target.files[0];
		if (allowedTypes.indexOf(file.type) < 0) {
			setError(true);
		} else {
			setImportFile(e.target.files[0]);
		}
	}, []);

	return (
		<>
			{' '}
			<div className='w-full h-100 pb-40 overflow-y-auto'>
				<div className='text-slate-500 mb-8'>{translate('thisFormWillImportAListOfAddressesInTheSystem')}</div>

				<a href='/imports/address-import-template.xlsx' className='w-full' download>
					<div className='my-2 p-2 text-sm cursor-pointer text-primary-500 hover:font-medium border-slate-200 hover:text-primary-500'>
						<i class='ri-file-download-line text-lg'></i>
						&nbsp;&nbsp;&nbsp;
						{translate('getImportTemplate')}
					</div>
				</a>
				<div className='text-slate-500 mb-8'>
					{translate(
						'pleaseUseTheProvidedFileAsTheImportTemplateUsingAnotherFileMayLeadToProblemsWhileImporting',
					)}
				</div>
				<FileInput ref={inputRef} accept={allowedTypes.join(',')} onChange={onFileAdded} />
				{error && (
					<Alert title={translate('invalidFile')} type={'error'}>
						{translate('pleaseChooseAValidFileOrUseTheProvidedTemplate')}
					</Alert>
				)}
				<div className='flex flex-row justify-end mt-5'>
					<Button onClick={handleImport} disabled={!importFile || error}>
						{translate('import')}
					</Button>
				</div>
			</div>
		</>
	);
});

export default ImportForm;
