import api from "services/api";
import tokenService from "services/token/token.service";

const endpoint = "languages?page=1&pageSize=100";

export const getLanguages = async () => {
    return await api.get(`${endpoint}`, {
        headers: {
            Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
        },
    });
};

export const getCountryByLanguageCode = (code) => {
    switch (code.toUpperCase()) {
        case 'EN':
            return 'US';
        case 'SQ':
            return 'AL';
        // add more cases for other language codes here
        default:
            return code;
    }
};

const getCountryFlag_from_emoji = (countryCode) => {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
};

export const getCountryFlag = (countryCode) => {
    let flagPath;
    try {
        flagPath = require(`../../../assets/flags/4x3/${countryCode.toLowerCase()}.svg`);
    } catch (error) {
        flagPath = getCountryFlag_from_emoji(countryCode);
    }
    return <img src={flagPath} alt={countryCode} className="h-4 w-4 mr-2" />;
};