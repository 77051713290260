import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { createPortals } from "contexts/PortalManager";

import modules, { publicModules } from "./modules";

export const router = createBrowserRouter([
	...modules.map((module) => {
		return {
			path: `/${module.id}/*`,
			element: module.element,
			children: module.routes,
		};
	}),
	{
		path: "*",
		element: <Navigate to={`/${modules[0].id}`} replace />,
	},
	{
		index: true,
		element: <Navigate to={`/${modules[0].id}`} replace />,
	},
]);

export const publicRouter = createBrowserRouter([
	...publicModules.map((module) => {
		return {
			path: `/${module.id}/*`,
			element: module.element,
			children: module.routes,
		};
	}),
	{
		path: "*",
		element: <Navigate to={`/${publicModules[0]?.id}`} replace />,
	},
	{
		index: true,
		element: <Navigate to={`/${publicModules[0]?.id}`} replace />,
	},
]);

export const portals = createPortals(
	modules.concat(publicModules).map((module) => module.portals)
);
