import PermissionService from "../services";
import { localStorageKeys } from "config";

export const getPermissions = async () => {
	let permissions = localStorage.getItem(
		localStorageKeys.PERMISSIONS_STORAGE_KEY
	);
	if (permissions && JSON.parse(permissions)) {
		return JSON.parse(permissions);
	} else {
		try {
			const service = new PermissionService();
			permissions = await service.getAllPermissions();
			if (permissions.data)
				localStorage.setItem(
					localStorageKeys.PERMISSIONS_STORAGE_KEY,
					JSON.stringify(permissions.data)
				);
			return permissions.data;
		} catch (error) {
			console.error("Error:", error);
		}
	}
};

export const clearPermissions = async () => {
	localStorage.removeItem(localStorageKeys.PERMISSIONS_STORAGE_KEY);
};
