import AbstractService from 'services/Service';
import { extractMeta } from 'utils/pagination';

const endpoint = 'taskgroups/{parentId}/templates';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
		this.parentId = null;
	}

	setParent(id) {
		this.endpoint = endpoint.replace('{parentId}', id);
		this.parentId = id;
	}

	async copy(id) {
		this.beforeEach();
		return await this.api.get(`${this.endpoint}/${id}/copy`);
	}

	beforeEach() {
		this.assertIfParentIdIsSet();
		return true;
	}

	assertIfParentIdIsSet() {
		if (this.parentId === null) {
			throw new Error('Parent ID is not set');
		}
	}

	async viewOne(id) {
		return await super.getOne(id, true);
	}

	#parseValue(value) {
		if (value === 'null') return null;
		if (value === 'undefined') return null;
		if (value === undefined) return null;
		if (value === '') return null;
		if (value === 'true') return true;
		if (value === 'false') return false;

		if (isNaN(value)) return value;
		if (!isNaN(value)) return parseFloat(value);
	}

	mapData(data) {
		const { id, ...rest } = data;

		let fields = Object.keys(rest).reduce((acc, key) => {
			const match = key.match(/fields\[(\d+)\]\.(.+)/);
			if (match) {
				const index = parseInt(match[1]);
				const fieldKey = match[2];

				acc[index] = { ...(acc[index] || {}), [fieldKey]: rest[key] };
				delete rest[key];
			}

			return acc;
		}, []);

		fields = fields?.map((field) => {
			Object.keys(field).forEach((key) => {
				field[key] = this.#parseValue(field[key]);
			});

			if (field.defaultValue === undefined || field.defaultValue === '') {
				field.defaultValue = null;
			} else {
				if (field.inputType === 16) {
					//if it is address input
					const address = JSON.parse(field.defaultValue);
					if (address) {
						field.defaultValue = {
							Level: address.level,
							Id: address.id,
						};
					}
				}

				//IF multiple select, or checkGroup explode the value and parse it
				if (field.inputType === 12) {
					if (Array.isArray(field.defaultValue)) {
						field.defaultValue = JSON.stringify(field.defaultValue);
					} else {
						field.defaultValue = JSON.stringify([field.defaultValue]);
					}
				} else if (field.inputType === 9) {
					if (field.defaultValue) {
						if (isNaN(field?.defaultValue) && field.defaultValue?.includes(',')) {
							field.defaultValue = JSON.stringify(field.defaultValue?.split(','));
						} else {
							field.defaultValue = JSON.stringify([field.defaultValue]);
						}
					} else field.defaultValue = null;
				} else if (field.defaultValue !== null) {
					field.defaultValue = JSON.stringify([field.defaultValue]);
				} else field.defaultValue = null;
			}

			field.order = field.order + 1;

			return field;
		});

		//map the fields without inputType
		fields = fields.map((field) => {
			const { inputType, ...rest } = field;
			return rest;
		});

		return { ...rest, fields };
	}

	async getMultiParentOptionsList(taskGroupIds = []) {
		const queryString = taskGroupIds.map((id) => `taskgroupids=${id}`).join('&');
		this.endpoint = 'taskgroups/templates';
		this.parentId = -1;
		const result = await super.getOptionsList(queryString + '&Page=1&PageSize=200');
		this.endpoint = endpoint;
		this.parentId = null;

		return {
			data: result?.data,
			meta: extractMeta(result, queryString),
		};
	}

	async getExcelTemplate(taskTemplateId) {
		return await this.api.post(`${this.endpoint}/${taskTemplateId}/export/excel`, null, {
			responseType: 'blob',
		});
	}

	async getOptionsList(queryString = null, allItemIsHidden = false) {
		return super.getOptionsList(queryString, allItemIsHidden, true);
	}
}

export default Service;
