import { BadgedListColumn, IdColumn, NameColumn, SimpleObjectColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class ComplexActionModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'complexAction';
		this.pluralModelName = 'complexActions';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(new BadgedListColumn('teams', { renderProps: { itemClassName: 'px-3' } }));
		this.columns.push(new SimpleObjectColumn('action'));
		this.assignColumnOrder();
	}
	isExportable() {
		return false;
	}
	areSelectsVisible() {
		return false;
	}
}

export default ComplexActionModel;
