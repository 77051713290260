import { useState, useEffect } from "react";

import { getRoomTypes } from "modules/addresses/services/addresses";

const CallTester = () => {
	const [name, setName] = useState("");
	const [code, setCode] = useState("");

	const [res, setRes] = useState({});

	const run = async () => {
		const r = await getRoomTypes();
		setRes(r.data);
	};

	return (
		<div className="p-4">
			<div className="flex flex-row gap-2">
				<input
					type="text"
					placeholder="Name"
					onChange={(e) => setName(e.target.value)}
				/>
				<input
					type="text"
					placeholder="Code"
					onChange={(e) => setCode(e.target.value)}
				/>
				<button onClick={run}>Run</button>
			</div>
			<div className="mt-4 border border-slate-500">
				{`tenant/enums/${name}/${code}`}
			</div>
			<div className="mt-4 w-full h-full border border-slate-500">
				<pre>{JSON.stringify(res, null, 2)}</pre>
			</div>
		</div>
	);
};

export default CallTester;
