import { useTranslations } from 'hooks';
const AccompaniedByNurseRender = () => {
	const { translate } = useTranslations();
	return (
		<span
			data-tooltip-variant='light'
			data-tooltip-id={`table-tooltip`}
			data-tooltip-content={translate('accompaniedByNurse')}
			className='hover:opacity-80'
		>
			<i class='ri-nurse-line text-lg px-1 text-primary-500'></i>
		</span>
	);
};
const TwoEmployeesRender = () => {
	const { translate } = useTranslations();
	return (
		<span
			data-tooltip-variant='light'
			data-tooltip-id={`table-tooltip`}
			data-tooltip-content={translate('twoEmployees')}
			className='hover:opacity-80'
		>
			<i class='ri-group-3-fill text-lg px-1 text-red-500'></i>
		</span>
	);
};

const DetailsRender = ({ value = null, row }) => {
	const customFields = row?.taskExtraDetails?.customFields || [];
	return (
		<div className=''>
			<div>
				{row.accompaniedByNurse && <AccompaniedByNurseRender />}
				{row.twoEmployees && <TwoEmployeesRender />}
			</div>
			<div>
				{customFields.map((field) => {
					const val = JSON.parse(field.value).join(', ');

					return (
						<table key={field.id} className=''>
							<tbody>
								<tr>
									<th
										scope='col'
										className=' text-left text-xs font-light text-gray-500 uppercase tracking-wider'
									>
										{field.name}:
									</th>
									<td className=' whitespace-nowrap font-bold text-sm pl-1 text-gray-500'>{val}</td>
								</tr>
							</tbody>
						</table>
					);
				})}
			</div>
		</div>
	);
};
export default DetailsRender;
