import { localStorageKeys } from 'config';
import tokenService from 'services/token/token.service';
import { getMyProfiles, getMyUser } from '../../../../../../../../auth/services/auth';
import UserService from '../services';

export const impersonateUser = async (userId, changeLanguage = async () => {}, storageHandler) => {
	const service = new UserService();
	const tokenInformation = await service.impersonate(userId);

	const userRaw = await getMyUser(tokenInformation.data.token);
	const user = userRaw.data;
	let settings = user.settings;
	delete user.settings;
	let profiles = await getMyProfiles(tokenInformation.data.token);

	const adminUser = tokenService.getUserObject();

	tokenService.removeUser();
	tokenService.setUser({
		...tokenInformation.data,
		user,
		settings,
		profiles: profiles?.data || [],
	});

	await storageHandler.fetchAllSchemas(userId);

	await clonePermissionsLocalStorage();
	await storeAdminUserTemporarily(adminUser);

	const keysToExclude = [
		localStorageKeys.USER_PERMISSIONS_TEMP_STORAGE_KEY,
		localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY,
		localStorageKeys.USER_PERMISSIONS_STORAGE_KEY,
		localStorageKeys.USER_STORAGE_KEY,
		localStorageKeys.LANGUAGES_STORAGE_KEY,
		localStorageKeys.TRANSLATION_LANGUAGE_CODE_STORAGE_KEY,
	];
	//remove some local storage keys
	Object.keys(localStorageKeys).forEach((key) => {
		if (keysToExclude.includes(localStorageKeys[key])) return;
		localStorage.removeItem(localStorageKeys[key]);
	});

	if (adminUser?.settings?.language) {
		await changeLanguage(adminUser.settings?.language || 'EN');
	}

	window.dispatchEvent(new Event('profileSwitch'));
	return;
};

/*Must use this one */
export const revertAdminUser = async (changeLanguage = async () => {}) => {
	const adminUser = JSON.parse(localStorage.getItem(localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY));
	tokenService.setUser(adminUser);

	if (adminUser?.settings?.language) {
		await changeLanguage(adminUser.settings?.language || 'EN');
	}

	revertTempPermissions();

	const keysToExclude = [
		localStorageKeys.USER_PERMISSIONS_STORAGE_KEY,
		localStorageKeys.USER_STORAGE_KEY,
		localStorageKeys.LANGUAGES_STORAGE_KEY,
		localStorageKeys.TRANSLATION_LANGUAGE_CODE_STORAGE_KEY,
	];
	//remove some local storage keys
	Object.keys(localStorageKeys).forEach((key) => {
		if (keysToExclude.includes(localStorageKeys[key])) return;
		localStorage.removeItem(localStorageKeys[key]);
	});

	window.dispatchEvent(new Event('profileSwitch'));
	return;
};

const clonePermissionsLocalStorage = async () => {
	const permissions = JSON.parse(localStorage.getItem(localStorageKeys.USER_PERMISSIONS_STORAGE_KEY));
	localStorage.setItem(localStorageKeys.USER_PERMISSIONS_TEMP_STORAGE_KEY, JSON.stringify(permissions));
};

const storeAdminUserTemporarily = async (user) => {
	localStorage.setItem(localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY, JSON.stringify(user));
};

const revertTempPermissions = () => {
	const permissions = JSON.parse(localStorage.getItem(localStorageKeys.USER_PERMISSIONS_TEMP_STORAGE_KEY));
	localStorage.setItem(localStorageKeys.USER_PERMISSIONS_STORAGE_KEY, JSON.stringify(permissions));
};
