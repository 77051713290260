export { default as PortalManager } from "./PortalProvider";
export { default as PortalContext } from "./PortalContext";

export const createPortals = (modules) => {
	const createdPortals = {};
	modules
		.filter((portals) => portals.length > 0)
		.forEach((portals) => {
			Object.assign(
				createdPortals,
				{},
				Object.fromEntries(
					portals.map((portal) => [
						portal.id,
						{
							element: portal.element,
							initialState: portal.initialState,
						},
					])
				)
			);
		});

	return createdPortals;
};
