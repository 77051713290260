import { IdColumn, MarkedBooleanColumn, NameColumn } from 'core/columns';
import { NumberColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';

class TaskTypeClass extends BaseModel {
	constructor() {
		super();
		this.modelName = 'taskType';
		this.pluralModelName = 'taskTypes';
	}
	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(new NumberColumn('advancedRequestTime', { sideLabel: 'min', defaultValue: 0 }));
		this.columns.push(new NumberColumn('expectedTaskTime', { sideLabel: 'min', defaultValue: 0 }));
		this.columns.push(new NumberColumn('anticipationTime', { sideLabel: 'min', defaultValue: 0 }));
		this.columns.push(
			new MarkedBooleanColumn('enabled', {
				label: 'enabled',
				trueValue: 'yes',
				falseValue: 'no',
			}),
		);
		this.assignColumnOrder();
	}
	areSelectsVisible() {
		return false;
	}
}

export default TaskTypeClass;
