import usePermissions from 'hooks/usePermissions';
import LocationsPermissions from 'modules/addresses/permissions';
import AssetsPermissions from 'modules/assets/permissions';
import PersonsPermissions from 'modules/persons/permissions';
import SettingsPermissions from 'modules/settings/permissions';
import TasksPermissions from 'modules/tasks/permissions';

const PermissionMap = () => {
	const { checkNestedPermissions } = usePermissions();
	const permissionsMap = {
		persons: PersonsPermissions(),
		tasks: TasksPermissions(),
		assets: AssetsPermissions(),
		locations: LocationsPermissions(),
		settings: SettingsPermissions(),
		// Add more module permissions as necessary...
	};

	const getPermissionMaps = (path) => {
		const keys = path.split('/').filter((key) => key !== '');
		let current = permissionsMap;

		for (const key of keys) {
			if (current[key] === undefined) {
				return null;
			}
			current = current[key];
		}

		return current;
	};

	const findPageId = (pathName, pages) => {
		for (const page of pages) {
			if (pathName.startsWith(page.id)) {
				return page.id;
			}
		}

		if (pages.length > 0) {
			return pages[0].id;
		}
		return null;
	};

	return {
		permissionsMap,
		getPermissionMaps,
		checkNestedPermissions,
		findPageId,
	};
};
export default PermissionMap;
