import AbstractService from 'services/Service';

const endpoint = 'users/profiles';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	mapData = (data) => {
		const {
			id,
			name,
			description,
			taskGroupIds,
			taskTemplateIds,
			departmentIds,
			permissionGroupIds,
			viewPatientsLevel,
			...rest
		} = data;
		let permissions = {};
		Object.keys(rest).map((key) => {
			if (key !== 'name' && key !== 'description') {
				let permissionSplit = key.split('__');
				let permissionKey = permissionSplit[0];
				let permissionValue = permissionSplit[1];
				if (!permissions[permissionKey]) permissions[permissionKey] = [];
				if (rest[key] === 'true') permissions[permissionKey].push(parseInt(permissionValue));
			}
		});
		//remove orphan permission which are empty
		Object.keys(permissions).map((key) => {
			if (permissions[key].length === 0) delete permissions[key];
		});

		return {
			name,
			description,
			customPermissions: permissions,
			taskGroupIds: taskGroupIds,
			departmentIds: departmentIds,
			permissionGroupIds: permissionGroupIds,
			taskTemplateIds: taskTemplateIds,
			viewPatientsLevel: parseInt(viewPatientsLevel),
		};
	};
}
export default Service;
