import React, { useEffect, useState } from "react";
import flags from "assets/flags";
import { useTranslations } from "hooks";

const Item = ({ flag, lang, selected = false, onClick = () => {} }) => {
	return (
		<div
			onClick={onClick}
			className={`cursor-pointer flex flex-row items-center pt-2 mb-4 ${
				selected ? "" : "opacity-50"
			}`}
		>
			<img src={flag} className="w-6 h-6 mr-2" alt={`lang-${lang}`} />
			<div className="text-lg">{lang}</div>
			<div className="flex flex-1"></div>
			{selected && <i className="ri-check-line text-lg"></i>}
		</div>
	);
};

const Language = ({ selectAndNext = () => {} }) => {
	const { translate, getLanguages, activeLanguageCode } = useTranslations();
	const [selected, setSelected] = useState(activeLanguageCode || "EN");
	const [languages, setLanguages] = useState([]);

	const fetchData = async () => {
		const l_languages = await getLanguages();
		setLanguages(l_languages);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="flex flex-col pt-5 pb-0 w-full h-full">
			<div className="flex flex-col items-center mb-6">
				<img
					src={require("../../../assets/language.png")}
					className="w-20 h-20 mb-4"
					alt="language icon"
				/>
				<h1 className="text-4xl font-medium">
					{translate("language")}
				</h1>
			</div>
			<div className="flex justify-center overflow-y-auto">
				<div className="flex flex-col w-2/3 ">
					{languages.map((lang) => (
						<Item
							key={lang.languageCode}
							flag={flags(lang.languageCode)}
							lang={lang.name}
							selected={selected === lang.languageCode}
							onClick={() => setSelected(lang.languageCode)}
						/>
					))}
				</div>
			</div>
			<div className="flex flex-row-reverse pt-2 pb-2">
				<div
					className="cursor-pointer group font-medium flex px-3 py-2 hover:pr-2 justify-center items-center transition-all"
					onClick={() => selectAndNext(selected)}
				>
					{translate("next")}{" "}
					<i className="ri-arrow-right-line ml-2 text-xl "></i>
				</div>
			</div>
		</div>
	);
};

export default Language;
