import Card from 'components/ui/Card';
import { useEffect, useRef, useState } from 'react';

import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { useTranslations } from 'hooks';

import { Button, CheckBox, Input } from 'components/ui/Input';
import { useCrud } from 'hooks';
import TenantService from '../../services';

const DispatcherSettingsView = ({ permissions }) => {
	const [settings, setSettings] = useState(null);

	const myForm = useRef(null);
	const { translate } = useTranslations();
	const service = new TenantService();

	const [isLoading, setIsLoading] = useState(false);
	const isView = (permissions.View && !permissions.Update) || false;

	const { create, getOne } = useCrud({
		create: (data) => service.putDispatcherSettings(data),
		getOne: (id) => service.getDispatcherSettings(),
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await postSettings(formDataObject, (res) => {
			setIsLoading(false);
			getSettings(false);
		});
	};

	const postSettings = async (data, onSuccess = () => {}) => {
		if (data) {
			return await create(data, {
				creationSuccessMessage: 'tenantDispatcherSettingsUpdatedSuccessfully',
			}).then((res) => {
				onSuccess(res);
			});
		}
		return null;
	};

	useEffect(() => {
		getSettings();
	}, []);

	const getSettings = async () => {
		setIsLoading(true);
		const res = await getOne();
		setSettings(res);
		setIsLoading(false);
	};

	return (
		<Card isLoading={isLoading} className='mt-2' collapsible header={'dispatcherSettings'} defaultOpen={true}>
			<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
				<FormEntry label={'enabled'}>
					<CheckBox isView={isView} name='enabled' label='enabled' selected={settings?.enabled} />
				</FormEntry>
				<FormEntry label={'dispatchTaskToTheSameGender'}>
					<CheckBox
						isView={isView}
						name='dispatchTaskToTheSameGender'
						label='dispatchTaskToTheSameGender'
						selected={settings?.dispatchTaskToTheSameGender}
					/>
				</FormEntry>
				<FormEntry label={'dispatcherRunIntervalInSeconds'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'dispatcherRunIntervalInSeconds'}
						type='number'
						min={0}
						required={true}
						name='dispatcherRunIntervalInSeconds'
						defaultValue={settings?.dispatcherRunIntervalInSeconds}
						sideLabel={'seconds'}
					/>
				</FormEntry>
				<FormEntry label={'cancelTaskAllocationWhenEmployeeOnBreak'}>
					<CheckBox
						isView={isView}
						name='cancelTaskAllocationWhenEmployeeOnBreak'
						label='cancelTaskAllocationWhenEmployeeOnBreak'
						selected={settings?.cancelTaskAllocationWhenEmployeeOnBreak}
					/>
				</FormEntry>
				<FormEntry label={'cancelTaskAllocationWhenEmployeeLogsOut'}>
					<CheckBox
						isView={isView}
						name='cancelTaskAllocationWhenEmployeeLogsOut'
						label='cancelTaskAllocationWhenEmployeeLogsOut'
						selected={settings?.cancelTaskAllocationWhenEmployeeLogsOut}
					/>
				</FormEntry>

				<FormEntry label={'assignNewTaskToEmployeeAfterXMinutesWithoutTask'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'assignNewTaskToEmployeeAfterXMinutesWithoutTask'}
						type='number'
						min={0}
						required={true}
						name='assignNewTaskToEmployeeAfterXMinutesWithoutTask'
						defaultValue={settings?.assignNewTaskToEmployeeAfterXMinutesWithoutTask}
						sideLabel={'mins'}
					/>
				</FormEntry>
				<FormEntry label={'askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes'}
						type='number'
						min={0}
						required={true}
						name='askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes'
						defaultValue={settings?.askEmployeeIfSecondEmployeeIsStillNeededAfterXMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>
				<InternalFormDivider>{translate('autoLogoutUserAndSendTasksToRequestSettings')}</InternalFormDivider>
				<FormEntry label={'enabledLeftOverEmployee'}>
					<CheckBox
						isView={isView}
						name='enabledLeftOverEmployee'
						label='enabledLeftOverEmployee'
						selected={settings?.enabledLeftOverEmployee}
					/>
				</FormEntry>
				<FormEntry label={'leftOverEmployeeRunIntervalInMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'leftOverEmployeeRunIntervalInMinutes'}
						type='number'
						min={0}
						required={true}
						name='leftOverEmployeeRunIntervalInMinutes'
						defaultValue={settings?.leftOverEmployeeRunIntervalInMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>
				<FormEntry label={'stillAvailableWhenUnrespondedTimePassedXMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'stillAvailableWhenUnrespondedTimePassedXMinutes'}
						type='number'
						min={0}
						required={true}
						name='stillAvailableWhenUnrespondedTimePassedXMinutes'
						defaultValue={settings?.stillAvailableWhenUnrespondedTimePassedXMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>
				<FormEntry label={'resetTaskWhenUnrespondedTimePassedXMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'resetTaskWhenUnrespondedTimePassedXMinutes'}
						type='number'
						min={0}
						required={true}
						name='resetTaskWhenUnrespondedTimePassedXMinutes'
						defaultValue={settings?.resetTaskWhenUnrespondedTimePassedXMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>

				<InternalFormDivider>{translate('autoCompleteTaskSettings')}</InternalFormDivider>
				<FormEntry label={'enabledLeftOverTask'}>
					<CheckBox
						isView={isView}
						name='enabledLeftOverTask'
						label='enabledLeftOverTask'
						selected={settings?.enabledLeftOverTask}
					/>
				</FormEntry>
				<FormEntry label={'leftOverTaskRunIntervalInMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'leftOverTaskRunIntervalInMinutes'}
						type='number'
						min={0}
						required={true}
						name='leftOverTaskRunIntervalInMinutes'
						defaultValue={settings?.leftOverTaskRunIntervalInMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>
				<FormEntry label={'stillInTransitWhenArrivalTimePassedXMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'stillInTransitWhenArrivalTimePassedXMinutes'}
						type='number'
						min={0}
						required={true}
						name='stillInTransitWhenArrivalTimePassedXMinutes'
						defaultValue={settings?.stillInTransitWhenArrivalTimePassedXMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>
				<FormEntry label={'completeTaskIfArrivalTimeHasPassedWithXMinutes'}>
					<Input
						isView={isView}
						containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
						placeholder={'completeTaskIfArrivalTimeHasPassedWithXMinutes'}
						type='number'
						min={0}
						required={true}
						name='completeTaskIfArrivalTimeHasPassedWithXMinutes'
						defaultValue={settings?.completeTaskIfArrivalTimeHasPassedWithXMinutes}
						sideLabel={'mins'}
					/>
				</FormEntry>

				{permissions.Update && (
					<tr>
						<td colSpan={2}>
							<div className='text-right p-10 pb-5'>
								<Button type='submit' isLoading={isLoading}>
									<i className='ri-save-line mr-2'></i>
									{translate('saveChanges')}
								</Button>
							</div>
						</td>
					</tr>
				)}
			</Form>
		</Card>
	);
};
export default DispatcherSettingsView;
