import StorageHandler from 'core/utils/StorageHandler';

class BaseModel {
	constructor() {
		// Prevents direct instantiation of the BaseModel class
		if (new.target === BaseModel) {
			throw new TypeError('Cannot construct BaseModel instances directly');
		}
		// Basic properties of the model
		this.modelName = '';
		this.pluralModelName = '';
		this.columns = [];
		this.hasModifiedColumns = false;
		this.storageHandler = StorageHandler();
		// Initializes columns for the model
		this.initializeColumns();
		this.canBeExported = false;
		this.canBeRefreshed = false;
	}

	/**
	 * Returns the singular name of the model.
	 * @returns {string} The model's singular name.
	 */
	getModelName() {
		return this.modelName;
	}

	/**
	 * Returns the plural name of the model.
	 * @returns {string} The model's plural name.
	 */
	getPluralModelName() {
		return this.pluralModelName;
	}

	/**
	 * Initializes the columns for the model. Must be overridden by subclasses.
	 * Throws an error if called directly on the BaseModel.
	 */
	initializeColumns() {
		throw new Error('Must override initializeColumns method');
	}

	/**
	 * Returns the names of the columns for the model.
	 * @returns {Array<string>} An array of column names.
	 */
	getColumnNames() {
		return this.columns.map((column) => column.name);
	}

	/**
	 * Returns the columns of the model.
	 * @returns {Array<Object>} An array of column objects.
	 */
	getColumns() {
		return this.columns;
	}

	/**
	 * Checks if any column in the model is filterable.
	 * @returns {boolean} True if at least one column is filterable, otherwise false.
	 */
	isFilterable() {
		return this.columns.some((column) => column.isFilterable());
	}

	/**
	 * Determines if the model's data can be refreshed.
	 * @returns {boolean} Always returns false. Override in subclass for different behavior.
	 */
	isRefreshable() {
		return this.canBeRefreshed;
	}

	setRefreshable(value) {
		this.canBeRefreshed = value;
	}

	/**
	 * Determines if the model columns can be modified.
	 * @returns {boolean} True if the model is modifiable, otherwise false.
	 */
	isModifiable() {
		return true;
	}

	/**
	 * Determines if the model's data can be exported.
	 * @returns {boolean} True if the data is exportable, otherwise false.
	 */
	isExportable() {
		return this.canBeExported;
	}

	/**
	 * Sets the exportable property of the model.
	 *
	 * @param {boolean} value - The value to set the exportable property to.
	 * @returns {void}
	 */
	setExportable(value) {
		this.canBeExported = value;
	}

	/**
	 * Determines if headers are visible in the model's representation.
	 * @returns {boolean} True if headers are visible, otherwise false.
	 */
	areHeadersVisible() {
		return true;
	}

	/**
	 * Determines if selects are visible in the model's representation.
	 * @returns {boolean} True if selects are visible, otherwise false.
	 */
	areSelectsVisible() {
		return true;
	}

	async isModified() {
		this.hasModifiedColumns = await this.storageHandler.hasSchema(this.getModelName());
		return this.hasModifiedColumns;
	}

	/**
	 * Assigns an order parameter to each column based on its index.
	 */
	assignColumnOrder() {
		this.columns.forEach((column, index) => {
			column.order = index + 1;
		});
	}

	getDefaultColumns() {
		return this.columns;
	}

	async getCustomColumns() {
		const schema = await this.storageHandler.getSchema(this.getModelName());

		if (schema) {
			const updatedColumns = this.getColumns().map((column) => {
				const instance = schema.find((col) => col.name === column.name);
				if (!instance) return column;

				column.hidden = instance.hidden;
				column.order = instance.order;
				return column;
			});

			if (updatedColumns.length === 0) {
				return this.getColumns();
			} else return updatedColumns.sort((a, b) => a.order - b.order);
		}
		return this.getColumns();
	}
}

export default BaseModel;
