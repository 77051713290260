import { BadgedListColumn, MarkedBooleanColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class ADConfigurationModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'ADConfiguration';
		this.pluralModelName = 'ADConfigurations';
	}

	initializeColumns() {
		this.columns.push(new NameColumn());
		this.columns.push(new BadgedListColumn('userProfiles'));
		this.columns.push(
			new MarkedBooleanColumn('blockNormalLogin', {
				label: 'normalLogin',
				trueValue: 'blocked',
				falseValue: 'allowed',
				trueClass: 'text-red-500',
				falseClass: 'text-green-500',
			}),
		);

		this.assignColumnOrder();
	}

	isExportable() {
		return false;
	}

	areSelectsVisible() {
		return false;
	}
}

export default ADConfigurationModel;
