import { TruncatedTextRender } from '.';

const EmailRender = ({ value, num = 24 }) => {
	if (!value) return '';
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<a
				className='text-primary-400 hover:text-primary-300'
				target='_blank'
				href={`mailto:${value}`}
				rel='noreferrer'
			>
				<TruncatedTextRender value={value} num={num} />
			</a>
		</div>
	);
};
export default EmailRender;
