import { BaseColumn } from './baseColumns';

class ActionColumn extends BaseColumn {
	constructor(render, options = {}) {
		super(options.name || 'action', 'string');
		this.key = options.key || 'action';
		this.label = options.label || 'action';
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = null;
		this.hidden = options.hidden || false;
		this.render = render || null;
		this.filter = null;
		this.order = options.order || 0;
	}
}
export default ActionColumn;
