import { useState, useEffect } from "react";
import PermissionsContext from "./PermissionsContext";

import { runAction } from "modules/utils";

const PermissionsProvider = ({ children }) => {
	const [permissions, setPermissions] = useState(null);

	const loadPermissions = async () => {
		const permissions = await runAction("auth", "permissions");
		setPermissions(permissions);
	};

	useEffect(() => {
		const handleProfileSwitch = () => {
			loadPermissions();
		};

		window.addEventListener("profileSwitch", handleProfileSwitch);

		if (!permissions) loadPermissions();

		return () => {
			window.removeEventListener("profileSwitch", handleProfileSwitch);
		};
	}, [permissions]);

	return (
		<PermissionsContext.Provider value={permissions}>
			{children}
		</PermissionsContext.Provider>
	);
};

export default PermissionsProvider;
