import { ActionColumn, BadgedListColumn, EmailColumn, IdColumn, MarkedBooleanColumn, NameColumn } from 'core/columns';
import { StringColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
import ImpersonateButton from '../parts/Impersonate';

class UserModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'user';
		this.pluralModelName = 'users';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(
			new StringColumn('lastName', {
				label: 'surname',
				filter: {
					type: 'text',
					key: 'lastName',
				},
			}),
		);
		this.columns.push(new EmailColumn());
		this.columns.push(
			new StringColumn('userName', {
				label: 'username',
				filter: { type: 'text', key: 'userName' },
			}),
		);
		this.columns.push(new BadgedListColumn('profiles'));
		this.columns.push(
			new MarkedBooleanColumn('lockoutEnabled', {
				label: 'status',
				trueValue: 'deactivated',
				falseValue: 'active',
				trueClass: 'text-red-500',
				falseClass: 'text-green-500',
				filter: {
					type: 'select',
					key: 'lockoutEnabled',
					options: [
						{ label: 'all', value: '' },
						{ label: 'active', value: false },
						{ label: 'deactivated', value: true },
					],
					defaultValue: false,
				},
			}),
		);
		this.columns.push(new BadgedListColumn('teams', { renderProps: { itemClassName: 'px-3' } }));

		this.columns.push(
			new ActionColumn((d, itemData) => <ImpersonateButton item={itemData} />, { label: 'impersonate' }),
		);

		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}
export default UserModel;
