import React from 'react';
import { Navigate, useLocation, useOutletContext, useRoutes } from 'react-router-dom';
import LinkTabs from './components/LinkTabs';
import routes from './routes';

import usePermissions from 'hooks/usePermissions';
const User = () => {
	const location = useLocation();
	const pathName = location.pathname; // Use this to dynamically render pages

	const { permissionsMap } = useOutletContext();

	const { checkNestedPermissions } = usePermissions();

	let localRoutes = [...routes];

	localRoutes = localRoutes.filter((route) => {
		const permissions = permissionsMap[route.key];
		return checkNestedPermissions(permissions);
	});

	const routing = useRoutes([
		{ path: '/', element: <Navigate to={localRoutes[0]?.path} replace /> },
		...localRoutes.map((item, index) => {
			let path = item.path;
			let parts = path.split('/');
			let newPath = '/' + parts[parts.length - 1];

			const permissions = permissionsMap[item.key];
			const elementWithPermissions = React.cloneElement(item.element, {
				permissions,
			});

			return {
				...item,
				path: newPath,
				element: elementWithPermissions,
			};
		}),
	]);
	return (
		<div>
			{<LinkTabs activeItem={pathName} items={localRoutes} />}
			<div>{routing}</div>
		</div>
	);
};
export default User;
