import { useEffect } from 'react';

import { withSuspense } from 'hoc';
import { useToolbar } from 'hooks';
import { useOutletContext } from 'react-router-dom';
import PatientTable from './parts/PatientTable';

const Patients = () => {
	const { crumbsBuilder } = useToolbar();

	useEffect(() => {
		crumbsBuilder.addPath('/persons/patients', 'patients');

		crumbsBuilder.build();
	}, []);

	const { permissionsMap } = useOutletContext();

	return (
		<div className='grid grid-cols-1 gap-2'>
			<PatientTable permissions={permissionsMap.Patients} />
		</div>
	);
};

export default withSuspense(Patients);
