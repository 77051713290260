import { IdColumn, NameColumn } from 'core/columns';
import { NumberColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';
import BadgeRender from 'core/renders/components/BadgeRender';
class TaskTeamModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'taskTeam';
		this.pluralModelName = 'taskTeams';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
				render: (value, row) => (
					<BadgeRender value={{ name: value, color: row.color }} itemClassName={'px-3'} />
				),
			}),
		);
		this.columns.push(new NumberColumn('openTime', { defaultValue: 0 }));
		this.columns.push(new NumberColumn('openReadyTasksCount', { defaultValue: 0 }));
		this.columns.push(new NumberColumn('completedTasksCount', { defaultValue: 0 }));
		this.columns.push(new NumberColumn('cancelledTasksCount', { defaultValue: 0 }));
		this.columns.push(new NumberColumn('inProgressTasksCount', { defaultValue: 0 }));
		this.columns.push(new NumberColumn('openNotReadyTasksCount', { defaultValue: 0 }));
		this.columns.push(new NumberColumn('loggedInEmployeesCount'));

		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}

	isExportable() {
		return false;
	}
}
export default TaskTeamModel;
