import React, { useState } from "react";
import { Input } from "components/ui/Input";

const DateRangePicker = ({ startDate, endDate, onDateChange }) => {
	const [selectedDate, setSelectedDate] = useState({
		startDate: startDate,
		endDate: endDate,
	});

	const handleDateChange = (date) => {
		setSelectedDate(date);
		onDateChange(date);
	};

	return (
		<div className="flex flex-col items-center ">
			<Input
				className="py-1"
				type="date"
				placeholder="From Date"
				defaultValue={startDate}
				onChange={(e) => {
					handleDateChange({
						startDate: e.target.value,
						endDate: selectedDate.endDate,
					});
				}}
			/>

			<Input
				className="py-1"
				type="date"
				placeholder="TO Date"
				defaultValue={endDate}
				onChange={(e) => {
					handleDateChange({
						startDate: selectedDate.startDate,
						endDate: e.target.value,
					});
				}}
			/>
		</div>
	);
};

export default DateRangePicker;
