import AdjustStaRule from '../pages/General/pages/AdjustStaRule/parts/AdjustStaRuleTable';
import ChangeTaskTypeRule from '../pages/General/pages/ChangeTaskTypeRule/parts/ChangeTaskTypeRuleTable';
import GroupingTaskRule from '../pages/General/pages/GroupingTaskRule/parts/GroupingTaskRuleTable';
import MonitorDoubleTaskRule from '../pages/General/pages/MonitorDoubleTaskRule/parts/MonitorDoubleTaskRuleTable';
import PriorityRule from '../pages/General/pages/PriorityRule/parts/PriorityRuleTable';

import AccompaniedByNurseSetting from '../../Rules/pages/DefaultSettings/pages/AccompaniedByNurseSetting/parts/AccompaniedByNurseSettingTable';
import NoReturnSetting from '../../Rules/pages/DefaultSettings/pages/NoReturnSetting/parts/NoReturnSettingTable';
import ProvisionalSetting from '../../Rules/pages/DefaultSettings/pages/Provisional/parts/ProvisionalSettingTable';
import TwoPortersSetting from '../../Rules/pages/DefaultSettings/pages/TwoPorters/parts/TwoPorterSettingTable';
const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: 'priorityRule',
				key: 'PriorityRule',
				hash: '#priorityRule',
				component: PriorityRule,
			},
			{
				name: 'adjustStaRule',
				key: 'AdjustStaRule',
				hash: '#adjustStaRule',
				component: AdjustStaRule,
			},
			{
				name: 'groupingTaskRule',
				key: 'GroupingTaskRule',
				hash: '#groupingTaskRule',
				component: GroupingTaskRule,
			},
			{
				name: 'changeTaskTypeRule',
				key: 'ChangeTaskTypeRule',
				hash: '#changeTaskTypeRule',
				component: ChangeTaskTypeRule,
			},
			{
				name: 'monitorDoubleTaskRule',
				key: 'MonitorDoubleTaskRule',
				hash: '#monitorDoubleTaskRule',
				component: MonitorDoubleTaskRule,
			},
		],
	},
	{
		header: 'defaultSettings',
		key: 'DefaultSettings',
		items: [
			{
				name: 'noReturn',
				key: 'NoReturn',
				hash: '#no-return',
				component: NoReturnSetting,
			},
			{
				name: 'twoPorters',
				key: 'TwoPorters',
				hash: '#two-porters',
				component: TwoPortersSetting,
			},
			{
				name: 'provisional',
				key: 'Provisional',
				hash: '#provisional',
				component: ProvisionalSetting,
			},
			{
				name: 'accompaniedByNurse',
				key: 'AccompaniedByNurse',
				hash: '#accompanied-by-nurse',
				component: AccompaniedByNurseSetting,
			},
		],
	},
];
export default routes;
