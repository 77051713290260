import { DescriptionColumn, IdColumn, NameColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class DepartmentModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'department';
		this.pluralModelName = 'departments';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(new NameColumn());
		this.columns.push(new DescriptionColumn());
		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}
}
export default DepartmentModel;
