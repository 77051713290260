import ConnectionView from './ADSetup/parts/ConnectionView';
import EntraIdConnectionView from './EntraIdSetup/parts/EntraIdConnectionView';
const SetupView = ({ permissions }) => {
	return (
		<div className='grid grid-cols-1 gap-2 '>
			<ConnectionView permissions={permissions} />
			<EntraIdConnectionView permissions={permissions} />
		</div>
	);
};
export default SetupView;
