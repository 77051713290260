import { useAside, useTranslations } from 'hooks';
import { forwardRef } from 'react';

function capitalizeFirstChar(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const CrudOperations = ({
	create,
	update,
	remove = null,
	fetchAndLoad = () => {},
	service = null,
	form = null,
	componentName = 'component',
	permissions = null,
	fetchAndLoadParams = [],
	afterSave = null,
	twoStepDeleteMessage = null,
	closeOnOutsideClick = true,
	hardSaveMessage = null,
	hardSaveVariable = 'createAnyWay',
	formWidth = 32,
}) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	const locAfterSave = (res) => {
		if (afterSave !== null) {
			afterSave(res);
		} else {
			asideBuilder.setOpen(false);
			asideBuilder.reset();
			fetchAndLoad();
		}
	};

	const openCreate = (extraProps = null) => {
		asideBuilder.setResetOnClose(true);

		asideBuilder.setRight(false);
		asideBuilder.setOpen(true);
		asideBuilder.setWidth(formWidth);
		asideBuilder.setTitle(translate('new' + capitalizeFirstChar(componentName)));
		asideBuilder.setComponent(form);
		asideBuilder.setAutoClose(false);
		asideBuilder.setAutoClear(false);
		asideBuilder.setCloseOnOutsideClick(closeOnOutsideClick);
		asideBuilder.setComponentProps({
			data: {},
			service: service,
			...extraProps,
		});
		asideBuilder.setComponentKey(componentName + '-new-' + new Date());
		asideBuilder.setSaveCallback(saveAndLoad);
		asideBuilder.setSecondaryComponent(null);
		asideBuilder.setOnClose(() => asideBuilder.reset());

		asideBuilder.build();
	};

	const openEdit = (row, extraProps = null) => {
		asideBuilder.setResetOnClose(true);
		asideBuilder.setRight(false);
		asideBuilder.setOpen(true);
		asideBuilder.setWidth(formWidth);
		asideBuilder.setTitle(row.name);
		asideBuilder.setComponent(form);
		asideBuilder.setComponentKey(componentName + '-' + row.id);
		asideBuilder.setAutoClose(false);
		asideBuilder.setCloseOnOutsideClick(closeOnOutsideClick);
		asideBuilder.setComponentProps({
			data: row,
			service: service,
			...extraProps,
		});
		asideBuilder.setSaveCallback(saveAndLoad);
		if (remove && permissions?.Delete) {
			asideBuilder.setSecondaryComponent(SecondaryButton);
			asideBuilder.setSecondaryComponentKey(componentName + '-delete-' + row.id);
			asideBuilder.setSecondaryComponentProps(row);
		}
		asideBuilder.setOnClose(() => asideBuilder.reset());

		asideBuilder.build();
	};

	const openView = (row, extraProps = null) => {
		asideBuilder.setResetOnClose(true);
		asideBuilder.setRight(false);
		asideBuilder.setOpen(true);
		asideBuilder.setWidth(formWidth);
		asideBuilder.setTitle(row.name);
		asideBuilder.setComponent(form);
		asideBuilder.setComponentKey(componentName + '-' + row.id);
		asideBuilder.setAutoClose(false);
		asideBuilder.setCloseOnOutsideClick(closeOnOutsideClick);
		asideBuilder.setComponentProps({
			data: row,
			service: service,
			isView: true,
			...extraProps,
		});
		asideBuilder.setSaveCallback(saveAndLoad);
		asideBuilder.setSecondaryComponent(null);
		asideBuilder.setOnClose(() => asideBuilder.reset());
		asideBuilder.hideSaveButton(true);

		asideBuilder.build();
	};

	const openClone = (row, extraProps = null) => {
		asideBuilder.setResetOnClose(true);
		asideBuilder.setRight(false);
		asideBuilder.setOpen(true);
		asideBuilder.setWidth(formWidth);
		asideBuilder.setTitle(row.name);
		asideBuilder.setComponent(form);
		asideBuilder.setComponentKey(componentName + '-clone-' + row.id);
		asideBuilder.setAutoClose(false);
		asideBuilder.setCloseOnOutsideClick(closeOnOutsideClick);
		asideBuilder.setComponentProps({
			data: row,
			service: service,
			isClone: true,
			...extraProps,
		});
		asideBuilder.setSaveCallback(saveAndLoad);
		asideBuilder.setOnClose(() => asideBuilder.reset());

		asideBuilder.build();
	};

	const SecondaryButton = forwardRef((props, ref) => {
		return (
			<button
				onClick={() => removeItem(props)}
				className=' w-10 h-8 flex items-center justify-center  rounded-lg border border-black hover:bg-black hover:bg-opacity-5 h-39 w-41 top-919 left-90 focus:ring-2 text-lg focus:ring-gray-400 active:bg-gray-400'
			>
				<i className='ri-delete-bin-line'></i>
			</button>
		);
	});

	const removeItem = (props) => {
		remove(
			props?.id,
			true,
			props?.name,
			{
				removeSuccessMessage: componentName + 'RemovedSuccessfully',
				removePromptMessage: 'youAreAboutToDeleteThis',
			},
			twoStepDeleteMessage,
		).then((res) => {
			asideBuilder.setOpen(false);
			asideBuilder.reset();
			fetchAndLoad(...fetchAndLoadParams);
		});
	};

	const saveAndLoad = async (data) => {
		if (data) {
			if (isNaN(data.id)) {
				await create(
					data,
					{
						creationSuccessMessage: componentName + 'CreatedSuccessfully',
					},
					hardSaveMessage,
					hardSaveVariable,
				).then((res) => {
					if (res?.isConfirmed !== false) {
						locAfterSave(res);
					}
				});
			} else {
				await update(
					data.id,
					data,
					{
						updateSuccessMessage: componentName + 'UpdatedSuccessfully',
					},
					hardSaveMessage,
					hardSaveVariable,
				).then((res) => {
					if (res?.isConfirmed !== false) {
						locAfterSave(res);
					}
				});
			}

			// fetchAndLoad();
		}
	};
	return { openCreate, openEdit, openClone, openView };
};

export default CrudOperations;
