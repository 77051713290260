import AbstractService from 'services/Service';
const endpoint = 'complextaskactions';

class ComplexTaskActionsService extends AbstractService {
	constructor(typeId = null) {
		super();
		this.type = typeId;
		this.endpoint = endpoint;
	}

	async getAll(queryString) {
		this.endpoint = endpoint + '/types/' + this.type;

		try {
			return await super.getAll(queryString);
		} finally {
			this.endpoint = endpoint;
		}
	}

	async create(data) {
		this.endpoint = endpoint + '/' + this.type;

		try {
			return await super.create(data);
		} finally {
			this.endpoint = endpoint;
		}
	}

	async update(id, data) {
		this.endpoint = endpoint + '/' + this.type;

		try {
			return await super.update(id, data);
		} finally {
			this.endpoint = endpoint;
		}
	}

	getTypeId() {
		return this.type;
	}
	setTypeId(type) {
		this.type = type;
	}
}
export default ComplexTaskActionsService;
