import Category from '../pages/categories/parts/CategoryTable';
const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			{
				name: 'categories',
				key: 'Category',
				hash: '#category',
				component: Category,
			},
		],
	},
];
export default routes;
