import { localStorageKeys } from "config";
import { getTranslations, getLanguages as getApiLanguages } from "../services";

const getLanguages = async () => {
	let languages = localStorage.getItem(
		localStorageKeys.LANGUAGES_STORAGE_KEY
	);
	if (languages && JSON.parse(languages)) {
		return JSON.parse(languages);
	} else {
		const languages = await getApiLanguages();
		localStorage.setItem(
			localStorageKeys.LANGUAGES_STORAGE_KEY,
			JSON.stringify(languages)
		);
		return languages;
	}
};

export const setActiveLanguageCode = (languageCode) => {
	localStorage.setItem(
		localStorageKeys.TRANSLATION_LANGUAGE_CODE_STORAGE_KEY,
		JSON.stringify(languageCode)
	);
};

export const getActiveLanguageCode = async () => {
	let languageCode = localStorage.getItem(
		localStorageKeys.TRANSLATION_LANGUAGE_CODE_STORAGE_KEY
	);
	if (languageCode && JSON.parse(languageCode)) {
		return JSON.parse(languageCode);
	} else {
		return "EN";
	}
};

const utils = {
	getTranslations,
	setActiveLanguageCode,
	getActiveLanguageCode,
	getLanguages,
};

export default utils;
