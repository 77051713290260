import AbstractService from "services/Service";
import { extractMeta } from "utils/pagination";
const endpoint = "taskgroups/{parentId}/documentations";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
		this.parentId = null;
	}

	setParent(id) {
		this.endpoint = endpoint.replace("{parentId}", id);
		this.parentId = id;
	}

	beforeEach() {
		this.assertIfParentIdIsSet();
		return true;
	}

	assertIfParentIdIsSet() {
		if (this.parentId === null) {
			throw new Error("Parent ID is not set");
		}
	}

	mapData(data) {
		const { id, ...rest } = data;
		return {
			...rest,
			source: parseInt(data?.source),
		};
	}

	async getOptionsList(queryString = "") {
		if (this.parentId) {
			return await super.getOptionsList(queryString);
		}
		return {
			data: [],
			meta: extractMeta(
				{
					page: 1,
					pageSize: 10,
					data: [],
					message: "",
					succeeded: true,
				},
				queryString
			),
		};
	}
}
export default Service;
