import { useEffect, useState } from 'react';

import { useTranslations } from 'hooks';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates//pages/TaskTemplates/services';

const TaskGroupItem = ({
	id,
	name,
	moduleName,
	active = false,
	onTaskGroupSelect = () => {},
	onSelect = () => {},
	color = '',
	icon = null,
}) => {
	const { translate } = useTranslations();

	const taskTemplateService = new TaskTemplateService();
	taskTemplateService.setParent(id);

	const [isOpen, setIsOpen] = useState(false);
	const [taskTemplates, setTaskTemplates] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (active) {
			setIsOpen(true);
			onTaskGroupSelect(id);
			fetchTaskTemplates();
		}
	}, []);

	const onClickHandler = () => {
		if (isOpen) {
			onTaskGroupSelect(null);
		} else {
			onTaskGroupSelect(id);
			fetchTaskTemplates();
		}
		setIsOpen(!isOpen);
	};

	const fetchTaskTemplates = async () => {
		setIsLoading(true);
		taskTemplateService.getOptionsList().then((res) => {
			setIsLoading(false);

			setTaskTemplates(res.data);
		});
	};

	useEffect(() => {
		if (!active) {
			setIsOpen(false);
		}
	}, [active]);

	return (
		<div>
			<div
				style={{ backgroundColor: active ? `${color}05` : '' }}
				className={`group cursor-pointer p-2.5  bg-gray-100 rounded-md mb-2 ${
					active ? 'opacity-100' : 'opacity-80 hover:opacity-100 transition-all duration-200 ease-in-out'
				} `}
				onClick={() => onClickHandler()}
			>
				<div
					className=' flex flex-row items-center'
					style={{
						background: active ? `linear-gradient(.95turn, transparent,${color}10, transparent)` : '',
					}}
				>
					<div
						style={{
							backgroundColor: !active ? color : `${color}20`,
						}}
						className={`py-[25px] mr-3 rounded-sm px-[2px]`}
					/>
					<div className='flex flex-1 w-full flex-col'>
						<div className='text-xs opacity-60'>{translate(moduleName)}</div>
						<div title={name} className='font-medium'>
							{name}
						</div>
					</div>
					{/* //icon to the right */}
					{icon && (
						<div className='pr-2'>
							<i className={`${icon} text-xl opacity-60`} />
						</div>
					)}
				</div>

				{isOpen && (
					<div className='mt-3'>
						{isLoading && (
							<div className='text-gray-500 text-sm mx-4 text-center'>
								<i className='ri-loader-4-line animate-spin' />
							</div>
						)}

						{taskTemplates.map((taskTemplate) => (
							<div
								onClick={() => onSelect(taskTemplate.value, taskTemplate.label)}
								key={taskTemplate.value}
								style={{ backgroundColor: `${taskTemplate.color}10` }}
								className='border border-slate-300 flex justify-between cursor-pointer mx-4 p-3 px-4 text-sm opacity-70 rounded-md mb-2 hover:opacity-100 transition-all duration-200 ease-in-out font-medium'
							>
								<span>
									<i class={`${taskTemplate.icon} mr-2`}></i>
									{taskTemplate.label}
								</span>
								<i class='ri-arrow-right-fill'></i>
							</div>
						))}
						{!isLoading && taskTemplates.length === 0 && (
							<div className='text-gray-500 text-sm mx-4 text-center'>
								{translate('noTaskTemplateFound')}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
export default TaskGroupItem;
