const ToggleButton = ({ onClick }) => {
    return (
        <button
            className="
						 text-cyan-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200
						 ml-2 py-1 mt-2 -mb- px-3 rounded-md absolute top-13 left-0 opacity-50 hover:opacity-100
						 bg-white border border-gray-200 rounded-lg
					"
            onClick={() => onClick()}
        >
            <i className={`ri-side-bar-fill text-lg`} />
        </button>
    )
}

export default ToggleButton