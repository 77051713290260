const ScheduledDepartureRender = ({ value, row }) => {
	const localTime = new Date(value).toLocaleString('en-GB', {
		hour: '2-digit',
		minute: '2-digit',
	});

	if (row?.scheduledTimeType?.name?.toLowerCase() === 'arrival') {
		return (
			<div>
				<span className='pr-2'>A</span>
				<span>{localTime}</span>
			</div>
		);
	} else return localTime;
};
export default ScheduledDepartureRender;
