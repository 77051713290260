import { useTranslations } from "hooks";
const NotImplemented = () => {
	const { translate } = useTranslations();
	return (
		<div className="grid grid-cols-1 gap-2">
			<h3>501: {translate("notImplemented")}</h3>
		</div>
	);
};

export default NotImplemented;
