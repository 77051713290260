import { useTranslations } from 'hooks';

const MarkedBooleanRender = ({
	value,
	trueVal = '',
	falseVal = '',
	trueClass = 'text-green-500',
	falseClass = 'text-red-500',
}) => {
	const { translate } = useTranslations();

	return value ? (
		<span className={trueClass}>{translate(trueVal)}</span>
	) : (
		<span className={falseClass}>{translate(falseVal)}</span>
	);
};

export default MarkedBooleanRender;
