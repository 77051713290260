import { TruncatedTextRender } from 'core/renders';
import { StringColumn } from './baseColumns';

class DescriptionColumn extends StringColumn {
	constructor(options = {}) {
		super(options.name || 'description', options);
		this.key = options.key || 'description';
		this.label = options.label || 'description';
		this.sortKey = options.sortKey || 'Description';
		this.hidden = options.hidden || false;
		this.truncateLength = options.truncateLength || 64;
		this.render = (value) => <TruncatedTextRender value={value} num={this.truncateLength} />;
		this.filter = options.filter || null;
		this.order = options.order || 0;
	}
}
export default DescriptionColumn;
