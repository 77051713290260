import { NumberWithLabelRender } from '../../renders';
import BaseColumn from './BaseColumn';

class NumberColumn extends BaseColumn {
	/**
	 * Constructs a new NumberColumn instance.
	 * @param {string} name - The unique identifier for the column.
	 * @param {object} options - Additional options for the column.
	 */
	constructor(name, options = {}) {
		super(name, 'number');
		this.key = options.key || name;
		this.label = options.label || name;
		this.title = options.title || null; //this will display in (title) attribute of the column when selecting column
		this.sortKey = options.sortKey || name;
		this.hidden = options.hidden || false;
		this.defaultValue = options.defaultValue ?? null;
		this.sideLabel = options.sideLabel || null;
		this.render =
			options.render ||
			((value) => (
				<NumberWithLabelRender value={value} defaultValue={this.defaultValue} sideLabel={this.sideLabel} />
			));

		this.filter = options.filter || null;
		this.order = options.order || 0;
	}

	isSortable() {
		return !!this.sortKey;
	}

	isFilterable() {
		return !!this.filter;
	}

	renderCell(value, row) {
		if (this.render) {
			return this.render(value, row);
		}
		return value;
	}
}

export default NumberColumn;
