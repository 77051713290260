import { useEffect } from "react";
import { withSuspense } from "hoc";
import { useToolbar } from "hooks";
import DepartmentTable from "./Departments/parts/DepartmentsTable";
import TeamsTree from "./Teams/parts/TeamsTree";

const TeamsAndDepartmentsPage = ({ permissions }) => {
	const { crumbsBuilder } = useToolbar();

	useEffect(() => {
		crumbsBuilder.addPath("/persons", "users");
		crumbsBuilder.addCurrent("teamsAndDepartments");
		crumbsBuilder.build();
	}, []);

	return (
		<div className="grid grid-cols-1 gap-2">
			{permissions.Department.View && (
				<DepartmentTable permissions={permissions.Department} />
			)}
			{permissions.Team.View && (
				<TeamsTree permissions={permissions.Team} />
			)}
		</div>
	);
};
export default withSuspense(TeamsAndDepartmentsPage);
