import { TruncateString } from 'utils';
const CustomFieldValues = ({ jsonValue }) => {
	if (jsonValue) {
		const value = JSON.parse(jsonValue);
		if (value === null) {
			return '';
		}
		if (Array.isArray(value)) {
			return TruncateString(value.join(', '), 32);
		} else {
			return TruncateString(value, 32);
		}
	}
	return '';
};
export default CustomFieldValues;
