const ErrorBox = ({ error, setError = () => {} }) => {
	if (!error) return <></>;
	return (
		<div
			onClick={() => setError("")}
			className="cursor-pointer absolute z-30 drop-shadow-lg bg-white border border-red-500 rounded-md text-sm"
		>
			<div className="absolute size-[8px] -top-[5px] border-t border-l border-gray-500 left-5 transform bg-white rotate-45" />
			<div className="flex z-100 items-center space-x-1 bg-white px-2 rounded-lg">
				<i className="ri-error-warning-fill text-3xl text-orange-500"></i>
				<span>{error}</span>
			</div>
		</div>
	);
};

export default ErrorBox;
