import AbstractService from 'services/Service';
const endpoint = 'tenant/urgencies';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	mapData(data) {
		const { id, userProfileIds, ...rest } = data;
		return {
			...rest,
			ProfileIds: userProfileIds,
		};
	}
}
export default Service;
