const TruncateBeginningString = (str, num = 42) => {
	if (!str) return '';
	if (!num) return str;
	if (str?.length <= num) {
		return str;
	}
	return '...' + str?.slice(str.length - num, str.length);
};

const TruncateString = (str, num = 64) => {
	if (!str) return '';
	if (str?.length <= num) {
		return str;
	}
	return str?.slice(0, num) + '...';
};

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export { TruncateBeginningString, TruncateString, capitalizeFirstLetter };
